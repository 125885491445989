import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mat-date-picker-header',
  templateUrl: './mat-date-picker-header.component.html',
  styleUrls: ['./mat-date-picker-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatDatePickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  months = this._dateAdapter.getMonthNames('long'); // Localized month names
  currentDate = this._dateAdapter.today(); // Current date
  currentYear = this._dateAdapter.getYear(this.currentDate);
  currentMonth = this._dateAdapter.getMonth(this.currentDate);
  years = Array.from({ length: 5 }, (_, i) => this.currentYear - 1 + i);

  constructor(
    private _calendar: MatCalendar<any>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  get monthLabel(): string {
    return this.months[this._dateAdapter.getMonth(this._calendar.activeDate)];
  }

  get yearLabel(): number {
    return this._dateAdapter.getYear(this._calendar.activeDate);
  }

  isPreviousDisabled(): boolean {
    const activeYear = this._dateAdapter.getYear(this._calendar.activeDate);
    const activeMonth = this._dateAdapter.getMonth(this._calendar.activeDate);
    return activeYear <= this.currentYear && activeMonth <= this.currentMonth;
  }

  setMonth(month: string): void {
    const date = this._calendar.activeDate as Date;
    const monthIndex = this.months.indexOf(month);
    this._calendar.activeDate = new Date(date.getFullYear(), monthIndex, 1);
  }

  setYear(year: number): void {
    const date = this._calendar.activeDate as Date;
    this._calendar.activeDate = new Date(year, date.getMonth(), 1);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      -1
    );
  }

  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      1
    );
  }
}
