import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DocumentTypeService } from 'src/app/admin-portal-configs/services/document-type.service';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { Document } from '../../models/document.model';

@Component({
  selector: 'app-display-document-fields',
  templateUrl: './display-document-fields.component.html',
  styleUrls: ['./display-document-fields.component.scss']
})
export class DisplayDocumentFieldsComponent implements OnInit {
  @Input() documentParentTypeId: string;
  @Input() documentParentType: string;
  @Input() form: UntypedFormGroup;
  @Input() documentControlName: string;
  @Input() fieldName: string;
  @Input() popupTitle: string;
  @Input() fieldPlaceholder: string;
  @Input() documentType: string;
  @Input() isRequired: boolean;
  @Input() isReplaceButtonhidden: boolean;

  documentTypes: DocumentType[] = [];
  registrationLicenseDocumentTypes: DocumentType[] = [];

  constructor(
    private documentTypeService: DocumentTypeService
  ) { }

  ngOnInit() {
    this.getDocumentTypeByName(this.documentType);
  }

  getDocumentTypeByName(documentTypeName: string): void {
    this.documentTypeService
      .getDocumentTypeByName(documentTypeName)
      .subscribe(resp => {
        this.documentTypes.push(resp);
      });
  }

  get document(): Document {
    return this.form?.get(this.documentControlName)?.value;
  }
}
