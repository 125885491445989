<div
  class="product-card-container"
  [class]="isInDashboard ? 'dashboard-product-card-container' : 'pl-2'"
>
  <div class="card-container" [class]="isBlueArrowHidden ? 'w-100' : ''">
    <div class="product-checkbox-container" *ngIf="!isInDashboard">
      <input
        type="checkbox"
        [checked]="isProductInSelectedProductsList"
        (click)="selectOrDeselectProduct($event)"
        class="product-checkbox"
      />
    </div>
    <!-- for icon -->
    <div class="product-icon">
      <div class="icon-container">
        <div class="icon-card">
          <svg-icon name="custom-clearance-icon"></svg-icon>
        </div>
      </div>
    </div>
    <!-- for content -->
    <div class=" col-7 product-data-container">
      <div class="product-data">
        <div class="row request-date">
          <span class="request-fpl-number"
            >Request No. {{ productRegistrationNumberString }}</span
          >
          <span class="dot" *ngIf="!isInDashboard">•</span>
          <span class="request-on" *ngIf="!isInDashboard">
            Requested On {{ submittedAt | date: "dd/MM/yyyy" }}</span
          >
        </div>
      </div>
    </div>
    <!-- for status icon -->
    <div class="status-col">
      <div
        class="vertical-line"
        [ngStyle]="{
          backgroundColor: productRegistration?.productSystemStatus?.colorCode
        }"
      ></div>
    </div>

    <!--  for status details -->
    <div class="status-details-col col-auto">
      <div class="status-details">
        <div class="status-name">
          {{ currentStatus }}
        </div>
        <div
          class="acceptance-number"
          *ngIf="acceptanceNumber && !registrationLicenseNumber"
        >
          Acceptance No. {{ acceptanceNumber }}
        </div>
        <div class="registration-number" *ngIf="registrationLicenseNumber">
          Registration No. {{ registrationLicenseNumber }}
        </div>

        <div class="update-on d-flex" *ngIf="!isInDashboard">
          <span> Updated on {{ updatedStatusDate | date: "dd/MM/yyyy" }}</span>

          <span class="pending-action-section" *ngIf="hasPendingActions">
            <div class="pending-actions-tag-custom-width">
              <svg-icon name="alertMark"></svg-icon>
              <span class="text-pending shipment-tag"> Pending Action </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div [class]="isBlueArrowHidden ? 'd-none' : ''">
    <div class="arrow-icon">
      <svg-icon name="blueArrowIcon"></svg-icon>
    </div>
  </div>
</div>
