import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Shipment } from '../shared/models/shipment';
import { ShipmentRoutingData } from './models/shipmentRoutingDataModel';
import { ShipmentBase } from 'src/app/shared/models/shipment/shipmentBase.model';
import { ShipmentServices } from 'src/app/shared/models/shipment/shipmentServices.model';
import { TruckingLookups } from 'src/app/createShipment/models/trucking-lookups.model';
import { PartyType } from 'src/app/shared/models/enums/party-type';
import { TransactionType } from 'src/app/shared/models/enums/transaction-type.enum';
import { SubmitShipmentRequest } from 'src/app/createShipment/models/submit-shipment-request.model';
import { IorEorServiceProviderAndEgyptIds } from './models/iorEor-service-provider-and-egypt-ids.model';
import { OriginAndDeliveryDistrict } from '../dashboard/models/origin-and-delivery-district.model';

@Injectable({
  providedIn: 'root'
})
export class CreateShipmentService {
  baseURL = environment.apiUrl + 'Shipment';
  private _shipment = new BehaviorSubject<ShipmentBase>(new Shipment());
  d: Shipment;

  private _servicesChanges: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(true);

  @Output() containerNumberClicked = new EventEmitter<boolean>();

  editingShipment: boolean = false;
  iorEorServiceProviderAndEgyptId: IorEorServiceProviderAndEgyptIds;
  isSubmitted: boolean = false;

  constructor(private http: HttpClient) {}

  set shipment(values: any) {
    const temp = Object.assign(new Shipment(), this._shipment.value, values);
    this._shipment.next(temp);
  }

  get shipment(): any {
    return this._shipment;
  }

  changeServicesSelection(): void {
    this._servicesChanges.next(true);
  }

  servicesChanges$(): Observable<boolean> {
    return this._servicesChanges.asObservable();
  }

  getShipment(shipmentId) {
    return this.http.get(this.baseURL + `?id=${shipmentId}`);
  }

  CommercialTransactionParty(
    companyId,
    originCountryId,
    destinationCountryId,
    partyTypeOfFreeplEntities?: PartyType,
    importExportService?: string
  ) {
    return this.http.get(
      environment.apiUrl +
        `CommercialTransactionParty/${companyId}/${originCountryId}/${destinationCountryId}/${partyTypeOfFreeplEntities}?importExportService=${importExportService}`
    );
  }

  commercialTransactionPartyNewVersion(
    companyId: number,
    originCountryId: number,
    destinationCountryId: number,
    importExportService?: string,
    partyTypeOfFreeplEntities?: PartyType
  ) {
    return this.http.get(
      environment.apiUrl +
        `CommercialTransactionParty/${companyId}/${originCountryId}/${destinationCountryId}?importExportService=${importExportService}&partyTypeOfFreeplEntities=${partyTypeOfFreeplEntities}`
    );
  }

  getOriginAndDeliveryDistrict(
    orginAndDeliveryDistrict: OriginAndDeliveryDistrict
  ) {
    return this.http.post(
      environment.apiUrl + 'District/OriginAndDeliveryDistrict',
      orginAndDeliveryDistrict
    );
  }

  intiateShipmentAndAddServices(
    companyId,
    body: ShipmentServices,
    quoteId?: string
  ) {
    let url = this.baseURL + `/intiateShipmentAndAddServices/${companyId}`;
    if (quoteId) {
      url += `?quoteId=${quoteId}`;
    }
    return this.http.post(url, body);
  }

  updateShipment(id, body) {
    return this.http.put(this.baseURL + `/UpsertServicesAsync?id=${id}`, body);
  }

  updateRouting(id, body): Observable<ShipmentBase> {
    return this.http.put<ShipmentBase>(
      this.baseURL + `/Routing?id=${id}`,
      body
    );
  }

  shipmentRoutingLookups(
    id: number,
    portId: number,
    transactionType: TransactionType
  ): Observable<ShipmentRoutingData> {
    return this.http.get<ShipmentRoutingData>(
      environment.apiUrl +
        `Companies/${id}/ShipmentRoutingLookups/${portId}/${transactionType}`
    );
  }

  getPorts() {
    return this.http.get(environment.apiUrl + `Helper/Ports`);
  }

  putCargoDetails(body, id): Observable<any> {
    return this.http.put(
      environment.apiUrl + `Shipment/CargoDetails?id=${id}`,
      body
    );
  }

  submitShipment(
    id,
    submitShipmentRequest: SubmitShipmentRequest,
    companyId
  ): Observable<any> {
    return this.http.put(
      environment.apiUrl +
        `Shipment/SubmitShipment?shipmentId=${id}&companyId=${companyId}`,
      submitShipmentRequest
    );
  }

  getTruckingLookups(id: string): Observable<TruckingLookups> {
    return this.http.get<TruckingLookups>(
      this.baseURL + `/TruckingLookups?shipmentId=${id}`
    );
  }
}
