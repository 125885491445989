<app-page-header [title]="pageTitle"></app-page-header>

<div class="search-filter-margin row">
  <div class="search-filter-container mx-0 row align-items-center">
    <div class="search-bar">
      <app-admin-search-bar
        class="search-bar d-block"
        placeholder="{{ searchPlaceHolder | translate }}"
        (searchChange)="onSearchChanged($event)"
      ></app-admin-search-bar>
    </div>
    <div class="p-2 cursor-pointer">
      <svg-icon name="three-lines-filter"></svg-icon>
    </div>
  </div>
  <div class="ml-auto" *ngIf="canCreateFreePLCustomers">
    <button
      *ngIf="parentCompanyId; else addNewServiceProviderButton"
      type="submit"
      class="btn add-btn"
      [routerLink]="['/company/add/', parentCompanyId]"
    >
      <span class="add-btn-title"
        >+ {{ "company.AddNewCustomer" | translate }}</span
      >
    </button>

    <ng-template #addNewServiceProviderButton>
      <button
        type="submit"
        class="btn add-btn"
        [routerLink]="['/company/add-service-provider']"
      >
        <span class="add-btn-title"
          >+ {{ "company.AddNewCompany" | translate }}</span
        >
      </button>
    </ng-template>
  </div>
</div>

<app-add-empty-data-msg
  *ngIf="!iscompaniesExist"
  [boldText]="'company.CompanyEmpty' | translate"
  [normalText]="'company.CompanyWillAppear' | translate"
></app-add-empty-data-msg>

<ngx-datatable
  *ngIf="iscompaniesExist"
  class="material colored-header sm table table-striped table-hover"
  [loadingIndicator]="loadingIndicator"
  [rows]="rows"
  [rowHeight]="40"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #companyNameTemplate let-row="row" let-value="value">
  <a
    [routerLink]="
      isCustomersProfilePage
        ? ['/customer', row.id, 'details']
        : ['/company', row.id]
    "
    >{{ row.name }}</a
  >
</ng-template>

<ng-template #boolTemplate let-row="row" let-value="value" let-i="index">
  <span *ngIf="value; else falseLabel" class=" text-success fw-bold">
    {{ "company.Active" | translate }}
  </span>

  <ng-template #falseLabel>
    <span class="text-danger fw-bold">
      {{ "company.Inactive" | translate }}
    </span>
  </ng-template>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
  <div>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      *ngIf="canEditFreePLCustomers"
      [routerLink]="['/company/edit/', row.id]"
    ></svg-icon>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      [name]="!row.isActive ? 'closed-lock' : 'open-lock'"
      (click)="onclickChangeCompanyStatus(row.id, !row.isActive)"
      *ngIf="canDeleteCustomers && canEditFreePLCustomers"
    ></svg-icon>
    <!-- <svg-icon
        class="action-btn cursor-pointer pr-2"
        name="open-lock"
        (click)="onclickChangeCompanyStatus(row.id, false)"
        *ngIf="row.isActive && canDeleteCustomers && canEditFreePLCustomers"
      ></svg-icon> -->
  </div>
</ng-template>
