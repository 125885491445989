import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Industry } from 'src/app/shared/models/industry.model';
import {
    AlertService,
    DialogType,
    MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Utilities } from '../../../../shared/services/utilities';
import { IndustryService } from '../../../services/industry.service';

@Component({
  selector: 'app-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.scss']
})
export class IndustryListComponent implements OnInit {
  gT = (key: string) => this.translationService.getTranslation(key);
  columns: any[] = [];
  rows: Industry[] = [];
  rowsCache: Industry[] = [];
  loadingIndicator: boolean;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('parentIndustryName', { static: true })
  parentIndustryName: TemplateRef<any>;

  @ViewChild('description', { static: true })
  description: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private industryService: IndustryService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var industries = data['industries'];

      industries = this.getIndecies(industries);

      this.rowsCache = [...industries];
      this.rows = industries;
    });
    this.initTable();
  }

  initTable() {
    this.columns = [
      {
        prop: 'name',
        name: this.gT('config.management.Industry.Name'),
        canAutoResize: true
      },
      {
        prop: 'parentIndustryName',
        name: this.gT('config.management.Industry.ParentIndustry'),
        cellTemplate: this.parentIndustryName
      },
      {
        prop: 'description',
        name: this.gT('config.management.Industry.Description'),
        cellTemplate: this.description
      },
      {
        name: '',
        cellTemplate: this.actionsTemplate,
        resizeable: true,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteIndustry(row: Industry) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" industry ?',
      DialogType.confirm,
      () => this.deleteIndustryHelper(row)
    );
  }

  deleteIndustryHelper(row: Industry) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.industryService.deleteIndustry(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the industry.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }
}
