import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EmployeeProfileRoutingModule } from './employee-profile-routing.module';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { EmployeeProfileInfoComponent } from './components/employee-profile-info/employee-profile-info.component';
import { EmployeeResolver } from '../shared/resolvers/employee.resolver';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RecoverPasswordComponent } from './components/account/recover-password/recover-password.component';
import { RegisterComponent } from './components/account/register/register.component';
import { ConfirmEmailComponent } from './components/account/confirm-email/confirm-email.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { ChangeProfilePictureComponent } from './components/change-profile-picture/change-profile-picture.component';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import {
  SvgIconsModule,
  svgIconCamera,
  svgIconPofilePicture,
  svgIconPersonalInfo,
  svgIconPassword,
  svgIconProfileNotification
} from 'projects/svg-icons/src/lib/svg-icons';
import { LoginPageComponent } from './pages/login-page/login-page.component';

@NgModule({
  declarations: [
    MyProfileComponent,
    ChangeProfilePictureComponent,
    EmployeeProfileInfoComponent,
    ChangePasswordComponent,
    LoginFormComponent,
    ConfirmEmailComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent,
    RegisterComponent,
    NotificationSettingsComponent,
    LoginPageComponent
  ],
  imports: [EmployeeProfileRoutingModule, SharedModule, SvgIconsModule],
  providers: [EmployeeResolver],
  exports: [NotificationSettingsComponent]
})
export class EmployeeProfileModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([
      svgIconPofilePicture,
      svgIconCamera,
      svgIconPersonalInfo,
      svgIconPassword,
      svgIconProfileNotification
    ]);
  }
}
