<div class="container">
  <app-page-header
    [title]="'config.management.Industry.Manage' | translate"
  ></app-page-header>

  <app-page-action-header
    placeholder="{{ 'config.management.Industry.Search' | translate }}"
    addLabel="{{ 'config.management.Industry.New' | translate }}"
    addUrl="./add"
    [canAdd]="true"
    (searchChanged)="onSearchChanged($event)"
  ></app-page-action-header>

  <app-add-empty-data-msg
    *ngIf="!rows.length"
    [boldText]="'config.management.Industry.IndustryEmpty' | translate"
    [normalText]="'config.management.Industry.IndustrWillAppear' | translate"
  ></app-add-empty-data-msg>

  <ngx-datatable
    *ngIf="rows.length"
    class="material colored-header sm table table-striped table-hover mt-5"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="40"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>

  <ng-template
    #parentIndustryName
    let-row="row"
    let-value="value"
    let-i="index"
  >
    <div>
      {{ value || "-"}}
    </div>
  </ng-template>

  <ng-template #description let-row="row" let-value="value" let-i="index">
    <div>
      {{ value || "-"}}
    </div>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <div>
      <svg-icon
        class="action-btn cursor-pointer pr-2"
        name="edit-circle"
        [routerLink]="['./' + row.id + '/edit']"
      ></svg-icon>
      <svg-icon
        class="action-btn cursor-pointer pr-2"
        name="delete-circle"
        (click)="deleteIndustry(row)"
      ></svg-icon>
    </div>
  </ng-template>
</div>
