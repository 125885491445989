<div class="upload-container" *ngIf="!selectedFile && !url">
  <!-- Hidden File Input -->
  <input
    type="file"
    id="fileInput"
    class="file-input"
    (change)="onFileSelected($event)"
  />

  <!-- Custom Styled Label -->
  <label for="fileInput" class="upload-label">
    <div class="upload-icon">
      <!-- Icon -->
      <svg-icon name="upload-icon"></svg-icon>
    </div>
    <span class="upload-text">Upload Logo</span>
  </label>
</div>

<div class="file-container" *ngIf="selectedFile || url">
  <div>
    <a
      class="file-name"
      (click)="navigateToPreview()"
      target="_blank"
      *ngIf="!url"
    >
      {{ selectedFile?.name }}
    </a>
    <a class="file-name" [href]="url" target="_blank" *ngIf="url">
      {{ url.split("/")[4] }}
    </a>
    <div class="file-size" *ngIf="!url">{{ fileSize }} KB</div>
  </div>

  <svg-icon
    name="trash-icon"
    class="cursor-pointer"
    (click)="clearFile()"
  ></svg-icon>
</div>
