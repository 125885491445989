<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 pb-1 mx-0">
    <form [formGroup]="companyForm" id="companyForm" (ngSubmit)="save()">
      <div class="header" [class]="isFailedToSave ? 'failed-to-save' : ''">
        <app-page-header
          [title]="isForEditting ? 'Edit Details' : 'Add new customer'"
        ></app-page-header>
        <div class="note">
          (<span class="field-mandatory">*</span>) means that this field is
          mandatory
        </div>
      </div>
      <app-error-badge
        [message]="httpErrorMessage || errorMessage"
        *ngIf="isFailedToSave"
      ></app-error-badge>

      <div class="full-logo">
        <div class="company-logo">
          <span
            *ngIf="!company.logo && !logoUploaded"
            class="company-logo no-picture"
          >
            <div class="no-logo">Logo</div>
          </span>
          <div *ngIf="selectedImage" class="company-logo uploded-logo">
            <div><img [src]="selectedImage" /></div>
          </div>
          <input
            type="file"
            (change)="onChangeLogo($event)"
            accept="image/*"
            style="display: none;"
            id="logo"
            formControlName="logo"
            #fileInput
          />
        </div>
        <div class="svg-container" (click)="onUploadClick()">
          <svg-icon class="camera-icon" name="camera-navy"></svg-icon>
        </div>
      </div>

      <div class="row form-body">
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="customerType">
              {{ "company.CustomerType" | translate
              }}<span class="text-error">*</span>
            </label>
            <ng-select
              class="form-control field-input freepl-details-ddl"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('customerType').errors &&
                  companyForm.get('customerType').touched
              }"
              appearance="outline"
              [items]="customerTypes"
              placeholder="{{ 'company.TypePlaceHolder' | translate }}"
              formControlName="customerType"
              [virtualScroll]="true"
              [clearable]="false"
              (change)="onChangeCustomerType($event, true)"
              bindValue="item"
              bindLabel="item"
              name="ddl-customerType"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <mat-radio-button
                  [value]="item"
                  [checked]="isFormControlSelected(item, 'customerType')"
                >
                  <label class="value">{{ item }}</label>
                </mat-radio-button>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">{{ item }} </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="input-field-container">
            <label class="field-title" for="licenseType">
              {{ "company.LicenceType" | translate
              }}<span class="text-error" *ngIf="!isFreeZoneSelected">*</span>
            </label>
            <ng-select
              class="form-control field-input freepl-details-ddl"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('licenseType').errors &&
                  companyForm.get('licenseType').touched
              }"
              appearance="outline"
              [items]="licenseTypes"
              formControlName="licenseType"
              [virtualScroll]="true"
              placeholder="{{ 'company.TypePlaceHolder' | translate }}"
              [readonly]="setlicenseTypesReadOnly()"
              [clearable]="false"
              bindValue="item"
              bindLabel="item"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <mat-radio-button
                  [value]="item"
                  [checked]="isFormControlSelected(item, 'licenseType')"
                >
                  <label class="value ">{{ item }}</label>
                </mat-radio-button>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">{{ item }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="name">
              {{ "company.Name" | translate }}<span class="text-error">*</span>
            </label>

            <input
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('name').errors &&
                  companyForm.get('name').touched
              }"
              type="text"
              class="form-control field-input"
              id="name"
              placeholder="{{ 'company.NamePlaceHolder' | translate }}"
              maxlength="30"
            />
          </div>
          <div class="field-container">
            <div class="input-field-container">
              <label class="field-title" for="companyOfficialName">
                {{ "company.OfficialName" | translate
                }}<span class="text-error">*</span>
              </label>

              <input
                formControlName="companyOfficialName"
                [ngClass]="{
                  'is-invalid':
                    companyForm.get('companyOfficialName').errors &&
                    companyForm.get('companyOfficialName').touched
                }"
                type="text"
                class="form-control field-input"
                id="companyOfficialName"
                placeholder="{{ 'company.NamePlaceHolder' | translate }}"
                maxLength="30"
              />
            </div>
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="companyRegistrationNo">
              {{ "company.RegistrationNo" | translate }}
            </label>
            <input
              formControlName="companyRegistrationNo"
              class="form-control field-input"
              id="companyRegistrationNo"
              placeholder="{{
                'company.RegistrationNoPlaceholder' | translate
              }}"
              appNumberOnly
            />
          </div>
          <div class="field-container">
            <div class="input-field-container">
              <label class="field-title" for="vatNumber">
                {{ "company.VATNumber" | translate
                }}<span class="text-error">*</span>
              </label>
              <input
                formControlName="vatNumber"
                type="text"
                class="form-control field-input"
                [ngClass]="{
                  'is-invalid':
                    companyForm.get('vatNumber').errors &&
                    companyForm.get('vatNumber').touched
                }"
                id="vatNumber"
                maxLength="30"
                placeholder="{{ 'company.VATNumberPlaceholder' | translate }}"
              />
            </div>
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="phoneNumber">
              {{ "company.PhoneNumber" | translate }}
            </label>
            <input
              formControlName="phoneNumber"
              type="text"
              class="form-control field-input"
              id="phoneNumber"
              placeholder="{{ 'company.PhoneNumberPlaceholder' | translate }}"
              maxlength="15"
              appOnlyDigitsDirective
            />
          </div>
          <div class="input-field-container">
            <label class="field-title" for="email">
              {{ "company.Email" | translate }}
            </label>
            <input
              formControlName="email"
              type="email"
              class="form-control field-input"
              id="email"
              placeholder="{{ 'company.EmailPlaceholder' | translate }}"
              maxlength="30"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="address">
              {{ "company.Address" | translate }}
            </label>
            <input
              formControlName="address"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('address').errors &&
                  companyForm.get('address').touched
              }"
              type="text"
              class="form-control field-input address-field"
              id="address"
              maxlength="100"
              placeholder="{{ 'company.AddressPlaceHolder' | translate }}"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="countryId">
              {{ "route.Country" | translate }}
            </label>
            <ng-select
              class="form-control field-input freepl-details-ddl"
              appearance="outline"
              [items]="countries"
              placeholder="{{ 'company.SelectCountry' | translate }}"
              formControlName="countryId"
              id="countryId"
              [virtualScroll]="true"
              [clearable]="false"
              (change)="onChangeCountry($event, true)"
              bindValue="id"
              bindLabel="name"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <mat-radio-button
                  [value]="item.id"
                  [checked]="isFormControlSelected(item.id, 'countryId')"
                >
                  <label class="value">{{ item.name }}</label>
                </mat-radio-button>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">{{ item.name }} </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="input-field-container">
            <label class="field-title" for="cityId">
              {{ "route.City" | translate }}
            </label>
            <ng-select
              appearance="outline"
              [items]="cities"
              class="form-control field-input freepl-details-ddl"
              formControlName="cityId"
              id="cityId"
              [virtualScroll]="true"
              placeholder="{{ 'company.SelectCity' | translate }}"
              [readonly]="!companyForm.get('countryId').value"
              [clearable]="false"
              bindValue="id"
              bindLabel="name"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <mat-radio-button
                  [value]="item.id"
                  [checked]="isFormControlSelected(item.id, 'cityId')"
                >
                  <label class="value ">{{ item.name }}</label>
                </mat-radio-button>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">{{ item.name }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="website">
              {{ "company.Website" | translate }}
            </label>
            <input
              formControlName="website"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('website').errors &&
                  companyForm.get('website').touched
              }"
              type="text"
              class="form-control field-input"
              id="website"
              maxlength="30"
              placeholder="{{ 'company.WebsitePlaceHolder' | translate }}"
            />
          </div>

          <div class="input-field-container">
            <label class="field-title" for="industry">
              {{ "company.Industry" | translate
              }}<span class="text-error">*</span>
            </label>
            <ng-select
              appearance="outline"
              [items]="industryList"
              class="form-control field-input freepl-details-ddl"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('industryId').errors &&
                  companyForm.get('industryId').touched
              }"
              formControlName="industryId"
              id="industryId"
              [virtualScroll]="true"
              placeholder="{{ 'company.SelectIndustry' | translate }}"
              [clearable]="false"
              [searchable]="false"
              bindValue="id"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <mat-radio-button
                  [value]="item.id"
                  [checked]="isFormControlSelected(item.id, 'industryId')"
                >
                  <label class="value">{{ item.name }}</label>
                </mat-radio-button>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">{{ item.name }}</span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="field-container" *ngIf="isCustomerProfile">
          <div class="input-field-container">
            <label class="field-title">
              {{ "company.SAPAccountId" | translate }}
            </label>
            <input
              formControlName="sapAccountId"
              [ngClass]="{
                'is-invalid':isDuplicatedSAPAccountId
              }"
              type="text"
              class="form-control field-input"
              maxlength="30"
              placeholder="{{ 'company.EnterSAPAccountId' | translate }}"
            />
          </div>
          <div class="input-field-container">
            <label class="field-title" for="isActive">
              {{ "route.Status" | translate }}<span class="text-error">*</span>
            </label>
            <ng-select
              class="form-control field-input freepl-details-ddl"
              [ngClass]="{
                'is-invalid':
                  companyForm.get('isActive').errors &&
                  companyForm.get('isActive').touched
              }"
              appearance="outline"
              [items]="statusList"
              placeholder="{{ 'company.statusPlaceHolder' | translate }}"
              formControlName="isActive"
              id="isActive"
              [clearable]="false"
              [searchable]="false"
            >
              <ng-template ng-option-tmp let-item="item">
                <label class="value">
                  {{ item ? statusEnum.Active : statusEnum.InActive }}
                </label>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div class="ng-value">
                  <span class="ng-value-label">
                    {{ item ? statusEnum.Active : statusEnum.InActive }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="field-container" *ngIf="false">
          <div
            class="input-field-container"
            [hidden]="!companyForm.get('isCreditLimit').value && isDialogOpened"
          >
            <label class="field-title" for="creditLimit">
              <mat-checkbox
                *ngIf="!isDialogOpened"
                formControlName="isCreditLimit"
                (change)="creditLimitChecked($event)"
                class="mr-2"
              >
              </mat-checkbox
              >{{ "company.CreditLimit" | translate }}
            </label>
            <div class="align-items-center d-flex">
              <input
                [attr.disabled]="
                  !companyForm.get('isCreditLimit').value || isDialogOpened
                    ? ''
                    : null
                "
                formControlName="creditLimit"
                [ngClass]="{
                  'is-invalid':
                    companyForm.get('creditLimit').errors &&
                    companyForm.get('creditLimit').touched
                }"
                type="number"
                class="w-100 form-control field-input currency-input"
                id="creditLimit"
                placeholder="{{ 'company.CreditLimitPlaceholder' | translate }}"
              />
              <select
                class="form-control currency-ddl"
                [ngClass]="{
                  'is-invalid':
                    companyForm.get('currencyId').errors &&
                    companyForm.get('currencyId').touched
                }"
                [attr.disabled]="
                  !companyForm.get('isCreditLimit').value || isDialogOpened
                    ? ''
                    : null
                "
                formControlName="currencyId"
                id="currencyId"
                [value]="company.currencyId"
              >
                <option *ngFor="let item of currencies" [value]="item.id">
                  {{ item.code }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row buttons">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <div class="form-btn cancel-btn" (click)="cancel()">
              {{ "forms.Cancel" | translate }}
            </div>
            <button
              type="submit"
              class="form-btn save-btn"
              [disabled]="isSaving"
            >
              {{ "forms.Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
