<div class="row w-100">
  <div class="col-6" *ngIf="isCustomerCustomesClearance">
    <div class="title">Supplier</div>
    <div class="value">{{ supplierName }}</div>
  </div>
  <div class="col-6">
    <div class="title">Transaction Type</div>
    <div class="value">{{ transactionTypes }}</div>
  </div>
  <div class="col-6 ">
    <div class="title">Freight Type</div>
    <div class="value">{{ clearanceDetails?.freightType }}</div>
  </div>

  <div class="col-6 ">
    <div class="title">Port of Clearance</div>
    <div class="value">{{ ports }}</div>
  </div>
  <div class="col-6 " *ngIf="!isAirFreight">
    <div class="title">Load Type</div>
    <div class="value">{{ loadtypes }}</div>
  </div>

  <div
    class="col-6 "
    *ngIf="!isAirFreight && !isBreakbulkLoadType && !isLandFreight"
  >
    <div class="title">Container Type</div>
    <div class="value">{{ containerTypes }}</div>
  </div>
</div>
