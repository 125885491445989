import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApprovalViewModel } from 'src/app/approvals/models/approval-view-mode';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApprovalBase } from 'src/app/approvals/models/approval-base.model';
import { HttpClient } from '@angular/common/http';
import { ApprovalSearchCriteria } from 'src/app/approvals/models/approval-search-criteria.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { ApprovalUpdateDocumentNote } from 'src/app/shared/models/approval-update-document-note';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {
  baseUrl = environment.apiUrl + 'Approval';
  @Output() onApprovalChange = new EventEmitter<boolean>();
  @Output() selectedApprovalsNumbersChanged = new EventEmitter<string[]>();
  private selectedFreePLApprovalNumber: string[] = [];
  @Output() approvalLeadTimeNotesUpdated: EventEmitter<{
    leadTimeId: number;
    leadTimeNotes: string;
  }> = new EventEmitter<{ leadTimeId: number; leadTimeNotes: string }>();
  isApprovalSubmitted: boolean = false;
  approval: ApprovalBase;

  constructor(private http: HttpClient) { }

  updateLeadTimeNotes(leadTimeId: number, notes?: string) {
    return this.http.put(
      `${this.baseUrl}/ApprovalLeadTimeLog/${leadTimeId}/Notes?notes=${notes}`,
      null
    );
  }

  submitApprovalDetails(
    approvalId: string,
    companyId: number
  ): Observable<ApprovalBase> {
    return this.http.put<ApprovalBase>(
      this.baseUrl + `/ApprovalSubmission/${companyId}/${approvalId}`,
      null
    );
  }

  approvalsPaginated(
    approvalSearchCriteria: ApprovalSearchCriteria
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseUrl + `/PaginatedApprovals`,
      approvalSearchCriteria
    );
  }

  getApprovalById(approvalId: string): Observable<ApprovalBase> {
    return this.http.get<ApprovalBase>(this.baseUrl + `/${approvalId}`);
  }

  deleteProduct(
    approvalId: string,
    productId: string
  ): Observable<ApprovalBase> {
    return this.http.delete<ApprovalBase>(
      this.baseUrl + `/${approvalId}/Product/${productId}`
    );
  }

  addToSelectedApprovalsList(approvalNumber: string) {
    this.selectedFreePLApprovalNumber.push(approvalNumber);
    this.selectedApprovalsNumbersChanged.emit(
      this.selectedFreePLApprovalNumber
    );
  }

  removeFromSelectedApprovalsList(approvalNumber: string) {
    this.selectedFreePLApprovalNumber = this.selectedFreePLApprovalNumber?.filter(
      item => {
        return item != approvalNumber;
      }
    );
    this.selectedApprovalsNumbersChanged.emit(
      this.selectedFreePLApprovalNumber
    );
  }

  removeAllFromSelectedApprovalsList(): void {
    this.selectedFreePLApprovalNumber = [];
    this.selectedApprovalsNumbersChanged.emit(
      this.selectedFreePLApprovalNumber
    );
  }

  isApprovalInSelectedList(number: string): boolean {
    let searchIndex = this.selectedFreePLApprovalNumber.indexOf(number);
    return searchIndex != -1;
  }

  cancelApproval(
    approvalId: string,
    companyId: number,
    reason: string
  ): Observable<ApprovalBase> {
    return this.http.put<ApprovalBase>(
      this.baseUrl +
      `/${approvalId}/Company/${companyId}/Cancel?reason=${reason}`,
      null
    );
  }
  updateApprovalDetails(
    approvalId: string,
    approval: ApprovalViewModel
  ): Observable<ApprovalBase> {
    return this.http.put<ApprovalBase>(
      this.baseUrl + `/${approvalId}/ApprovalUpdate`,
      approval
    );
  }

  updateApprovalActivity(
    approvalId: string,
    activityName: string,
    approvalNumber?: string,
    submissionNumber?: string
  ): Observable<ApprovalBase> {
    let url = `${this.baseUrl}/${approvalId}/Activity/${activityName}`;

    const queryParams: string[] = [];
    if (approvalNumber) {
      queryParams.push(`approvalNumber=${encodeURIComponent(approvalNumber)}`);
    }
    if (submissionNumber) {
      queryParams.push(`submissionNumber=${encodeURIComponent(submissionNumber)}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    return this.http.put<ApprovalBase>(url, null);
  }

  updateApprovalDocumentNote(
    approvalId: string,
    noteViewModel: ApprovalUpdateDocumentNote
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrl}/${approvalId}/DocumentNote`,
      noteViewModel
    );
  }

  deleteApprovalDocument(
    approvalId: string,
    documentId: number,
    reason: string,
    parentDocumntId?: number
  ): Observable<ApprovalBase> {
    return this.http.put<ApprovalBase>(
      `${this.baseUrl}/${approvalId}/DocumentDeletion/${documentId}?reason=${reason}&parentDocumntId=${parentDocumntId}`,
      null
    );
  }

  updateProductNotes(
    approvalId: string,
    productId: string,
    notes: string
  ): Observable<ApprovalBase> {
    return this.http.put<ApprovalBase>(
      this.baseUrl + `/${approvalId}/ProductNote/${productId}?notes=${notes}`,
      null
    );
  }

  initiateApproval(
    approvalViewModel: ApprovalViewModel,
    companyId: number
  ): Observable<ApprovalBase> {
    return this.http.post<ApprovalBase>(
      `${this.baseUrl}/ApprovalInitiation/${companyId}`,
      approvalViewModel
    );
  }
}
