import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { RenewalType } from '../../../supplier/enums/renewal-type';
import { PaymentTerm } from 'src/app/shared/models/payment-term.model';
import { OfficialReceiptsPaymentsTermsEnum } from '../../../supplier/enums/official-receipts-payments-terms.enum';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { MatDatePickerHeaderComponent } from 'src/app/shared/components/mat-date-picker-header/mat-date-picker-header.component';
import { ChoiceEnum } from '../../models/enums/choice.enum';
import { PaymentTermsTypesEnum } from '../../models/enums/payment-terms-types.enum';

@Component({
  selector: 'app-pricelist-contractual-terms',
  templateUrl: './pricelist-contractual-terms.component.html',
  styleUrls: ['./pricelist-contractual-terms.component.scss']
})
export class PricelistContractualTermsComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() paymentTerms: PaymentTerm[];
  @Input() targetPricelist: string;
  @Input() isLandFreight: boolean;

  renewalTypes: string[];
  creditLimitOptions: string[];
  officialReceiptsPaymentsTerms: string[];
  paymentTermsTypes: string[];
  entityType = EntityType;
  matDateHeader = MatDatePickerHeaderComponent;

  constructor(private formsHelperService: FormsHelperService) { }

  ngOnInit(): void {
    this.getStaticLookups();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isLandFreight) {
      if (this.isLandFreight) {
        this.addValidators('paymentTermType');
      } else {
        var paymentTermTypeControl = this.form.get('paymentTermType');
        if (paymentTermTypeControl) {
          this.removeValidators('paymentTermType');
        }
      }
    }
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.form,
      formControlName
    );
  }

  getStaticLookups(): void {
    this.creditLimitOptions = [ChoiceEnum.Yes, ChoiceEnum.No];
    this.paymentTermsTypes = [
      PaymentTermsTypesEnum.AllServicesShareTheSamePaymentTerms,
      PaymentTermsTypesEnum.EachServiceHasItsOwnPaymentTerms
    ];
    this.renewalTypes = [RenewalType.AutoRenewing, RenewalType.ManualRenewal];
    if (this.targetPricelist == EntityType.CustomsClearancePricelist) {
      this.officialReceiptsPaymentsTerms = [
        OfficialReceiptsPaymentsTermsEnum.Deferred,
        OfficialReceiptsPaymentsTermsEnum.Advanced
      ];
    }
  }

  onOpenDatePicker(): void {
    const matCalendar = document.getElementsByClassName('mat-calendar')[0];
    (matCalendar as HTMLElement).style.height = '40px';
    const button = document.createElement('div');
    button.className = 'footerToday';
    const span = document.createElement('span');
    button.appendChild(span);
    const text = document.createTextNode('Today');
    button.appendChild(text);
    matCalendar.appendChild(button);
  }

  cleardatePiker(formControlName: string): void {
    setTimeout(() => {
      this.form.get(formControlName).markAsUntouched();
    }, 10);
  }

  addValidators(controlName: string): void {
    this.formsHelperService.addRequiredValidator(this.form, controlName);
  }

  removeValidators(controlName: string): void {
    this.formsHelperService.clearValidator(this.form, controlName);
  }

  onCreditLimitChange(event: string): void {
    if (event == ChoiceEnum.Yes) {
      this.form.get('creditLimitAmount')?.enable();
      this.addValidators('creditLimitAmount');
    } else {
      const creditLimitAmount = this.form.get('creditLimitAmount');
      creditLimitAmount?.reset();
      creditLimitAmount?.disable();
      this.removeValidators('creditLimitAmount');
    }
  }

  get futureDate(): Date {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }

  get isCustomerCompanyEntity(): boolean {
    return this.targetPricelist == EntityType.CustomerEntities;
  }

  get hasCreditLimitSelected(): boolean {
    return this.form.get('hasCreditLimit')?.value == ChoiceEnum.Yes;
  }
}
