import { Component, Input, OnInit } from '@angular/core';
import { ApprovalColumns } from 'src/app/approvals/enums/approval-columns';
import { ApprovalHelperService } from 'src/app/approvals/services/approval-helper.service';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { iso8601Regex } from '../../constants/iso-8601-regex';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})
export class TableViewComponent implements OnInit {
  @Input() rows: any[];
  @Input() columns: string[];
  @Input() entityType: string;
  @Input() isAllSelected: boolean;

  maxLengthBeforeTruncation: number = 20;
  iso8601Regex = iso8601Regex;

  constructor(
    private approvalHelperService: ApprovalHelperService,
    private approvalService: ApprovalService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  getContent(columnName: string, row): string | Date {
    if (this.entityType == EntityType.Approval) {
      return this.approvalHelperService.getColumnContent(columnName, row);
    }
  }

  isDate(value: any): boolean {
    if (value && typeof value === 'string' && this.iso8601Regex.test(value)) {
      return true;
    }
    return false;
  }

  isStatusField(columnName: string) {
    return columnName == ApprovalColumns.Status;
  }

  getStatusColor(row: any) {
    if (this.entityType == EntityType.Approval) {
      return this.approvalHelperService.getStatusColor(row);
    }
  }

  selectOrDeselect(event: any, ApprovalNumber: string): void {
    event.stopPropagation();
    const isChecked: boolean = event.target.checked;
    if (this.entityType == EntityType.Approval) {
      if (isChecked) {
        if (!this.isAllSelected) {
          this.approvalService.addToSelectedApprovalsList(ApprovalNumber);
        } else {
          this.approvalService.removeFromSelectedApprovalsList(ApprovalNumber);
        }
      } else {
        if (!this.isAllSelected) {
          this.approvalService.removeFromSelectedApprovalsList(ApprovalNumber);
        } else {
          this.approvalService.addToSelectedApprovalsList(ApprovalNumber);
        }
      }
    }
  }

  isInList(code: string): boolean {
    if (this.entityType == EntityType.Approval) {
      let isSelected: boolean = this.approvalService.isApprovalInSelectedList(
        code
      );
      return this.isAllSelected ? !isSelected : isSelected;
    }
  }

  navigateToDetails(id: string, event: MouseEvent): void {
    const url = `${this.router.url}/${id}/details`;

    if (event.ctrlKey) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }
}
