import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/company/models/company.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { CompanyService } from '../../services/company.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {
  @Input() public companies: Company[];
  public rows: Company[];
  @Input() public parentCompanyId: number;
  @Input() public pageTitle: string;
  public searchPlaceHolder: string;
  public columns;

  loadingIndicator: boolean;
  gT = (key: string) => this.translationService.getTranslation(key);
  @ViewChild('companyNameTemplate', { static: true })
  companyNameTemplate: TemplateRef<any>;

  @ViewChild('boolTemplate', { static: true })
  boolTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  get iscompaniesExist(): boolean {
    return this.rows?.length > 0;
  }

  constructor(
    public companyService: CompanyService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadingIndicator = true;

    if (!this.companies || this.companies.length == 0) {
      this.route.data.subscribe(data => {
        this.rows = this.companies = data['companies'];
      });
    } else {
      this.rows = this.companies;
    }

    let companyUrl = this.router.url.includes('company');

    if (companyUrl) {
      this.pageTitle = this.pageTitle || this.gT('company.Companies');
      this.searchPlaceHolder = 'company.Search';
    } else if (this.isCustomersUrl) {
      this.pageTitle = this.pageTitle || this.gT('company.Customers');
      this.searchPlaceHolder = 'company.SearchCustomers';
      this.parentCompanyId = Number(this.authService.currentUser.companyId);
    }

    this.initTable();
    this.loadingIndicator = false;
  }

  initTable() {
    this.columns = [
      {
        prop: 'name',
        name: this.gT('company.Name'),
        cellTemplate: this.companyNameTemplate,
        canAutoResize: false
      },
      { prop: 'industry.name', name: this.gT('company.Industry'), width: 500 },

      {
        prop: 'isActive',
        name: this.gT('company.Status'),

        cellTemplate: this.boolTemplate
      }
    ];

    if (this.canEditFreePLCustomers || this.canDeleteCustomers) {
      this.columns.push({
        name: '',
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }
  onSearchChanged(value: string) {
    this.rows = this.companies.filter(r =>
      Utilities.searchArray(
        value,
        false,
        r.name,
        r.industry,
        r.email,
        r.phoneNumber
      )
    );
  }

  onclickChangeCompanyStatus(companyId: number, isActive: boolean) {
    var message = isActive
      ? 'Are you sure to activate the company'
      : 'Are you sure to deactivate the company';
    this.alertService.confirm(message, () => {
      this.changeCompanyStatus(companyId, isActive);
    });
  }

  changeCompanyStatus(companyId: number, isActive: boolean) {
    this.companyService
      .changeCompanyStatus(companyId, isActive)
      .subscribe(data => {
        this.changeCompanyStatusInList(companyId, isActive);
      });
  }

  changeCompanyStatusInList(companyId: number, isActive: boolean) {
    var company = this.companies.find(e => e.id == companyId);
    company.isActive = isActive;
    this.rows = this.companies;
  }

  get canDeleteCustomers(): boolean {
    if (!this.isCustomersUrl) {
      return true;
    }
    return this.authService.canDeleteCustomers;
  }

  get canEditFreePLCustomers(): boolean {
    return this.authService.canUpdateFreePLCustomers;
  }

  get canCreateFreePLCustomers(): boolean {
    return this.authService.canCreateFreePLCustomers;
  }

  get isCustomersUrl(): boolean {
    return this.router.url.includes('customers');
  }

  get isCustomersProfilePage(): boolean {
    return this.router.url.includes('customers-profile');
  }
}
