import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyDigitModule } from 'src/app/shared/directives/only-digit-directive/only-digit.module';
import { NgxMaskModule } from 'ngx-mask';
import { InputNumberComponent } from './input-number/input-number.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  svgColorPicker,
  svgIcondatePickerIcon,
  SvgIconsModule,
  svgTrashIcon,
  svgUploadIcon
} from 'projects/svg-icons/src/lib/svg-icons';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
@NgModule({
  declarations: [
    InputComponent,
    InputNumberComponent,
    DatePickerComponent,
    ColorPickerComponent,
    FileUploadComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OnlyDigitModule,
    NgxMaskModule.forRoot(),
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    SvgIconsModule
  ],
  exports: [
    InputComponent,
    InputNumberComponent,
    DatePickerComponent,
    ColorPickerComponent,
    FileUploadComponent
  ]
})
export class InputModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([
      svgIcondatePickerIcon,
      svgColorPicker,
      svgUploadIcon,
      svgTrashIcon
    ]);
  }
}
