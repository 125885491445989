import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';
import { ActivityCategory } from '../models/shipment/activity-category.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityParentCategoryResolver
  implements Resolve<ActivityCategory[]> {
  constructor(private activityService: ActivityService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<ActivityCategory[]> {
    return this.activityService.getActivityCategories().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
