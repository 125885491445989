import { CommonModule, LowerCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe
} from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DndModule } from 'ngx-drag-drop';
import { NgxMaskModule } from 'ngx-mask';
import { TimeagoModule } from 'ngx-timeago';
import { ToastaModule } from 'ngx-toasta';
import {
  SvgIconDetails,
  svgCompanyContacts,
  svgDashboard,
  svgCompanyDocumentsSidebar,
  svgForms4Sidebar,
  svgIcoExclamationMark,
  svgIconActionIcon,
  svgIconAdditionalService,
  svgIconArrowDown,
  svgIconArrowDownBlack,
  svgIconCompanyAssignedBps,
  svgIconBlackArrowLeft,
  svgIconBlueArrowRight,
  svgIconBlueCompactCardViewIcon,
  svgIconBlueEnvelop,
  svgIconBoat,
  svgIconBox,
  svgIconCancel,
  svgIconCargo,
  svgIconCheckSuccess,
  svgIconCircleLock,
  svgIconCirclePen,
  svgIconCircleUnLock,
  svgIconClose,
  svgIconCollapseSectionButton,
  svgIconColumnChartIcon,
  svgIconCommentSectionDefault,
  svgIconCommentsSectionPreview,
  svgIconCompanyDetailsEmployees,
  svgIconCompanyDetailsOperationalKpis,
  svgIconCompanyDetailsServices,
  svgIconCompanyProfile,
  svgIconContainer20,
  svgIconContainer40,
  svgIconContainer40hc,
  svgIconCompanyContractualTerms,
  svgIconCopy,
  svgIconCustomClearance,
  svgIconDangerousMaterialClassCorrosive,
  svgIconDangerousMaterialClassExplosives,
  svgIconDangerousMaterialClassGases,
  svgIconDangerousMaterialClassLiquid,
  svgIconDangerousMaterialClassMiscellaneous,
  svgIconDangerousMaterialClassOxidising,
  svgIconDangerousMaterialClassRadioactive,
  svgIconDangerousMaterialClassSolid,
  svgIconDangerousMaterialClassToxins,
  svgIconDashboardLandTrucking,
  svgIconDelete,
  svgIconDestination,
  svgIconDimmedCompactCardViewIcon,
  svgIconDimmedListView,
  svgIconDoc,
  svgIconDonutChartIcon,
  svgIconDownloadIcon,
  svgIconDownloadNavy,
  svgIconExpandDrawer,
  svgIconExpandSectionButton,
  svgIconExpansionArrow,
  svgIconExtendArrowDown,
  svgIconFCLContainer,
  svgIconFTLTruck,
  svgIconFolder,
  svgIconFright,
  svgIconGrayCancelIcon,
  svgIconGrayDelete,
  svgIconGreenSwitch,
  svgIconImportationApproval,
  svgIconImportationPlan,
  svgIconInfo,
  svgIconLCLContainer,
  svgIconLTLTruck,
  svgIconLandTrucking,
  svgIconLeftBlueArrow,
  svgIconLineChartIcon,
  svgIconLogOut,
  svgIconManageColumn,
  svgIconMessage,
  svgIconMyProfile,
  svgIconNavyEnvelop,
  svgIconNoCommentsIcon,
  svgIconNoItemsIcon,
  svgIconNotification,
  svgIconNotificationBlack,
  svgIconOrangePlusIcon,
  svgIconOrigin,
  svgIconPackage,
  svgIconPallet,
  svgIconPauseShipment,
  svgIconProductRegistration,
  svgIconRecommendedFlag,
  svgIconRedInfo,
  svgIconRedSwitch,
  svgIconRefresh,
  svgIconRefreshButton,
  svgIconRemoveDocument,
  svgIconResumeShipment,
  svgIconRightArrowHead,
  svgIconRightBlueArrow,
  svgIconSendIcon,
  svgIconShipmentLeadTimes,
  svgIconShipmentNowActive,
  svgIconShipmentStatisticsIcon,
  svgIconSmallAlertMark,
  svgIconSmallFilterIcon,
  svgIconSmallInfo,
  svgIconSmallWhiteCheckIcon,
  svgIconTimepiece,
  svgIconTotalShipmentsIcon,
  svgIconTruck,
  svgIconUndo,
  svgIconUndoDarkBlue,
  svgIconUndoStatus,
  svgIconUploadDocument,
  svgIconUploadingSpinner,
  svgIconWarning,
  svgIconWhiteArrowLeft,
  svgIconWhiteCamera,
  svgIconWhiteCheckIcon,
  svgIconactiveShipmentsIcon,
  svgIconaddIcon,
  svgIconadditionalDetails,
  svgIconarrow,
  svgIconarrowToLeft,
  svgIconbluePlaneIcon,
  svgIconcancelShipment,
  svgIconchargesSummary,
  svgIconcompletedShipmentsIcon,
  svgIcondatePickerIcon,
  svgIcondocumentsummery,
  svgIconeditCharge,
  svgIconeditQuote,
  svgIconfiltersIcon,
  svgIconlooseCargo,
  svgIconreplaceDocument,
  svgIconsearchIcon,
  svgIconservicesAndRates,
  svgIconshipmentDetailsActionDelete,
  svgIconshipmentProducts,
  svgIconshipmentrouting,
  svgIconsubmittedShipmentsIcon,
  svgMenu,
  svgOperationalOversightSidebar,
  svgPlus,
  svgProductsSidebar,
  svgQuotesSidebar,
  svgQuotesSidebarV2,
  svgReportsSidebar,
  svgShipments,
  svgSmallSortFilterIcon,
  svgSortFilterIcon,
  svgWarehousingSidebarIcon,
  svgPlansSidebar,
  svgShipmentActivitiesSidebar,
  svgShipmentStatusesSidebar,
  svgWorkflowSidebar,
  svgKpisSidebar,
  svgSystemFieldsSidebar,
  svgGeoLocationsSidebar,
  svgNetworkSidebar,
  svgSecuritySidebar,
  svgCustomerProfileSidebar,
  svgOldCustomerProfileSidebar,
  svgCompanyProfileSidebar,
  svgNewPlansSidebar,
  svgSubscriptionsSidebar,
  svgTenantsSidebar,
  svgIconDeleteCircle,
  svgIconEditCircle,
  svgPeopleFillIcon,
  svgXIcon,
  svgCompanyNetwork,
  svgTableCell
} from 'projects/svg-icons/src/lib/svg-icons';
import { SvgIconsModule } from 'projects/svg-icons/src/lib/svg-icons.module';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import { BoolPipeModule } from 'src/app/shared/pipes/bool.pipe';
import { DateExpiredPipeModule } from 'src/app/shared/pipes/date-expired/date-expired.module';
import { FromUTCToLocalPipeModule } from 'src/app/shared/pipes/from-utc-to-local-datetime/from-utc-to-local-datetime.module';
import { RouteResolver } from 'src/app/shared/resolvers/route.resolver';
import {
  AppTranslationService,
  TranslateLanguageLoader
} from 'src/app/shared/services/app-translation.service';
import { AdminPortalConfigsRouting } from '../admin-portal-configs/admin-portal-configs-routing.module';
import { AppErrorHandler } from '../app-error.handler';
import { ProductRegistrationDocumentOlderVersionComponent } from '../create-product-registration/components/product-registration-document-older-version/product-registration-document-older-version.component';
import { PreventUnsavedChanges } from '../createShipment/guards/prevent-unsaved-changes.guard';
import { ShipmentServicesResolver } from '../createShipment/resolvers/shipment-services.resolver';
import { QuoteCardComponent } from '../quote/components/quote-card/quote-card.component';
import { PageHeaderModule } from '../shipments/sub-components/page-header/page-header.module';
import { ActionAuthorComponent } from './components/action-author/action-author.component';
import { AddDomesticTruckingInformationComponent } from './components/add-domestic-trucking-information/add-domestic-trucking-information.component';
import { AddEditTruckDetailsComponent } from './components/add-edit-truck-details/add-edit-truck-details.component';
import { AddEmptyDataMsgComponent } from './components/add-empty-data-msg/add-empty-data-msg.component';
import { AdminPageDetailsComponent } from './components/admin-page-details/admin-page-details.component';
import { AdminSearchBarComponent } from './components/admin-search-bar/admin-search-bar.component';
import { ApprovalSummaryComponent } from './components/approval-summary/approval-summary.component';
import { BoxWithTriangleModule } from './components/box-width-triangle/box-with-triangle.module';
import { BulkActionComponent } from './components/bulk-action/bulk-action.component';
import { CancelEntityComponent } from './components/cancel-entity/cancel-entity.component';
import { CardTwoModule } from './components/cards/card-two/card-two.module';
import { CargoHandlingComponent } from './components/cargo-handling/cargo-handling.component';
import { ChargesTabComponent } from './components/charges-tab/charges-tab.component';
import { CheckSelectionCardComponent } from './components/check-selection-card/check-selection-card.component';
import { CollapsiblePermissionCategoryComponent } from './components/collapsible-permission-category/collapsible-permission-category.component';
import { CommentPendingActionFulfillInformationComponent } from './components/comment-pending-action-fulfill-information/comment-pending-action-fulfill-information.component';
import { CommentsPendingActionComponent } from './components/comments-pending-action/comments-pending-action.component';
import { CompanySideNavBarComponent } from './components/company-side-nav-bar/company-side-nav-bar.component';
import { ConfirmationDeletionComponent } from './components/confirmation-deletion/confirmation-deletion.component';
import { ContainerCardComponent } from './components/container-card/container-card.component';
import { ContainerDetails1Component } from './components/container-details-1/container-details-1.component';
import { ContainerDetailsForm1Component } from './components/container-details-form-1/container-details-form-1.component';
import { CustomsClearancePricelistDetailsSummaryComponent } from './components/customs-clearance-pricelist-details-summary/customs-clearance-pricelist-details-summary.component';
import { DangerousGoodClassComponent } from './components/dangerous-good-class/dangerous-good-class.component';
import { DangerousGoodComponent } from './components/dangerous-good/dangerous-good.component';
import { DeleteDocumentComponent } from './components/delete-document/delete-document.component';
import { DisplayDocumentFieldsComponent } from './components/display-document-fields/display-document-fields.component';
import { DisplayDocumentComponent } from './components/display-document/display-document.component';
import { ErrorBadgeModule } from './components/error-badge/error-badge.module';
import { FileViewerModule } from './components/file-viewer/file-viewer.module';
import { CheckboxModule } from './components/forms/checkbox/checkbox.module';
import { InputModule } from './components/forms/input/input.module';
import { QuantityInputModule } from './components/forms/quantity-input/quantity-input.module';
import { GSearchBarComponent } from './components/g-search-bar/g-search-bar.component';
import { HeaderDashedModule } from './components/header-dashed-underline/header-dashed.module';
import { LabelWithInputModule } from './components/label-with-input/label-with-input.module';
import { LeadTimesComponent } from './components/lead-times/lead-times.component';
import { LeavingPageNotificationComponent } from './components/leaving-page-notification/leaving-page-notification.component';
import { ListFiltersComponent } from './components/list-filters/list-filters.component';
import { LoadTypeRadioButtonCardComponent } from './components/load-type-radio-button-card/load-type-radio-button-card.component';
import { LoadingIndicatorModule } from './components/loading-indicator/loading-indicator.module';
import { MatDatePickerHeaderComponent } from './components/mat-date-picker-header/mat-date-picker-header.component';
import { ModuleWidgetComponent } from './components/module-widget/module-widget.component';
import { NonAdminSidebarComponent } from './components/non-admin-sidebar/non-admin-sidebar.component';
import { NotificationsViewerComponent } from './components/notification/notifications-viewer/notifications-viewer.component';
import { NumberOfUpdatedEntitiesModalComponent } from './components/number-of-updated-entities-modal/number-of-updated-entities-modal.component';
import { PageStepperComponent } from './components/page-stepper/page-stepper.component';
import { PortPopupComponent } from './components/port-popup/port-popup.component';
import { PricelistClearanceDetailsComponent } from './components/pricelist-clearance-details/pricelist-clearance-details.component';
import { PriceListTermsAndConditiontsComponent } from './components/pricelist-terms-and-conditionts/pricelist-terms-and-conditionts.component';
import { ProductComplianceDetailsSummaryComponent } from './components/product-compliance-details-summary/product-compliance-details-summary.component';
import { ProductComplianceSummaryComponent } from './components/product-compliance-summary/product-compliance-summary.component';
import { ProductDetailsApprovalsSummaryComponent } from './components/product-details-approvals-summary/product-details-approvals-summary.component';
import { ProductDetailsRegistrationSummaryComponent } from './components/product-details-registration-summary/product-details-registration-summary.component';
import { ProductDetailsSummaryComponent } from './components/product-details-summary/product-details-summary.component';
import { ProductInformationSummaryComponent } from './components/product-information-summary/product-information-summary.component';
import { ProductRegistrationCardComponent } from './components/product-registration-card/product-registration-card.component';
import { ProductUploadDocumentsComponent } from './components/product-upload-documents/product-upload-documents.component';
import { ProductVariantsListComponent } from './components/product-variants-list/product-variants-list.component';
import { RadioButtonCardComponent } from './components/radio-button-card/radio-button-card.component';
import { SearchBoxModule } from './components/search-box/search-box.module';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SectionWithHeaderComponent } from './components/section-with-header/section-with-header.component';
import { SelectedProductInTab } from './components/selected-product-in-tab/selected-product-in-tab.component';
import { ServiceSelectionCardComponent } from './components/service-selection-card/service-selection-card.component';
import { SortFilterComponent } from './components/sort-filter/sort-filter.component';
import { StatusListComponent } from './components/status-list/status-list.component';
import { SubSectionTitleComponent } from './components/sub-section-title/sub-section-title.component';
import { SubmitPopupComponent } from './components/submit-popup/submit-popup.component';
import { SuccessSnackBarModule } from './components/success-snack-bar/success-snack-bar.module';
import { TableViewComponent } from './components/table-view/table-view.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TotalsDetailedPackagesInformationSelectionComponent } from './components/totals-detailed-packages-information-selection/totals-detailed-packages-information-selection.component';
import { TruckingRouteDetailsSectionComponent } from './components/trucking-route-details-section/trucking-route-details-section.component';
import { TruckingRouteDetailsSummaryComponent } from './components/trucking-route-details-summary/trucking-route-details-summary.component';
import { TruckingTermsAndConditionsSummaryComponent } from './components/trucking-terms-and-conditions-summary/trucking-terms-and-conditions-summary.component';
import { TruckingTruckDetailsSummaryComponent } from './components/trucking-truck-details-summary/trucking-truck-details-summary.component';
import { UploadDocumentFieldComponent } from './components/upload-document-field/upload-document-field.component';
import { UploadedDocumentsDetailsLoaderComponent } from './components/uploaded-documents-details-loader/uploaded-documents-details-loader.component';
import { UploadedDocumentsDetailsOlderVersionComponent } from './components/uploaded-documents-details-older-version/uploaded-documents-details-older-version.component';
import { UploadedDocumentsDetailsComponent } from './components/uploaded-documents-details/uploaded-documents-details.component';
import { WarningBadgeModule } from './components/warning-badge/warning-badge.module';
import { WhiteNavBarComponent } from './components/white-nav-bar/white-nav-bar.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { ErrorHighlightDirective } from './directives/error-highlight.directive';
import { FileUploadDirective } from './directives/file-upload.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { OnlyDigitModule } from './directives/only-digit-directive/only-digit.module';
import { NumberOnlyDirective } from './directives/only-number.directive';
import { AuthInterceptorService } from './interceptors/auth.interceptor';
import { AddOrEditCompanyComponent } from './pages/add-or-edit-company/add-or-edit-company.component';
import { CompanyProfileDetailsComponent } from './pages/company-profile-details/company-profile-details.component';
import { EmployeeListComponent } from './pages/employee-list/employee-list.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProfileDocumentsListComponent } from './pages/profile-documents-list/profile-documents-list.component';
import { BoolNullPipe } from './pipes/bool-null.pipe';
import { CapitalCasePipe } from './pipes/capital-case/capital-case.pipe';
import { ExcludePipe } from './pipes/exclude.pipe';
import { FromUtcToAfricaCairoPipeModule } from './pipes/from-utc-to-africa-cairo/from-utc-to-africa.module';
import { HSCodePipeModule } from './pipes/hsCode/hsCode.pipe.module';
import { MonthNameByNumberPipe } from './pipes/month-name-by-number/month-name-by-number.pipe';
import { NoValueReplacePipe } from './pipes/no-value-replace.pipe';
import { ParentesisAroundLastWordPipe } from './pipes/parentesis-around-last-word/parentesis-around-last-word.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { UpperCasePipe } from './pipes/upper-case/upper-case.pipe';
import { UserNameInitialsPipeModule } from './pipes/user-name-initials/user-name-initials.module';
import { ValuArrPipe } from './pipes/valu-arr.pipe';
import { OceanFreightDetailsComponent } from './quote/components/ocean-freight-details/ocean-freight-details.component';
import { PackagePalletDetailsComponent } from './quote/components/package-pallet-details/package-pallet-details.component';
import { QuoteCargoDetailsSummaryComponent } from './quote/components/quote-cargo-details-summary/quote-cargo-details-summary.component';
import { QuoteDocumentsSummaryComponent } from './quote/components/quote-documents-summary/quote-documents-summary.component';
import { QuoteProductDetailsComponent } from './quote/components/quote-product-details/quote-product-details.component';
import { QuoteRoutingSummaryComponent } from './quote/components/quote-routing-summary/quote-routing-summary.component';
import { SubmitQuotePopUpComponent } from './quote/components/submit-quote-pop-up/submit-quote-pop-up.component';
import { SummaryTruckingSectionComponent } from './quote/components/summary-trucking-section/summary-trucking-section.component';
import { AccountManagersListResolver } from './resolvers/account-managers-list.resolver';
import { CommissionTieredRateResolver } from './resolvers/commission-tiered-rate.resolver';
import { DocumentsListResolver } from './resolvers/documents-list.resolver';
import { AccountEndpoint } from './services/account-endpoint.service';
import { AccountService } from './services/account.service';
import { AppTitleService } from './services/app-title.service';
import { ConfigurationService } from './services/configuration.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { NotificationSettingService } from './services/notification-setting.service';
import { NotificationService } from './services/notification.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { PaginatorIntl } from './services/paginator-intl';
import { ThemeManager } from './services/theme-manager';
import { SharedRoutingModule } from './shared-routing.module';
import { PricelistContractualTermsComponent } from './components/pricelist-contractual-terms/pricelist-contractual-terms.component';
import { PageActionHeader } from './components/page-action-header/page-action-header.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';

@NgModule({
  declarations: [
    AdminPageDetailsComponent,
    ProductUploadDocumentsComponent,
    SubSectionTitleComponent,
    SectionTitleComponent,
    CheckSelectionCardComponent,
    ServiceSelectionCardComponent,
    CommentPendingActionFulfillInformationComponent,
    CommentsPendingActionComponent,
    RadioButtonCardComponent,
    ActionAuthorComponent,
    ValuArrPipe,
    EqualValidator,
    LastElementDirective,
    FileUploadDirective,
    AutofocusDirective,
    NotFoundComponent,
    BoolNullPipe,
    ExcludePipe,
    PortPopupComponent,
    LeavingPageNotificationComponent,
    ContainerDetailsForm1Component,
    ContainerDetails1Component,
    ContainerCardComponent,
    LoadTypeRadioButtonCardComponent,
    DangerousGoodComponent,
    MonthNameByNumberPipe,
    DangerousGoodClassComponent,
    CapitalCasePipe,
    ShortNumberPipe,
    UpperCasePipe,
    NoValueReplacePipe,
    ParentesisAroundLastWordPipe,
    MatDatePickerHeaderComponent,
    OceanFreightDetailsComponent,
    PackagePalletDetailsComponent,
    QuoteDocumentsSummaryComponent,
    QuoteProductDetailsComponent,
    QuoteRoutingSummaryComponent,
    SummaryTruckingSectionComponent,
    QuoteCargoDetailsSummaryComponent,
    SubmitQuotePopUpComponent,
    SectionWithHeaderComponent,
    PageStepperComponent,
    CancelEntityComponent,
    LeadTimesComponent,
    DeleteDocumentComponent,
    SortFilterComponent,
    ListFiltersComponent,
    TotalsDetailedPackagesInformationSelectionComponent,
    CargoHandlingComponent,
    GSearchBarComponent,
    BulkActionComponent,
    StatusListComponent,
    NumberOnlyDirective,
    NumberOfUpdatedEntitiesModalComponent,
    AddEmptyDataMsgComponent,
    WhiteNavBarComponent,
    NotificationsViewerComponent,
    UploadedDocumentsDetailsComponent,
    ProductDetailsSummaryComponent,
    ConfirmationDeletionComponent,
    UploadedDocumentsDetailsOlderVersionComponent,
    SubmitPopupComponent,
    ModuleWidgetComponent,
    TableViewComponent,
    ApprovalSummaryComponent,
    QuoteCardComponent,
    ProductRegistrationCardComponent,
    AddDomesticTruckingInformationComponent,
    CompanySideNavBarComponent,
    CompanyProfileDetailsComponent,
    AddOrEditCompanyComponent,
    AdminSearchBarComponent,
    ErrorHighlightDirective,
    EmployeeListComponent,
    TruckingRouteDetailsSectionComponent,
    AddEditTruckDetailsComponent,
    ProfileDocumentsListComponent,
    PriceListTermsAndConditiontsComponent,
    UploadedDocumentsDetailsLoaderComponent,
    NonAdminSidebarComponent,
    TruckingRouteDetailsSummaryComponent,
    TruckingTruckDetailsSummaryComponent,
    TruckingTermsAndConditionsSummaryComponent,
    UploadDocumentFieldComponent,
    RelativeTimePipe,
    SelectedProductInTab,
    CollapsiblePermissionCategoryComponent,
    ProductComplianceDetailsSummaryComponent,
    ProductInformationSummaryComponent,
    ProductVariantsListComponent,
    TopBarComponent,
    ProductDetailsApprovalsSummaryComponent,
    ProductDetailsRegistrationSummaryComponent,
    ProductComplianceSummaryComponent,
    ProductRegistrationDocumentOlderVersionComponent,
    DisplayDocumentComponent,
    DisplayDocumentFieldsComponent,
    ChargesTabComponent,
    CustomsClearancePricelistDetailsSummaryComponent,
    PricelistClearanceDetailsComponent,
    PricelistContractualTermsComponent,
    PageActionHeader,
    AdminSidebarComponent
  ],

  imports: [
    MatIconModule,
    ChartsModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatSidenavModule,
    LabelWithInputModule,
    InputModule,
    QuantityInputModule,
    HeaderDashedModule,
    PageHeaderModule,
    OnlyDigitModule,
    SharedRoutingModule,
    BoolPipeModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    ToastaModule,
    TimeagoModule.forRoot(),
    FromUTCToLocalPipeModule,
    NgSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    MatPaginatorModule,
    UserNameInitialsPipeModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    LoadingIndicatorModule,
    SuccessSnackBarModule,
    HttpClientModule,
    ErrorBadgeModule,
    WarningBadgeModule,
    MatFormFieldModule,
    CheckboxModule,
    MatCheckboxModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    SvgIconsModule,
    FileViewerModule,
    DateExpiredPipeModule,
    HSCodePipeModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    SearchBoxModule,
    DndModule,
    LowerCasePipe,
    FromUtcToAfricaCairoPipeModule
  ],
  exports: [
    NotificationsViewerComponent,
    WhiteNavBarComponent,
    ProductUploadDocumentsComponent,
    ParentesisAroundLastWordPipe,
    UpperCasePipe,
    NoValueReplacePipe,
    CapitalCasePipe,
    MonthNameByNumberPipe,
    InputModule,
    QuantityInputModule,
    ContainerDetailsForm1Component,
    ContainerDetails1Component,
    ValuArrPipe,
    CancelEntityComponent,
    LeavingPageNotificationComponent,
    PortPopupComponent,
    FileUploadDirective,
    GSearchBarComponent,
    LabelWithInputModule,
    SubSectionTitleComponent,
    SectionTitleComponent,
    CheckSelectionCardComponent,
    ServiceSelectionCardComponent,
    HeaderDashedModule,
    HSCodePipeModule,
    PageHeaderModule,
    CardTwoModule,
    BoxWithTriangleModule,
    CheckboxModule,
    InputModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatSidenavModule,
    FileViewerModule,
    CommonModule,
    TranslatePipe,
    BoolPipeModule,
    BoolNullPipe,
    ToastaModule,
    FromUTCToLocalPipeModule,
    NgSelectModule,
    UserNameInitialsPipeModule,
    MatSnackBarModule,
    MatDialogModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatTabsModule,
    ModalModule,
    LoadingIndicatorModule,
    SuccessSnackBarModule,
    HttpClientModule,
    ErrorBadgeModule,
    WarningBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    AdminPortalConfigsRouting,
    NgxDatatableModule,
    SvgIconsModule,
    DateExpiredPipeModule,
    NgbModule,
    OnlyDigitModule,
    SearchBoxModule,
    CommentPendingActionFulfillInformationComponent,
    CommentsPendingActionComponent,
    RadioButtonCardComponent,
    ActionAuthorComponent,
    LoadTypeRadioButtonCardComponent,
    DangerousGoodComponent,
    DangerousGoodClassComponent,
    DndModule,
    ShortNumberPipe,
    QuoteDocumentsSummaryComponent,
    QuoteRoutingSummaryComponent,
    QuoteProductDetailsComponent,
    OceanFreightDetailsComponent,
    SummaryTruckingSectionComponent,
    PackagePalletDetailsComponent,
    QuoteCargoDetailsSummaryComponent,
    NgxMaskModule,
    MatDatePickerHeaderComponent,
    SubmitQuotePopUpComponent,
    SectionWithHeaderComponent,
    PageStepperComponent,
    LeadTimesComponent,
    DeleteDocumentComponent,
    ListFiltersComponent,
    TotalsDetailedPackagesInformationSelectionComponent,
    CargoHandlingComponent,
    BulkActionComponent,
    NumberOnlyDirective,
    AddEmptyDataMsgComponent,
    UploadedDocumentsDetailsComponent,
    ProductDetailsSummaryComponent,
    EqualValidator,
    ModuleWidgetComponent,
    TableViewComponent,
    LowerCasePipe,
    ApprovalSummaryComponent,
    QuoteCardComponent,
    ProductRegistrationCardComponent,
    AddDomesticTruckingInformationComponent,
    CompanySideNavBarComponent,
    CompanyProfileDetailsComponent,
    AddOrEditCompanyComponent,
    AdminPageDetailsComponent,
    AdminSearchBarComponent,
    ErrorHighlightDirective,
    EmployeeListComponent,
    TruckingRouteDetailsSectionComponent,
    AddEditTruckDetailsComponent,
    ProfileDocumentsListComponent,
    PriceListTermsAndConditiontsComponent,
    NonAdminSidebarComponent,
    TruckingRouteDetailsSummaryComponent,
    TruckingTruckDetailsSummaryComponent,
    TruckingTermsAndConditionsSummaryComponent,
    UploadDocumentFieldComponent,
    SelectedProductInTab,
    CollapsiblePermissionCategoryComponent,
    FromUtcToAfricaCairoPipeModule,
    ProductComplianceDetailsSummaryComponent,
    ProductInformationSummaryComponent,
    ProductVariantsListComponent,
    TopBarComponent,
    ProductComplianceSummaryComponent,
    ProductDetailsApprovalsSummaryComponent,
    ProductRegistrationDocumentOlderVersionComponent,
    DisplayDocumentComponent,
    DisplayDocumentFieldsComponent,
    ChargesTabComponent,
    CustomsClearancePricelistDetailsSummaryComponent,
    PricelistClearanceDetailsComponent,
    PricelistContractualTermsComponent,
    PageActionHeader,
    AdminSidebarComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: PaginatorIntl },
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    NotificationService,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    OidcHelperService,
    AccountManagersListResolver,
    NotificationSettingService,
    PreventUnsavedChanges,
    ShipmentServicesResolver,
    CommissionTieredRateResolver,
    RouteResolver,
    TranslatePipe,
    AppTranslationService,
    DocumentsListResolver
  ]
})
export class SharedModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([
      svgIconCopy,
      svgIconLandTrucking,
      svgIconDashboardLandTrucking,
      svgIconArrowDownBlack,
      svgIconNotificationBlack,
      svgIconSmallAlertMark,
      svgIconWhiteArrowLeft,
      svgIconBlackArrowLeft,
      svgIconCommentSectionDefault,
      svgIconNoCommentsIcon,
      svgIconSendIcon,
      svgIconCollapseSectionButton,
      svgIconExpandDrawer,
      svgForms4Sidebar,
      svgIconExpandSectionButton,
      svgIconCommentsSectionPreview,
      svgIconRightBlueArrow,
      svgIconLeftBlueArrow,
      svgIconShipmentNowActive,
      svgIconeditQuote,
      svgIconArrowDown,
      svgIconNotification,
      svgIconMessage,
      svgIconarrowToLeft,
      svgIconRedInfo,
      svgIconaddIcon,
      svgIconBlueEnvelop,
      svgIconDownloadIcon,
      svgIconshipmentDetailsActionDelete,
      svgIconeditCharge,
      svgIconreplaceDocument,
      svgIconRedSwitch,
      svgIconGreenSwitch,
      svgIconarrow,
      svgIconTimepiece,
      svgIconUploadDocument,
      svgIconFolder,
      svgIconUploadingSpinner,
      svgIconBlueArrowRight,
      svgIconCheckSuccess,
      svgIconCancel,
      svgIconFright,
      svgIconOrigin,
      svgIconDestination,
      svgIcondatePickerIcon,
      svgIconTruck,
      svgIconDoc,
      svgIconWarning,
      svgIconarrow,
      svgIconExtendArrowDown,
      svgIconshipmentProducts,
      svgIconCheckSuccess,
      svgIconDelete,
      svgIcoExclamationMark,
      svgIconRefresh,
      svgIconRefreshButton,
      svgIconWhiteCheckIcon,
      svgIconRecommendedFlag,
      svgIconClose,
      svgIconlooseCargo,
      svgIconFCLContainer,
      svgIconUndo,
      svgIconUndoStatus,
      svgIconUndoDarkBlue,
      svgIconBoat,
      svgIconPallet,
      svgIconPackage,
      svgIconOrangePlusIcon,
      svgIconContainer20,
      svgIconContainer40,
      svgIconContainer40hc,
      svgIconLTLTruck,
      svgIconFTLTruck,
      svgIconLCLContainer,
      svgIconFCLContainer,
      svgIconBox,
      svgIconDangerousMaterialClassCorrosive,
      svgIconDangerousMaterialClassExplosives,
      svgIconDangerousMaterialClassGases,
      svgIconDangerousMaterialClassLiquid,
      svgIconDangerousMaterialClassMiscellaneous,
      svgIconDangerousMaterialClassOxidising,
      svgIconDangerousMaterialClassRadioactive,
      svgIconDangerousMaterialClassSolid,
      svgIconDangerousMaterialClassToxins,
      svgIconSmallWhiteCheckIcon,
      svgIconDonutChartIcon,
      svgIconTotalShipmentsIcon,
      svgIconLineChartIcon,
      svgIconColumnChartIcon,
      svgIconShipmentStatisticsIcon,
      svgIconCargo,
      svgIconeditQuote,
      svgIconAdditionalService,
      svgIcondocumentsummery,
      svgIconshipmentrouting,
      svgIconcompletedShipmentsIcon,
      svgIconsubmittedShipmentsIcon,
      svgIconactiveShipmentsIcon,
      svgIconsearchIcon,
      svgIconfiltersIcon,
      svgIconbluePlaneIcon,
      svgIconbluePlaneIcon,
      svgIconcancelShipment,
      svgIconRemoveDocument,
      svgIconNoItemsIcon,
      svgIconservicesAndRates,
      svgIconExpansionArrow,
      svgIconchargesSummary,
      svgIconProductRegistration,
      svgIconImportationApproval,
      svgIconImportationPlan,
      svgIconMyProfile,
      svgIconCompanyProfile,
      svgIconLogOut,
      svgSortFilterIcon,
      svgIconGrayCancelIcon,
      svgIconPauseShipment,
      svgIconShipmentLeadTimes,
      svgIconResumeShipment,
      svgIconSmallInfo,
      svgSmallSortFilterIcon,
      svgIconSmallFilterIcon,
      svgIconDimmedListView,
      svgIconDimmedCompactCardViewIcon,
      svgIconBlueCompactCardViewIcon,
      svgIconActionIcon,
      svgIconManageColumn,
      svgIconNavyEnvelop,
      svgIconDownloadNavy,
      svgIconGrayDelete,
      svgIconadditionalDetails,
      svgIconCustomClearance,
      svgIconRightArrowHead,
      svgIconCompanyContractualTerms,
      svgIconCompanyAssignedBps,
      svgIconCompanyDetailsEmployees,
      svgIconCompanyDetailsOperationalKpis,
      svgIconCompanyDetailsServices,
      svgIconWhiteCamera,
      SvgIconDetails,
      svgIconCircleLock,
      svgIconCircleUnLock,
      svgIconCirclePen,
      svgMenu,
      svgPlus,
      svgDashboard,
      svgShipments,
      svgQuotesSidebar,
      svgQuotesSidebarV2,
      svgProductsSidebar,
      svgOperationalOversightSidebar,
      svgReportsSidebar,
      svgIconInfo,
      svgWarehousingSidebarIcon,
      svgCompanyContacts,
      svgCompanyDocumentsSidebar,
      svgPlansSidebar,
      svgShipmentActivitiesSidebar,
      svgShipmentStatusesSidebar,
      svgWorkflowSidebar,
      svgKpisSidebar,
      svgSystemFieldsSidebar,
      svgGeoLocationsSidebar,
      svgNetworkSidebar,
      svgSecuritySidebar,
      svgCustomerProfileSidebar,
      svgOldCustomerProfileSidebar,
      svgCompanyProfileSidebar,
      svgNewPlansSidebar,
      svgSubscriptionsSidebar,
      svgTenantsSidebar,
      svgIconDeleteCircle,
      svgIconEditCircle,
      svgPeopleFillIcon,
      svgXIcon,
      svgCompanyNetwork,
      svgTableCell
    ]);
  }
}
