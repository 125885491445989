<div class="document-fields-container">
  <div class="document-field">
    <app-upload-document-field
      [form]="form"
      [documentControlName]="documentControlName"
      [uploadedFile]="this.form?.get(documentControlName)?.value"
      [fieldName]="fieldName"
      [popUpTitle]="popupTitle"
      [isRequired]="false"
      [placeholder]="fieldPlaceholder"
      [documentParentType]="documentParentType"
      [documentTypes]="documentTypes"
      [documentParentId]="documentParentTypeId"
      [isRequired]="isRequired"
      *ngIf="document == null"
    >
    </app-upload-document-field>
    <div *ngIf="document != null">
      <h5 class="field-label">
        {{ fieldName }}
      </h5>
      <app-display-document
        [document]="document"
        [documentParentTypeId]="documentParentTypeId"
        [documentTypes]="documentTypes"
        [form]="form"
        [documentControlName]="documentControlName"
        [parentType]="documentParentType"
        [isReplaceButtonhidden]="isReplaceButtonhidden"
      ></app-display-document>
    </div>
  </div>
</div>
