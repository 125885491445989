import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { UploadDocumentComponent } from 'src/app/shared/components/upload-document/upload-document/upload-document.component';
import { dangerousMaterialClasses } from 'src/app/shared/constants/dangerousMaterialClass';
import { DocumentTypeEnum } from 'src/app/shared/models/enums/document-type.enum';
import { UploadDocumentPopUpPurpose } from 'src/app/shared/models/enums/upload-document-pop-up-purpose.enum';
import { DocumentService } from 'src/app/shared/services/document.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { DangerousGoodClass } from '../../models/dangerous-good-class.model';
import { DocumentParentType } from '../../models/enums/document-parent-type.model';
import { CreateQuoteService } from 'src/app/create-quote/services/create-quote.service';
import { QuoteService } from 'src/app/quote/services/quote.service';
import { FileViewerService } from '../file-viewer/file-viewer.service';
import { DialogService } from '../../services/dialog.service';
import { Document } from '../../models/document.model';

@Component({
  selector: 'app-dangerous-good',
  templateUrl: './dangerous-good.component.html',
  styleUrls: ['./dangerous-good.component.scss']
})
export class DangerousGoodComponent implements OnInit, OnDestroy {
  @Input() quoteId: string;
  @Input() dangerousGoodForm: UntypedFormGroup;
  @Input() dangerousGoodClasses: DangerousGoodClass[];
  uploadedDocument: Document;
  isExistError: boolean;
  isUploadingDocument: boolean;
  isUploadedDocument: boolean;

  classesNamesFormControl = new UntypedFormControl('');
  selectedSingleDocumentTypeNames: string[] = [
    DocumentTypeEnum.DangerousGoodsDeclarationDGD,
    DocumentTypeEnum.MSDS
  ];

  dangerousMaterialClasses = dangerousMaterialClasses;
  constructor(
    private documentService: DocumentService,
    private formHeplerService: FormsHelperService,
    private fb: FormBuilder,
    private fileViewerService: FileViewerService,
    private quoteService: QuoteService,
    private createQuoteService: CreateQuoteService,
    private dialogService: DialogService
  ) {}

  ngOnDestroy(): void {
    this.formHeplerService.clearValidator(this.dangerousGoodForm, 'document');
  }

  ngOnInit(): void {
    this.documentService.fileUploadingEmitter.subscribe(res => {
      this.addUploadedDocument(res.data, res.uploadedFile, res.isError);
    });

    this.classesNamesFormControl?.valueChanges.subscribe(res => {
      if (res?.length > this.classesFormArray?.controls?.length) {
        let newClassNameAdded = res[res?.length - 1];
        this.addDangerousGoodClass(newClassNameAdded, null);
      } else {
        this.removeDangerousGoodClassFormGroup();
      }
    });

    this.initFormValue();
    this.initFormValidators();
  }

  showModal() {
    if (this.document || this.uploadedDocument) {
      return;
    }

    this.dialogService.open(
      UploadDocumentComponent,
      {
        popUpPurpose: UploadDocumentPopUpPurpose.AddDangerousGoodsDocument,
        selectedSingleDocumentTypeNames: this.selectedSingleDocumentTypeNames,
        parentTypeId: this.quoteId,
        parentType: DocumentParentType.Quote
      },
      '600px',
      '550px'
    );
  }

  get document() {
    return this.dangerousGoodForm?.get('document')?.value;
  }

  deleteUploadedDocument() {
    var documentSqlId = this.uploadedDocument?.documentSqlId;
    if (documentSqlId) {
      this.quoteService
        .removeDocument(this.quoteId, documentSqlId)
        .subscribe(data => {
          let docs = this.createQuoteService.quote.documents;
          docs = docs.filter(file => file.documentSqlId !== documentSqlId);
          this.createQuoteService.quote.documents = docs;
        });
      this.uploadedDocument = null;
      this.dangerousGoodForm.get('document').setValue(null);
    }
  }

  cancelUploadingDocument() {
    this.uploadedDocument = null;
    this.dangerousGoodForm.get('document').setValue(null);
  }

  initFormValidators() {
    this.formHeplerService.addRequiredValidator(
      this.dangerousGoodForm,
      'document'
    );
  }

  addUploadedDocument(uploadedDocument: any, uploadingFile, isError: boolean) {
    if (uploadedDocument) {
      this.isExistError = isError;
      this.isUploadingDocument = uploadingFile?.isUploading;
      this.isUploadedDocument = uploadingFile?.isUploaded;

      this.uploadedDocument = uploadedDocument;
      if (!isError) {
        this.dangerousGoodForm.get('document').setValue(this.uploadedDocument);
      }
    }
  }

  get classesFormArray() {
    return this.dangerousGoodForm.get('classes') as FormArray;
  }

  addDangerousGoodClassFormGroup(newClassAdded: DangerousGoodClass) {
    let classFormContorl = this.fb.group({
      name: newClassAdded?.name,
      unNumbers: newClassAdded?.unNumbers
    });
    this.classesFormArray.push(classFormContorl);
  }

  addDangerousGoodClass(className: string, unNumbers: string) {
    let newClassAdded: DangerousGoodClass = {
      name: className,
      unNumbers: unNumbers
    };
    this.addDangerousGoodClassFormGroup(newClassAdded);
  }

  get classesNamesFormControlValue() {
    return this.classesNamesFormControl?.value;
  }

  removeDangerousGoodClassFormGroup() {
    let deletedDangerousGoodClassIndex;
    this.classesFormArray?.controls?.findIndex((x, index) => {
      let className = x?.get('name')?.value;
      let isDeletedClass = !this.classesNamesFormControlValue?.includes(
        className
      );
      if (isDeletedClass) {
        deletedDangerousGoodClassIndex = index;
        return;
      }
    });
    if (deletedDangerousGoodClassIndex >= 0) {
      this.classesFormArray?.removeAt(deletedDangerousGoodClassIndex);
    }
  }

  getDangerousGoodClassId(className) {
    return this.dangerousMaterialClasses.find(s => s.name == className)?.id;
  }

  initFormValue() {
    this.uploadedDocument = this.document;
    this.isUploadedDocument = this.document?.id ? true : false;
    if (this.dangerousGoodClasses?.length > 0) {
      this.dangerousGoodClasses?.forEach(item => {
        this.addDangerousGoodClassFormGroup(item);
      });
    }
    let classesNames = this.classesValues?.map(x => x?.name);
    this.classesNamesFormControl.setValue(classesNames);
  }

  get classesValues() {
    return this.dangerousGoodForm?.get('classes')?.value;
  }

  viewFile(path) {
    this.fileViewerService.view(path);
  }
}
