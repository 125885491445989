<app-page-header [title]="pageTitle"></app-page-header>

<div class="search-filter-margin">
  <app-page-action-header
    [placeholder]="searchPlaceholder | translate"
    [addUrl]="'./add'"
    [addLabel]="addNewButton | translate"
    canAdd="true"
  ></app-page-action-header>
</div>

<ngx-datatable
  class="material colored-header sm table table-striped table-hover"
  [loadingIndicator]="loadingIndicator"
  [rows]="rows"
  [rowHeight]="40"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
  <svg-icon
    class="pr-2 cursor-pointer"
    name="edit-circle"
    [routerLink]="['./' + row.id + '/edit']"
    *ngIf="canUpdateShipmentActivities"
  ></svg-icon>
  <svg-icon
    class="pr-2 cursor-pointer"
    name="delete-circle"
    (click)="deleteActivity(row)"
    *ngIf="canDeleteShipmentActivities"
  ></svg-icon>
</ng-template>

<ng-template #parentCategoryTemplate let-row="row" let-value="value">
  <span>{{ row.category }}</span>
</ng-template>
