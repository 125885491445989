import { Component, Input, OnInit } from '@angular/core';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import {
  documentNoteLimit,
  documentTypeNameLimit
} from 'src/app/shared/constants/shipment-details-document';
import { UploadedFile } from 'src/app/shared/models/types';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { ProductUpdateDocumentNote } from 'src/app/shared/models/product-update-document-notes.model';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { ModuleName } from '../../models/enums/module-name.enum';
import { CreateProductService } from 'src/app/create-product/services/create-product.service';
import { SupplierDocument } from 'src/app/company/models/supplier-document.model';
import { UploadDocumentComponent } from '../upload-document/upload-document/upload-document.component';
import { UploadDocumentPopUpPurpose } from '../../models/enums/upload-document-pop-up-purpose.enum';
import { DeleteDocumentComponent } from '../delete-document/delete-document.component';
import { ActivatedRoute } from '@angular/router';
import { DocumentTypeEnum } from '../../models/enums/document-type.enum';
import { DocumentType } from '../../models/document-type.model';
import { DocumentTypeService } from 'src/app/admin-portal-configs/services/document-type.service';
import { CompanyDocument } from '../../models/company-document';
import { CompanyUpdateDocumentNote } from '../../models/company-update-document-note';
import { Company2Service } from 'src/app/company2/services/company2.service';
import { ReplaceLinkedDocumentComponent } from 'src/app/product-library/components/replace-linked-document/replace-linked-document.component';
import { DocumentParentType } from '../../models/enums/document-parent-type.model';
import { DialogService } from '../../services/dialog.service';
import { DeleteLinkedDocumentComponent } from 'src/app/product-library/components/delete-linked-document/delete-linked-document.component';

@Component({
  selector: 'app-uploaded-documents-details',
  templateUrl: './uploaded-documents-details.component.html',
  styleUrls: ['./uploaded-documents-details.component.scss']
})
export class UploadedDocumentsDetailsComponent implements OnInit {
  @Input() document: CompanyDocument | ProductDocument | SupplierDocument;
  @Input() popOverPlacement: string = 'bottom-left';
  @Input() typeId: string;
  @Input() type?: string;
  @Input() module: ModuleName;

  @Input() canDownloadDocument: boolean;
  @Input() canReplaceDocument: boolean;
  @Input() canDeleteDocument: boolean;

  moduleName = ModuleName;

  documentNoteLimit = documentNoteLimit;
  documentTypeNameLimit = documentTypeNameLimit;
  uploadedFile: UploadedFile;
  isError: boolean = false;
  errorMsg: string = null;
  isShowInput: boolean = false;
  insertedNotes: string;

  supplierId: string;
  commercialInvoiceDocument: DocumentType;

  constructor(
    private fileViewerService: FileViewerService,
    private alertService: AlertService,
    private documentService: DocumentService,
    private createProductService: CreateProductService,
    private documenTypeService: DocumentTypeService,
    private route: ActivatedRoute,
    private companyService: Company2Service,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.documentService.fileUploadingEmitter.subscribe(res => {
      this.errorMsg = res.errorMsg;
      this.isError = res.isError;
      this.uploadedFile = res.uploadedFile;
    });

    this.supplierId = this.route.snapshot.paramMap.get('id');
    this.getCommercialInvoiceDocumentType();
    this.insertedNotes = this.document?.notes;
  }

  viewFile(document) {
    if (document.isDeleted) {
      return;
    }

    this.fileViewerService.view(document.path);
  }

  cancelUploadingFile(file: any) {
    this.uploadedFile.isCanceled = true;
    this.uploadedFile.isUploaded = false;
    this.uploadedFile.isUploading = false;
    this.documentService.cancelFileUploadingEmitter.emit({
      val: false,
      uploadedFile: this.uploadedFile
    });
  }

  reuploadFile(file: any) {
    this.documentService.uploadingFile(false, this.uploadedFile, null, true);
    this.isError = false;
  }

  toggleShowInput() {
    this.isShowInput = !this.isShowInput && !this?.document?.isDeleted;
  }

  onValueChange() {
    if (this.module == this.moduleName.ProductLibrary) {
      let productUpdateDocumentNote = {
        documentSqlId: this.document?.documentSqlId,
        note: this.insertedNotes
      } as ProductUpdateDocumentNote;
      this.createProductService
        .updateProductDocumentNote(this.typeId, productUpdateDocumentNote)
        .subscribe(data => {
          this.document.notes = this.insertedNotes ?? null;
          this.toggleShowInput();
        });
    } else if (this.module == this.moduleName.CompanyProfile) {
      let companyUpdateDocumentNote = {
        documentId: this.document?.id,
        description: this.insertedNotes
      } as CompanyUpdateDocumentNote;
      this.companyService
        .updateDocumentDescription(companyUpdateDocumentNote)
        .subscribe(data => {
          this.document.notes = this.insertedNotes ?? null;
          this.toggleShowInput();
        });
    }
  }

  download(path: string, fileName: string) {
    if (
      !(
        this.uploadedFile?.isUploading &&
        this.uploadedFile?.typeName === this.document.documentTypeName
      ) &&
      !this.document.isDeleted
    ) {
      var link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', path);

      document.body.appendChild(link);
      link.click();
      link.remove();
      this.alertService.success('Document successfully downloading.');
    }
  }

  replace() {
    if (this.document.isDeleted) {
      return;
    }

    if (this.type === DocumentParentType.Product) {
      this.dialogService.open(
        ReplaceLinkedDocumentComponent,
        {
          currentDocumentType:
            this.document?.documentTypeName ||
            this.document?.documentType?.name,
          parentId: this.typeId,
          documentPath: this.document?.path,
          selectedSingleDocumentTypeId: this.document?.documentTypeId,
          documentToReplaceSqlId: this.document?.documentSqlId,
          selectedSingleDocumentTypeNames: this.document?.documentTypeName
        },
        '820px'
      );
    } else {
      this.dialogService.open(
        UploadDocumentComponent,
        {
          parentTypeId: this.typeId,
          parentType: this.type,
          formTitle: 'Replace Document',
          selectedSingleDocumentTypeId: this.document?.documentTypeId,
          popUpPurpose: UploadDocumentPopUpPurpose.ReplaceExistingDocument,
          documentToReplaceSqlId: this.document?.documentSqlId,
          currentDocument: this.document,
          selectedSingleDocumentTypeNames: this.document?.documentTypeName,
          documentTypes:
            this.document?.documentTypeName ===
              DocumentTypeEnum.CommercialInvoice
              ? [this.commercialInvoiceDocument]
              : null
        },
        '600px',
        '675px'
      );
    }
  }

  getCommercialInvoiceDocumentType(): void {
    this.documenTypeService
      .getDocumentTypeByName(DocumentTypeEnum.CommercialInvoice)
      .subscribe(res => {
        this.commercialInvoiceDocument = res;
      });
  }

  delete() {
    if (this.document.isDeleted) {
      return;
    }

    if (this.type === DocumentParentType.Product) {
      this.dialogService.open(
        DeleteLinkedDocumentComponent,
        {
          currentDocumentType:
            this.document?.documentTypeName ||
            this.document?.documentType?.name,
          parentId: this.typeId,
          documentPath: this.document?.path,
          selectedSingleDocumentTypeId: this.document?.documentTypeId,
          documentToReplaceSqlId: this.document?.documentSqlId
        },
        '820px',
        '696px'
      );
    } else {
      this.dialogService.open(
        DeleteDocumentComponent,
        {
          parentId: this.typeId || this.supplierId,
          module: this.module,
          documentSqlId: this.document.documentSqlId
        },
        '600px',
        '650px'
      );
    }
  }

  get isExceedDocumentTypeNameLimit(): boolean {
    return this.document?.documentTypeName?.length > documentTypeNameLimit;
  }

  get isExceedFileNameLimit(): boolean {
    return this.document?.fileName?.length > documentTypeNameLimit;
  }

  get isExceedNoteLimit(): boolean {
    return this.document?.notes?.length > this.documentNoteLimit;
  }

  get isSupplierModule(): boolean {
    return this.module == ModuleName.Supplier;
  }
  get isCompanyModule(): boolean {
    return this.module == ModuleName.CompanyProfile;
  }
}
