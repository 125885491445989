<div class="row p-3">
  <div class="mb-3 col-9">
    <app-page-header
      [title]="
        (isNewIndustry
          ? 'config.management.Industry.New'
          : 'config.management.Industry.Edit'
        ) | translate
      "
    ></app-page-header>
  </div>

  <span class="col-3">
    (<span class="required-text">*</span>) means that this field is mandatory
  </span>

  <div class="mb-3 col-12">
    <app-error-badge
      message="{{
        'config.management.Industry.Editor.DuplicateEntry' | translate
      }}"
      *ngIf="duplicateIndustry"
    ></app-error-badge>
  </div>

  <div class="col-lg-12 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="industryForm" id="industryForm" (ngSubmit)="save()">
      <fieldset class="nested-fieldset">
        <div class="form-group mb-3">
          <label for="name"
            >{{ "config.management.Industry.Name" | translate }}
            <span class="required-text">*</span></label
          >
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                industryForm.get('name').errors &&
                industryForm.get('name').touched
            }"
            type="text"
            class="form-control"
            name="txt-industryName"
          />
          <small
            *ngIf="
              industryForm.get('name').touched &&
              industryForm.get('name').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.Industry.Editor.IndustryNameRequired"
                | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="name">{{
            "config.management.Industry.Description" | translate
          }}</label>
          <input
            formControlName="description"
            type="text"
            class="form-control"
            name="txt-industryDescription"
          />
        </div>

        <div class="form-group mb-3">
          <label for="name">{{
            "config.management.Industry.ParentIndustry" | translate
          }}</label>
          <ng-select
            [items]="industryParents"
            [bindLabel]="'name'"
            [searchable]="false"
            [clearable]="false"
            formControlName="parentIndustry"
            name="txt-parentIndustry"
          >
          </ng-select>
        </div>
      </fieldset>
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 mt-2">
          <div class="btn-container">
            <button class="btn btn-cancel" (click)="cancel()">
              {{ "config.management.Industry.Editor.Cancel" | translate }}
            </button>
            <button
              class="btn btn-save"
              type="submit"
              [disabled]="!isFormValid && isSaving"
            >
              {{
                isSaving
                  ? ("config.management.Industry.Editor.Saving" | translate)
                  : ("config.management.Industry.Editor.Save" | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
