import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { AccessToken, LoginResponse } from '../models/login-response.model';
import { JwtHelper } from './jwt-helper';

@Injectable()
export class OidcHelperService {
  private get baseUrl() {
    return this.configurations.baseUrl;
  }
  private tokenExpiresIn: Date;
  private clientId = 'freepl_spa';
  private scope = 'openid email phone profile offline_access roles freepl_api';
  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private configurations: ConfigurationService,
    private localStorage: LocalStoreManager
  ) {}

  loginWithPassword(userName: string, password: string) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const params = new HttpParams()
      .append('username', userName)
      .append('password', password)
      .append('client_id', this.clientId)
      .append('grant_type', 'password')
      .append('scope', this.scope);

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument()).pipe(
      mergeMap(() => {
        return this.http.post<LoginResponse>(
          this.oauthService.tokenEndpoint,
          params,
          { headers: header }
        );
      })
    );
  }

  refreshLogin() {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const params = new HttpParams()
      .append('refresh_token', this.refreshToken)
      .append('client_id', this.clientId)
      .append('grant_type', 'refresh_token');

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument()).pipe(
      mergeMap(() => {
        return this.http.post<LoginResponse>(
          this.oauthService.tokenEndpoint,
          params,
          { headers: header }
        );
      })
    );
  }

  loginWithExternalToken(
    token: string,
    provider: string,
    email?: string,
    password?: string
  ) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let params = new HttpParams()
      .append('token', token)
      .append('provider', provider)
      .append('client_id', this.clientId)
      .append('grant_type', 'delegation')
      .append('scope', this.scope);

    if (email) {
      params = params.append('email', email);
    }

    if (password) {
      params = params.append('password', password);
    }

    this.oauthService.issuer = this.baseUrl;

    return from(this.oauthService.loadDiscoveryDocument()).pipe(
      mergeMap(() => {
        return this.http.post<LoginResponse>(
          this.oauthService.tokenEndpoint,
          params,
          { headers: header }
        );
      })
    );
  }

  initLoginWithGoogle() {
    this.oauthService.configure({
      issuer: 'https://accounts.google.com',
      redirectUri: this.configurations.baseUrl + '/google-login',
      clientId: this.configurations.googleClientId,
      strictDiscoveryDocumentValidation: false,
      scope: 'openid profile email',
      sessionChecksEnabled: false
    });

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService.initImplicitFlow();
    });
  }

  initLoginWithFacebook() {
    this.oauthService.configure({
      loginUrl: 'https://www.facebook.com/v5.0/dialog/oauth',
      redirectUri: this.configurations.baseUrl + '/facebook-login',
      clientId: this.configurations.facebookClientId,
      scope: 'email',
      oidc: false,
      sessionChecksEnabled: false
    });

    this.oauthService.initImplicitFlow();
  }

  get accessToken(): string {
    return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
  }

  get isLoggedIn(): boolean {
    return this.accessToken != null;
  }

  public set accessTokenExpiryDate(expiresIn: Date) {
    this.tokenExpiresIn = expiresIn;
  }
  public get accessTokenExpiryDate(): Date {
    if (this.tokenExpiresIn == null && this.isLoggedIn) {
      const jwtHelper = new JwtHelper();
      const decodedAccessToken = jwtHelper.decodeToken(
        this.accessToken
      ) as AccessToken;
      const accessTokenExpiry = new Date(
        decodedAccessToken.exp * 1000 + 12 * 3600
      );
      this.tokenExpiresIn = accessTokenExpiry;
    }
    return this.tokenExpiresIn;
  }

  get refreshToken(): string {
    return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
  }
}
