import { Component, Input, isDevMode, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DomesticTruckingInformation } from 'src/app/shared/models/domestic-trucking-information.model';
import { TruckingRate } from 'src/app/shared/models/shipment/shipmentServices.model';
import { HelperService } from '../../services/helper.service';
import { DomesticTruckingLookups } from '../../models/domestic-trucking-lookups.model';
import { RouteInformation } from '../../models/route-information.model';
import { TruckType } from '../../models/truck-type.model';
import { LandTrucking } from '../../models/land-trucking';
import { LandTruckingInformation } from '../../models/land-trucking-information';
import { FormsHelperService } from '../../services/forms-helper.service';
import { TruckTypeAndTruckSubType } from '../../models/truck-type-and-truck-sub-type.model';
import { TruckingLoadType } from '../../models/enums/trucking-load-type.enum';

@Component({
  selector: 'app-add-domestic-trucking-information',
  templateUrl: './add-domestic-trucking-information.component.html',
  styleUrls: ['./add-domestic-trucking-information.component.scss']
})
export class AddDomesticTruckingInformationComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() domesticTruckingInformation: DomesticTruckingInformation;
  @Input() landTrucking: LandTrucking;
  @Input() rates: TruckingRate[];
  @Input() isdomesticTruckingFromQuote: boolean = false;
  @Input() routeInformation: RouteInformation;
  @Input() isDisableForm: boolean;
  @Input() isLandServiceSelectedStandalone: boolean;
  @Input() landFormGroup: UntypedFormGroup;
  @Input() newTruckTypesAndSubTypes: TruckTypeAndTruckSubType[] = [];

  landForm: FormArray;
  domesticTruckingLookups: DomesticTruckingLookups;
  truckTypes: TruckType[];
  isDevelopmentEnvironment: boolean;

  constructor(
    private helperService: HelperService,
    private fb: FormBuilder,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.isDevelopmentEnvironment = isDevMode();

    this.truckTypes = this.newTruckTypesAndSubTypes;

    if (!this.landFormGroup) {
      this.declareLandFormGroup();
    }
    this.landForm = this.landFormGroup.get(
      'landTruckingInformation'
    ) as FormArray;
    if (this.landTrucking != null) {
      this.setLandValues();
    } else {
      this.addTruckingRecord();
    }

    if (!this.domesticTruckingInformation) {
      this.setDropDownsValues();
    } else {
      this.setDomesticValues();
    }
    this.getLookups();
    this.setupValidation();
  }

  declareLandFormGroup(): void {
    this.landFormGroup = this.fb.group({
      landTruckingInformation: this.fb.array([]),
      specialRequirements: null
    });
  }

  addTruckingRecord(landTruckingInformation?: LandTruckingInformation): void {
    const truckDetailsForm = this.fb.group({
      truckType: [
        landTruckingInformation?.truckType,
        !this.isdomesticTruckingFromQuote &&
        this.isDevelopmentEnvironment &&
        this.isSelectedLoadTypeLTL
          ? null
          : Validators.required
      ],
      loadType: [landTruckingInformation?.loadType, Validators.required],
      quantity: [
        landTruckingInformation?.quantity || 1,
        [Validators.required, Validators.min(1)]
      ],
      truckSubType: [landTruckingInformation?.truckSubType, Validators.required]
    });
    this.landForm.push(truckDetailsForm);
  }

  deleteTruckingRecord(index: number): void {
    this.landForm.removeAt(index);
  }

  get isDeletable(): boolean {
    return this.landForm?.controls.length > 1;
  }

  setDomesticValues() {
    this.form.patchValue({
      quantity: this.domesticTruckingInformation.quantity,
      specialRequirements: this.domesticTruckingInformation.specialRequirements,
      loadType: this.domesticTruckingInformation.loadType,
      truckType: this.domesticTruckingInformation.truckType,
      truckSubType: this.domesticTruckingInformation.truckSubType
    });
  }

  setLandValues() {
    this.landTrucking.landTruckingInformation.forEach(
      landTruckingInformation => {
        this.addTruckingRecord(landTruckingInformation);
      }
    );
    this.setSpecailRequirementsLand();
  }

  setSpecailRequirementsLand() {
    this.landFormGroup
      .get('specialRequirements')
      .patchValue(this.landTrucking.specialRequirements);
  }

  setDropDownsValues() {
    if (this.rates && this.rates.length > 0) {
      this.form.patchValue({
        loadType: this.rates[0].loadType,
        truckType: this.rates[0].truckType,
        truckSubType: this.rates[0].truckSubType
      });
    }
  }

  onChangeTruckType(event: string): void {
    if (!this.isDevelopmentEnvironment || this.isdomesticTruckingFromQuote) {
      return;
    }

    let truckSubType = this.newTruckTypesAndSubTypes?.find(
      t => t.name === event
    )?.truckSubTypes;

    if (truckSubType?.length > 0) {
      this.form.get('truckSubType')?.patchValue(truckSubType[0]?.name);
    }
  }

  getLookups() {
    if (this.isdomesticTruckingFromQuote) {
      this.helperService
        .getDomesticTruckingLookups(this.isLandServiceSelectedStandalone)
        .subscribe(response => {
          this.domesticTruckingLookups = response;
          this.getTruckTypes();
        });
    }
  }
  onChangeGetTruckTypes(event: any) {
    let loadTypeId = this.domesticTruckingLookups?.loadTypes.find(
      i => i.name == event
    )?.id;
    this.helperService
      .getCompatibleTruckTypes(loadTypeId, this.isLandServiceSelectedStandalone)
      .subscribe(response => {
        this.truckTypes = [];
        this.truckTypes = response;
      });
  }

  errorExistInFormField(
    truckdetailsform: UntypedFormGroup,
    form: UntypedFormGroup,
    formControlName: string
  ): boolean {
    if (this.isLandServiceSelectedStandalone) {
      if (formControlName == 'quantity') {
        return (
          truckdetailsform?.get(formControlName)?.hasError('required') ||
          truckdetailsform?.get(formControlName)?.hasError('min')
        );
      }
      return this.formsHelperService.isInvalidFormControl(
        truckdetailsform,
        formControlName
      );
    } else {
      return this.formsHelperService.isInvalidFormControl(
        form,
        formControlName
      );
    }
  }

  get isSelectedLoadTypeLTL(): boolean {
    return (
      this.rates?.length > 0 && this.rates[0]?.loadType === TruckingLoadType.LTL
    );
  }
  private setupValidation() {
    if (this.isdomesticTruckingFromQuote) {
      this.form.controls['loadType'].setValidators([Validators.required]);
      this.form.controls['truckType'].setValidators([Validators.required]);
      this.form.controls['truckSubType'].setValidators([Validators.required]);
    }

    this.form.controls['quantity'].setValidators([
      Validators.required,
      Validators.min(1)
    ]);
  }

  private getTruckTypes() {
    let loadTypeName = this.form.controls['loadType'].value;
    if (this.isdomesticTruckingFromQuote && loadTypeName) {
      let loadTypeId = this.domesticTruckingLookups?.loadTypes.find(
        i => i.name == loadTypeName
      )?.id;
      this.helperService
        .getCompatibleTruckTypes(
          loadTypeId,
          this.isLandServiceSelectedStandalone
        )
        .subscribe(response => {
          this.truckTypes = [];
          this.truckTypes = response;
        });
    }
  }
}
