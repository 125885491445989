export enum DocumentTypeEnum {
  MSDS = 'Material Safety Data Sheet (MSDS)',
  Other = 'Other',
  Eur1Certficate = 'Eur1 Certficate',
  CertificateOfOrigin = 'Certificate of Origin (COO)',
  PackingList = 'Packing List',
  CommercialInvoice = 'Commercial Invoice',
  CommercialRegister = 'Commercial Register',
  CustomerContractWithXPL = 'Customer Contract with XPL',
  VATRegistration = 'VAT Registration',
  TaxCard = 'Tax Card',
  ProformaInvoice = 'Proforma Invoice',
  MasterBillOfLading = 'Master Bill of Lading (MBL)',
  MasterAirWaybill = 'Master Air Waybill (MAWB)',
  ImportLicense = 'Import License',
  ExportLicense = 'Export License',
  OceanFromSaudiArabia = 'Ocean From Saudi Arabia',
  DangerousGoodsDeclarationDGD = 'Dangerous Goods Declaration (DGD)',
  RoadBillOfLading = 'Road Bill of Lading',
  HouseAirWaybill = 'House Air Waybill (HAWB)',
  HouseBillOfLading = 'House Bill of Lading (HBL)',
  FormFour = 'Form 4',
  SalesOrder = 'Sales Order (SO)',
  PurchaseOrder = 'Purchase Order (PO)',
  RegistrationLicense = 'Registration License',
  TechnicalFile = 'Technical File',
  WaiverRequest = 'Waiver Request',
  ApprovalFile = 'Approval File',
  ApprovalDocument = 'Approval Document'
}
