<div class="old-version-expandable w-100">
  <div class="older-version-row d-flex w-100 justify-content-end">
    <div
      class="equivalent-space-for-document-type"
      [ngClass]="{ 'w-25': isProductLibrary }"
    ></div>
    <div class="older-version col-title">Older Versions</div>
    <div class="notes col-title">
      {{ module === moduleName.Supplier ? "Expiry Date" : "Notes" }}
    </div>
    <div class="actions col-title">
      <span
        *ngIf="canDownloadDocument || canReplaceDocument || canDeleteDocument"
        >Actions</span
      >
    </div>
    <div class="equivalent-space-for-exapnsion-arrow"></div>
  </div>

  <div
    class="older-version-row d-flex w-100 justify-content-end"
    *ngFor="let version of oldVersions; let index = index"
  >
    <div
      class="equivalent-space-for-document-type"
      [ngClass]="{ 'w-25': isProductLibrary }"
    ></div>
    <div class="older-version file">
      <div
        class="file-name cursor-pointer"
        [ngClass]="version.isDeleted ? 'deleted-file-name' : ''"
        (click)="viewFile(version)"
      >
        {{ version?.fileName }}
      </div>

      <div class="file-upload-date">
        <span *ngIf="module === moduleName.CompanyProfile">
          {{ version.createdAt | fromutc | date: "dd/MM/YYYY hh:mm a" }}
        </span>
        <span *ngIf="module != moduleName.CompanyProfile">
          {{ version.createdAt | date: "dd/MM/YYYY hh:mm a" }}
        </span>
        -
        {{ version?.uploadedBy?.fullName }},
        {{ version?.uploadedBy?.companyName }}
      </div>
      <div class="document-deleted d-flex" *ngIf="version.isDeleted">
        <div class="deleted-text">Deleted</div>
        <span
          [placement]="
            index == oldVersions?.length - 1 ? 'top-left' : 'bottom-left'
          "
          [ngbPopover]="deletionReasonTemplate"
          triggers="mouseenter:mouseleave"
          ><svg-icon [name]="'red-info'"></svg-icon
        ></span>
      </div>
    </div>

    <div class="notes" *ngIf="module === moduleName.Supplier; else NotesBlock">
      <div>
        <div>
          {{ version.expirationDate | date: "dd/MM/yyyy" }}
        </div>
      </div>
    </div>
    <ng-template #NotesBlock>
      <div
        class="notes document-notes"
        *ngIf="version.notes; else NoNotesAddedBlock"
        placement="top-left"
        [ngbPopover]="isExceedNoteLimit(version) ? noteTemplate : ''"
        triggers="mouseenter:mouseleave"
      >
        {{ version.notes | slice: 0:documentNoteLimit }}
        {{ isExceedNoteLimit(version) ? "..." : "" }}
      </div>
    </ng-template>

    <div class="actions" [class]="version.isDeleted ? 'disabled' : ''">
      <span
        class="action-btn download-btn cursor-pointer"
        *ngIf="canDownloadDocument"
        (click)="download(version)"
      >
        <svg-icon name="download"></svg-icon>
      </span>

      <span
        class="action-btn download-btn replace-btn cursor-pointer"
        *ngIf="canReplaceDocument"
      >
        <svg-icon name="replaceDocument"></svg-icon>
      </span>

      <span
        class="action-btn delete-btn cursor-pointer"
        *ngIf="canDeleteDocument"
        (click)="delete(version)"
      >
        <svg-icon name="shipmentDetailsActionDelete"></svg-icon>
      </span>
      <ng-template #deletionReasonTemplate>
        <div class="deletion-popOver">
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Deleted on</div>
            <div class="reason-info col-8 px-0">
              <span *ngIf="module === moduleName.CompanyProfile">
                {{ version.deletedAt | fromutc | date: "dd/MM/YYYY hh:mm a" }}
              </span>
              <span *ngIf="module != moduleName.CompanyProfile">
                {{ version.deletedAt | date: "dd/MM/YYYY hh:mm a" }}
              </span>
            </div>
          </div>
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Deleted by</div>
            <div class="reason-info col-8 px-0">
              {{ version.deletedBy?.fullName }},
              {{ version.deletedBy?.companyName }}
            </div>
          </div>
          <div class="deletion-reason-con row">
            <div class="reason-title col-4 pl-0">Reason</div>
            <div class="deletion-reason reason-info col-8 px-0">
              {{ version.deletionReason }}
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="equivalent-space-for-exapnsion-arrow"></div>
    <ng-template #NoNotesAddedBlock>
      <div class="notes document-no-notes-added">No added notes.</div>
    </ng-template>
    <ng-template #noteTemplate>
      <div class="note-popup">
        <div>{{ version?.notes }}</div>
      </div>
    </ng-template>
  </div>
</div>
