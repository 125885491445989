<div class="contractual-terms-container" [formGroup]="form">
  <div class="row m-0 padding-between-row">
    <div class="field">
      <div class="text-label">
        Contract Start Date<span class="required">*</span>
      </div>
      <mat-form-field
        appearance="fill"
        class="custom-datepicker"
        [ngClass]="{
          error: isInvalidFormControl('contractStartDate')
        }"
      >
        <input
          matInput
          [matDatepicker]="datepicker_start"
          formControlName="contractStartDate"
          placeholder="dd/mm/yyyy"
          (focus)="datepicker_start.open(); cleardatePiker('contractStartDate')"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="datepicker_start">
          <svg-icon matDatepickerToggleIcon name="datePickerIcon"></svg-icon>
        </mat-datepicker-toggle>
        <mat-datepicker
          #datepicker_start
          [panelClass]="'custom-today'"
          [calendarHeaderComponent]="matDateHeader"
          (opened)="onOpenDatePicker()"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="field">
      <div class="text-label">
        Contract End Date<span class="required">*</span>
      </div>

      <mat-form-field
        appearance="fill"
        class="custom-datepicker"
        [ngClass]="{
          error: isInvalidFormControl('contractEndDate')
        }"
      >
        <input
          matInput
          [matDatepicker]="datepicker_end"
          formControlName="contractEndDate"
          placeholder="dd/mm/yyyy"
          (focus)="datepicker_end.open(); cleardatePiker('contractEndDate')"
          readonly
          [min]="futureDate"
        />
        <mat-datepicker-toggle matSuffix [for]="datepicker_end">
          <svg-icon matDatepickerToggleIcon name="datePickerIcon"></svg-icon>
        </mat-datepicker-toggle>
        <mat-datepicker
          #datepicker_end
          [panelClass]="'custom-today'"
          [calendarHeaderComponent]="matDateHeader"
          (opened)="onOpenDatePicker()"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-0 padding-between-row">
    <div class="field ">
      <div class="text-label">
        Renewal Type<span
          *ngIf="isLandFreight || isCustomerCompanyEntity"
          class="required"
          >*
        </span>
      </div>
      <ng-select
        [items]="renewalTypes"
        bindValue="item"
        formControlName="renewalType"
        [clearable]="false"
        [ngClass]="{ error: isInvalidFormControl('renewalType') }"
        [searchable]="false"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="field">
      <div class="text-label">Renewal Cancellation Window</div>
      <input
        formControlName="renewalCancellationWindow"
        [ngClass]="{
          error: isInvalidFormControl('renewalCancellationWindow')
        }"
        class="input-field"
        placeholder="eg. 30 days"
        maxLength="3"
        appOnlyDigitsDirective
        mask="0*"
      />
    </div>
  </div>

  <div class="row m-0" *ngIf="!isCustomerCompanyEntity">
    <div class="field">
      <div class="text-label">
        Payment Terms (Days)<span class="required">*</span>
      </div>
      <ng-select
        [items]="paymentTerms"
        formControlName="paymentTerm"
        [bindLabel]="'type'"
        [ngClass]="{ error: isInvalidFormControl('paymentTerm') }"
        [clearable]="false"
        [searchable]="false"
      >
        <ng-template ng-label-tmp let-item="item">
          <div *ngIf="item?.type != 'Advance payment'; else advancePaymentTemp">
            {{ item?.term }} {{ item?.type }}
          </div>
          <ng-template #advancePaymentTemp>
            {{ item?.type }}
          </ng-template>
        </ng-template>
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>

            <div
              *ngIf="item?.type != 'Advance payment'; else advancePaymentTemp"
            >
              {{ item?.term }} {{ item?.type }}
            </div>
            <ng-template #advancePaymentTemp>
              <div>{{ item?.type }}</div>
            </ng-template>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div
      class="field"
      *ngIf="targetPricelist == entityType.CustomsClearancePricelist"
    >
      <div class="text-label">
        Official Receipts Payments Terms<span class="required">*</span>
      </div>
      <ng-select
        [items]="officialReceiptsPaymentsTerms"
        bindValue="item"
        formControlName="officialReceiptsPaymentsTerms"
        [clearable]="false"
        [ngClass]="{
          error: isInvalidFormControl('officialReceiptsPaymentsTerms')
        }"
        [searchable]="false"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div class="d-flex">
            <div class="circle"></div>
            {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="row m-0 padding-between-row" *ngIf="isCustomerCompanyEntity">
    <div class="field">
      <div class="text-label">
        Has Credit Limit<span class="required">*</span>
      </div>
      <ng-select
        formControlName="hasCreditLimit"
        [items]="creditLimitOptions"
        [bindLabel]="'type'"
        [ngClass]="{ error: isInvalidFormControl('hasCreditLimit') }"
        [clearable]="false"
        [searchable]="false"
        (change)="onCreditLimitChange($event)"
      >
      </ng-select>
    </div>
    <div class="field">
      <div class="text-label">
        Credit Limit Amount<span class="required" *ngIf="hasCreditLimitSelected"
          >*
        </span>
      </div>
      <input
        formControlName="creditLimitAmount"
        [ngClass]="{
          error: isInvalidFormControl('creditLimitAmount'),
          disabled: !hasCreditLimitSelected
        }"
        class="input-field"
        appOnlyDigitsDirective
        [mask]="'separator.0'"
        [thousandSeparator]="','"
      />
    </div>
  </div>

  <div class="row m-0" *ngIf="isCustomerCompanyEntity">
    <div class="field larger-field mr-0">
      <div class="text-label">
        How would you like to set the payment terms for services?<span
          class="required"
          >*
        </span>
      </div>

      <ng-select
        formControlName="paymentTermType"
        [items]="paymentTermsTypes"
        [clearable]="false"
        [ngClass]="{ error: isInvalidFormControl('paymentTermType') }"
        [searchable]="false"
      ></ng-select>
    </div>
  </div>
</div>
