<div class="add-edit-activity-container">
  <div class="header d-flex justify-content-between w-100 align-items-center">
    <app-page-header [title]="pageTitle"></app-page-header>
    <div class="note">
      (<span class="field-mandatory">*</span>) means that this field is
      mandatory
    </div>
  </div>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="row m-0 padding-between-row">
      <div class="field larger-field">
        <div class="text-label">
          {{ nameField }}<span class="required">*</span>
        </div>
        <input class="input-field" formControlName="name" />
      </div>
    </div>

    <div *ngIf="!isVoidActivity; else voidActivitiesForm">
      <div class="row m-0 padding-between-row">
        <div class="field">
          <div class="text-label">
            Linked System Status<span class="required">*</span>
          </div>
          <ng-select
            [items]="systemStatuses"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select System status to link"
            bindLabel="name"
            bindValue="id"
            formControlName="statusId"
          >
          </ng-select>
        </div>
        <div class="field">
          <div class="text-label">
            Linked Progress Point<span class="required">*</span>
          </div>
          <ng-select
            [items]="progressPoints"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select Progress point to link"
            bindLabel="name"
            bindValue="id"
            formControlName="progressId"
          >
          </ng-select>
        </div>
      </div>

      <div class="row m-0">
        <div class="field">
          <div class="text-label">
            Shipment Type<span class="required">*</span>
          </div>
          <ng-select
            [items]="shipmentTypes"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select a Shipment type"
            formControlName="shipmentTypes"
            [multiple]="true"
            [closeOnSelect]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <mat-checkbox
                [checked]="isShipmentTypeSelected(item)"
                (change)="onShipmentTypeChange(item, $event)"
              >
                <span class="checkbox-span">{{ item }}</span>
              </mat-checkbox>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <ng-template #voidActivitiesForm>
      <div class="row m-0 padding-between-row">
        <div class="field larger-field">
          <div class="text-label">
            Parent Category<span class="required">*</span>
          </div>
          <ng-select
            [items]="parentCategories"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select parent category"
            bindLabel="name"
            bindValue="id"
            formControlName="categoryId"
          >
          </ng-select>
        </div>
      </div>
    </ng-template>

    <div class="col-12 row justify-content-end buttons-container">
      <div class="col-5 d-flex gap-3">
        <button
          class="button btn-cancel"
          [routerLink]="[activitiesListRouterLink]"
          [disabled]="isSaving"
        >
          {{ "forms.Cancel" | translate }}
        </button>
        <button
          class="button btn-submit"
          type="submit"
          [disabled]="isSaving || form.invalid"
        >
          {{ "forms.Save" | translate }}
          <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
        </button>
      </div>
    </div>
  </form>
</div>
