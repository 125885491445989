<div class="section" [formGroup]="routeDetailsForm">
  <div class="header">ROUTE DETAILS</div>
  <div class="row fields-padding" *ngIf="!isDomesticRoute">
    <div class="col-12 d-flex">
      <div class="field" *ngIf="isForCustomerCompany">
        <label class="title">
          Supplier Name<span class="required">*</span>
        </label>
        <ng-select
          [clearable]="false"
          [readonly]="true"
          formControlName="supplierName"
        >
        </ng-select>
      </div>
      <div class="field">
        <label class="title">
          Route Direction<span class="required">*</span>
        </label>
        <ng-select
          [items]="routeDirectionLookup"
          formControlName="routeDirection"
          [ngClass]="isInvalid('routeDirection') ? 'error' : ''"
          [clearable]="false"
          [searchable]="false"
          (change)="onChangeRouteDirection()"
          [readonly]="isDisabled"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
            let-value="item"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex">
      <div class="field">
        <label class="title">
          Pickup From<span class="required">*</span>
        </label>
        <ng-select
          [typeahead]="
            isReturntDistricts(true) ? districtsPickUpFrom$ : pickupPorts$
          "
          formControlName="pickupFrom"
          blindValue="id"
          [clearable]="false"
          [ngClass]="
            (!isForCustomerCompany && isInvalid('pickupFrom')) || isSameRoute
              ? 'error'
              : ''
          "
          [items]="
            (isReturntDistricts(true)
              ? districtsObservablePickUpFrom
              : pickupPortsObservable
            ) | async
          "
          [readonly]="isDisabled"
        >
          <ng-template ng-label-tmp let-item="item">
            <span> {{ item.name }}, {{ item.cityName }} </span>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item.name }} , {{ item.cityName }}
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="field">
        <label class="title"> Deliver To<span class="required">*</span> </label>
        <ng-select
          [typeahead]="
            isReturntDistricts(false) ? districtsDeliverTo$ : deliverPorts$
          "
          formControlName="deliverTo"
          blindValue="id"
          [clearable]="false"
          [ngClass]="
            (!isForCustomerCompany && isInvalid('deliverTo')) || isSameRoute
              ? 'error'
              : ''
          "
          [items]="
            (isReturntDistricts(false)
              ? districtsObservableDeliverTo
              : deliverPortsObservable
            ) | async
          "
          [readonly]="isDisabled"
        >
          <ng-template ng-label-tmp let-item="item">
            <span> {{ item.name }}, {{ item.cityName }} </span>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item.name }} , {{ item.cityName }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</div>
