import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminSidebarRoutesEnum } from '../../models/enums/admin-side-bar-routes.enum';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {
  adminSidebarRoutesEnum = AdminSidebarRoutesEnum;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {}

  isCurrentPage(page: string): boolean {
    return this.router.url.includes(page);
  }

  isCurrentFragment(fragment: string): boolean {
    return this.router.url.includes(fragment);
  }

  get canViewShipmentWorkflow(): boolean {
    return this.authService.canViewShipmentWorkflow;
  }

  get canViewRegistrationWorkflows(): boolean {
    return this.authService.canViewRegistrationWorkflows;
  }

  get canViewShipmentActivities(): boolean {
    return this.authService.canViewShipmentActivities;
  }

  get canViewRegistrationActivities(): boolean {
    return this.authService.canViewRegistrationActivities;
  }

  get canViewShipmentKPIs(): boolean {
    return this.authService.canViewShipmentKPIs;
  }

  get canViewProductRegistrationKPI(): boolean {
    return this.authService.canViewProductRegistrationKPI;
  }

  get canViewChargeType(): boolean {
    return this.authService.canViewChargeType;
  }

  get canViewCities(): boolean {
    return this.authService.canViewCities;
  }

  get canViewCurrency(): boolean {
    return this.authService.canViewCurrency;
  }

  get canViewDocumentType(): boolean {
    return this.authService.canViewDocumentType;
  }

  get canViewProductCategories(): boolean {
    return this.authService.canViewProductCategories;
  }

  get canViewDistricts(): boolean {
    return this.authService.canViewDistricts;
  }

  get canViewUsers(): boolean {
    return this.authService.canViewUsers;
  }

  get canViewCarriers(): boolean {
    return this.authService.canViewCarriers;
  }

  get canViewFreePLCustomers(): boolean {
    return this.authService.canViewFreePLCustomers;
  }

  get canViewTenant(): boolean {
    return this.authService.canViewTenant;
  }

  get canViewPlan(): boolean {
    return this.authService.canViewPlan;
  }
}
