import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/company/models/employee.model';
import { CompanyService } from 'src/app/company/services/company.service';
import { EmployeeStatus } from 'src/app/shared/models/enums/employee-status.model';
import { EmployeeType } from 'src/app/shared/models/enums/employee-type';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {
  columns: any;
  employees: Employee[];
  rows: Employee[];
  companyId: number;

  employeeStatus = EmployeeStatus;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('employeeNameTemplate', { static: true })
  employeeNameTemplate: TemplateRef<any>;

  @ViewChild('lockAndUnlockTemplate', { static: true })
  lockAndUnlockTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private alertService: AlertService,
    private companyService: CompanyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.employees = data['employees'];
      this.rows = this.employees;
    });

    this.initDataTable();

    this.route.parent?.params?.subscribe(data => {
      this.companyId = data?.companyId;
    });  
  }

  initDataTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: '',
        name: gT('employee.Name'),
        cellTemplate: this.employeeNameTemplate,
        width: 200,
        canAutoResize: false,
        sortable: true,
        comparator: (propA, propB) => {
          const nameA = propA.firstName + ' ' + propA.lastName;
          const nameB = propB.firstName + ' ' + propB.lastName;
          return nameA.localeCompare(nameB);
        }
      },
      {
        prop: 'jobTitle',
        name: gT('employee.JobTitle'),
        width: 230,
        canAutoResize: false
      },
      {
        prop: 'type',
        name: gT('employee.Role'),
        width: 230,
        canAutoResize: false
      },

      {
        prop: 'status',
        name: gT('employee.Status'),
        cellTemplate: this.lockAndUnlockTemplate,
        width: 100,
        canAutoResize: false
      },
      {
        name: this.canUpdateEmployees ? gT('employee.Action') : '',
        width: 167,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  onclickChangeEmployeeStatus(
    event: any,
    employeeId: number,
    isActive: boolean
  ) {
    event.stopPropagation();
    var message = isActive
      ? 'Are you sure to activate the account'
      : 'Are you sure to deactivate the account';
    this.alertService.confirm(message, () => {
      this.changeEmployeeStatus(employeeId, isActive);
    });
  }

  changeEmployeeStatus(employeeId: number, isActive: boolean) {
    this.companyService
      .changeEmployeeStatus(employeeId, isActive)
      .subscribe(() => {
        this.changeEmployeeStatusInList(employeeId, isActive);
      });
  }

  changeEmployeeStatusInList(employeeId: number, isActive: boolean) {
    var employee = this.employees.find(e => e.id == employeeId);
    employee.status = isActive
      ? EmployeeStatus.Active
      : EmployeeStatus.Inactive;
    this.rows = [...this.employees];
  }

  onSearchChanged(value: string) {
    this.rows = this.employees.filter(r =>
      Utilities.searchArray(
        value,
        false,
        r.firstName + ' ' + r.lastName,
        r.email,
        r.phoneNumber
      )
    );
  }

  get canUpdateEmployees(): boolean {
    return this.authService.canUpdateEmployees;
  }

  get canCreateEmployees(): boolean {
    return this.authService.canCreateEmployees;
  }

  get isAdministrator(): boolean {
    return this.authService.isAdmin;
  }

  isAdministratorRole(roleName: string): boolean {
    return roleName == EmployeeType.Admin;
  }
  get isCustomerRoute(): boolean {
    return this.router.url.includes('customer');
  }

  onClick(event) {
    if (event.type == 'click') {
      if (this.isCustomerRoute) {
        this.router.navigate([
          `customer/${this.companyId}/employees/${event?.row?.id}/details`
        ]);
      } else {
        this.router.navigate([
          `company-profile/${this.companyId}/employee/${event?.row?.id}/details`
        ]);
      }
    }
  }
}
