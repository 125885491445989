import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MatDatePickerHeaderComponent } from '../../../mat-date-picker-header/mat-date-picker-header.component';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true // Allows multiple providers for NG_VALUE_ACCESSOR
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  matDateHeader = MatDatePickerHeaderComponent;
  isDatePickerOpened: boolean = false;
  value: any = '';
  onChange: any = () => {};
  onTouched: any = () => {};

  /** Inputs for customization */
  @Input() formControl: FormControl; // Default FormControl
  @Input() placeholder: string = 'Select a date'; // Default placeholder
  @Input() appearance: string = 'fill'; // Default appearance type
  @Input() min: Date | null = null; // Default min date (no restriction)
  @Input() max: Date | null = null; // Default max date (no restriction)
  @Input() readonly: boolean = false; // Default: input is editable
  @Input() error: boolean = false;
  @Input() name: string = ''; // Default name attribute
  @Input() class: any = '';
  @Input() panelClass: any = '';

  /** Outputs for events */
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() input: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  @Output() change: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
  constructor() {}

  ngOnInit(): void {}

  writeValue(value: any): void {
    if (value) {
      this.value = value.toString();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onOpenDatePicker(): void {
    this.isDatePickerOpened = true;
    const matCalendar = document.getElementsByClassName('mat-calendar')[0];
    (matCalendar as HTMLElement).style.height = '40px';
    const button = document.createElement('div');
    button.className = 'footerToday';
    const span = document.createElement('span');
    button.appendChild(span);
    const text = document.createTextNode('Today');
    button.appendChild(text);

    matCalendar.appendChild(button);
  }

  onFocus(event: FocusEvent): void {
    this.focus.emit(event);
  }

  onClosed(): void {
    this.closed.emit();
  }

  onBlur(event: FocusEvent): void {
    this.blur.emit(event);
  }
}
