<div class="form-group">
  <input
    (click)="cp.click()"
    class="form-control"
    placeholder="Select color"
    readonly
    [value]="color"
    [ngClass]="{ hide: !color }"
  />
  <input
    #cp
    class="color"
    type="color"
    [(ngModel)]="color"
    [ngClass]="{ hide: !color }"
    [formControl]="formControl"
  />
  <svg-icon
    name="color-picker"
    class="picker-icon cursor-pointer"
    (click)="cp.click()"
  ></svg-icon>
</div>
