<div class="progress-activity-section">
  <div class="column-title column">
    <span>Product Registration Log</span>
    <span>Date</span>
  </div>
  <div class="activity-log column" *ngFor="let activityLog of activityLogs">
    <div class="activity">
      <div class="more-space">
        {{ activityLog.registrationActivity.name }}
      </div>
    </div>
    <div class="date">
      <span
        >{{ activityLog.createdAt | fromutc | date: "dd/MM/yyyy" }} -
        <span *ngIf="activityLog?.userFullName; else noUser">
          {{ activityLog.userFullName }}, {{ activityLog.userCompanyName }}
        </span>
        <ng-template #noUser>
          <span>Unknown</span>
        </ng-template>
      </span>
    </div>
  </div>
</div>
