import { RouterModule, Routes } from '@angular/router';
import { ProductRegistrationComponent } from './product-registration.component';
import { NgModule } from '@angular/core';
import { ProductRegistrationListComponent } from './pages/product-registration-list/product-registration-list.component';
import { UnauthorizedCompanyAccessGuard } from '../shared/guards/unauthorized-company-access.guard';
import { ProductRegistrationDetailsComponent } from './pages/product-registration-details/product-registration-details.component';
import { ProductRegistrationDetailsResolver } from './resolvers/product-registration-details.resolver';
import { ProductListResolver } from './resolvers/product-registration-list.resolver';
import { ProductRegistrationCommentsResolver } from './resolvers/product-comments.resolver';
import { ProductRegistrationPreferredSelectedColumnsResolver } from './resolvers/product-registration-preferred-selected-columns.resolver';
import { ProductRegistrationColumnsResolver } from './resolvers/product-registration-columns.resolver';
import { AccessProductRegistrationListGuard } from './routing-guards/access-product-registration-list.guard';

const routes: Routes = [
  {
    path: '',
    component: ProductRegistrationComponent,
    canActivate: [
      UnauthorizedCompanyAccessGuard,
      AccessProductRegistrationListGuard
    ],
    children: [
      {
        path: '',
        component: ProductRegistrationListComponent,
        resolve: {
          paginatedProducts: ProductListResolver,
          employeeSelectedColumns: ProductRegistrationPreferredSelectedColumnsResolver,
          registrationColumns: ProductRegistrationColumnsResolver
        }
      },
      {
        path: ':productId/details',
        component: ProductRegistrationDetailsComponent,
        resolve: {
          details: ProductRegistrationDetailsResolver,
          comments: ProductRegistrationCommentsResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule {}
