<mat-form-field
  [appearance]="appearance"
  [class]="'custom-datepicker' + ' ' + class"
  [ngClass]="{
    error:
      (formControl?.pristine &&
        formControl?.touched &&
        formControl?.hasError('required')) ||
      error,
    'active-border': isDatePickerOpened && !formControl?.touched
  }"
>
  <input
    matInput
    [matDatepicker]="datepicker"
    [formControl]="formControl"
    [placeholder]="placeholder"
    (focus)="datepicker.open(); onFocus($event)"
    (blur)="onBlur($event)"
    [name]="name"
    [min]="min"
    [max]="max"
    [readonly]="readonly"
  />
  <mat-datepicker-toggle matSuffix [for]="datepicker">
    <svg-icon matDatepickerToggleIcon name="datePickerIcon"></svg-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #datepicker
    [panelClass]="'custom-today' + ' ' + panelClass"
    [calendarHeaderComponent]="matDateHeader"
    (opened)="onOpenDatePicker()"
    (closed)="onClosed()"
  >
  </mat-datepicker>
</mat-form-field>
