import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from '../models/login-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  private baseUrl: string = environment.apiUrl + 'Authenticate';

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<LoginResponse> {
    const tenantUrl =
      window.location.hostname == 'localhost'
        ? 'dev.freepl.app'
        : window.location.hostname;

    const body = { username, password, tenantUrl };
    return this.http.post<LoginResponse>(`${this.baseUrl}/login`, body);
  }

  refreshLogin(refreshToken: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${this.baseUrl}refresh`,
      refreshToken
    );
  }
}
