export enum CompanyTabs {
  Details = 'Details',
  Contacts = 'Contacts',
  Documents = 'Documents',
  Services = 'Services',
  Employees = 'Employees',
  OperationalKpis = 'Operational KPIs',
  AssignedBPS = 'Assigned BPs',
  Network = 'Network',
  ContractualTerms = 'Contractual Terms'
}
