import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedServices } from 'src/app/admin-portal-configs/models/selected-services.model';
import { ActivityService } from 'src/app/admin-portal-configs/services/activity.service';
import { TransportTypeEnum } from 'src/app/shared/models/enums/transport-type.enum';
import { ActivityCategory } from 'src/app/shared/models/shipment/activity-category.model';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { Progress } from 'src/app/shared/models/shipment/progress.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { Status } from 'src/app/shared/models/shipment/status.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';

@Component({
  selector: 'app-add-edit-activity',
  templateUrl: './add-edit-activity.component.html',
  styleUrls: ['./add-edit-activity.component.scss']
})
export class AddEditActivityComponent implements OnInit {
  isSaving: boolean = false;
  systemStatuses: Status[];
  progressPoints: Progress[];
  parentCategories: ActivityCategory[];
  shipmentTypes: string[] = [
    TransportTypeEnum.Air,
    TransportTypeEnum.Ocean,
    ServiceTypes.DomesticTrucking
  ];
  form: UntypedFormGroup;
  activity: Activity;
  domesticTruckingService: SelectedServices;

  constructor(
    private router: Router,
    private translationService: AppTranslationService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private activityService: ActivityService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.parentCategories = data['parentCategories'];
      this.activity = data['activity'];
    });
    this.activity = this.activity || ({} as Activity);
    this.initForm();

    if (!this.isVoidActivity) {
      this.getNonVoidActivityLookups();
      this.setNonVoidActivityValidators();
    } else {
      this.setVoidActivityValidators();
    }
  }

  initForm(): void {
    const selectedShipmentTypes = [];
    if (this.isEditting && !this.isVoidActivity) {
      let isAir = this.activity.isForAir;
      let isOcean = this.activity.isForOcean;
      let isTrucking = this.activity.freePlServices?.some(
        c => c.name == ServiceTypes.DomesticTrucking
      );

      if (isAir) {
        selectedShipmentTypes.push(TransportTypeEnum.Air);
      }
      if (isOcean) {
        selectedShipmentTypes.push(TransportTypeEnum.Ocean);
      }
      if (isTrucking) {
        selectedShipmentTypes.push(ServiceTypes.DomesticTrucking);
      }
    }

    this.form = this.fb.group({
      name: [this.activity.name, [Validators.required]],
      statusId: [this.activity.statusId],
      progressId: [this.activity.progressId],
      categoryId: [this.activity.categoryId],
      shipmentTypes: [selectedShipmentTypes],
      isVoid: [this.isVoidActivity]
    });
  }

  setNonVoidActivityValidators(): void {
    this.form.get('statusId').setValidators([Validators.required]);
    this.form.get('progressId').setValidators([Validators.required]);
    this.form.get('shipmentTypes').setValidators([Validators.required]);
  }

  setVoidActivityValidators(): void {
    this.form.get('categoryId').setValidators([Validators.required]);
  }

  getNonVoidActivityLookups(): void {
    this.activityService.getNonVoidActivityLookups().subscribe(lookups => {
      this.systemStatuses = lookups.systemStatuses;
      this.progressPoints = lookups.progressPoints;
      this.domesticTruckingService = lookups.domesticTruckingService;
    });
  }

  save(): void {
    this.isSaving = true;
    this.form.markAllAsTouched();

    let activity = this.createActivityValue();

    if (this.isEditting) {
      activity.id = this.activity.id;
      this.updateActivity(activity);
    } else {
      this.addNewRequest(activity);
    }
  }

  addNewRequest(activity: Activity): void {
    this.activityService.addNew(activity).subscribe(() => {
      this.alertService.success('Activity has been created successfully');
      this.router.navigate([this.activitiesListRouterLink]);
    });
  }

  updateActivity(activity: Activity): void {
    this.activityService.updateActivity(activity.id, activity).subscribe(() => {
      this.alertService.success('Activity has been updated successfully');
      this.router.navigate([this.activitiesListRouterLink]);
    });
  }

  createActivityValue(): Activity {
    const shipmentTypes = this.form.value?.shipmentTypes;

    let activity: Activity = this.form.value;
    if (shipmentTypes?.length > 0) {
      if (shipmentTypes.includes(TransportTypeEnum.Air)) {
        activity.isForAir = true;
      }
      if (shipmentTypes.includes(TransportTypeEnum.Ocean)) {
        activity.isForOcean = true;
      }

      if (shipmentTypes.includes(ServiceTypes.DomesticTrucking)) {
        activity.freePlServices = [];
        activity.freePlServices.push(this.domesticTruckingService);
      }
    }

    return activity;
  }

  isShipmentTypeSelected(type: string): boolean {
    return this.form.get('shipmentTypes')?.value?.includes(type);
  }

  onShipmentTypeChange(item: string, event: any): void {
    const selectedItems = this.form.get('shipmentTypes').value;
    if (event.checked) {
      selectedItems.push(item);
    } else {
      const index = selectedItems.indexOf(item);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }
    this.form.get('shipmentTypes').setValue(selectedItems);
  }

  get isVoidActivity(): boolean {
    return this.activatedRoute.snapshot.data.isVoidActivity || false;
  }

  get pageTitle(): string {
    if (this.isVoidActivity) {
      return this.isEditting ? 'Edit Activity Details' : 'Add Activity Details';
    }

    return this.isEditting ? 'Edit Status Details' : 'Add Status Details';
  }

  get isEditting(): boolean {
    return this.router.url.includes('edit');
  }

  get activitiesListRouterLink(): string {
    return this.isVoidActivity ? '/shipment-activities' : '/shipment-statuses';
  }

  get nameField(): string {
    return this.isVoidActivity ? 'Activity Name' : 'Status Name';
  }
}
