import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentFreightType } from 'src/app/shared/models/enums/shipment-freight-type.enum';
import { ShipmentStatusEnum } from 'src/app/shared/models/enums/shipment-status';
import { TruckingLoadType } from 'src/app/shared/models/enums/trucking-load-type.enum';
import { SearchCriteria } from 'src/app/shared/models/shipment/search-criteria.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { ShipmentBase } from 'src/app/shared/models/shipment/shipmentBase.model';
import { OceanFreightOptionsEnum } from 'src/app/shared/models/enums/ocean-freight.enum';
import { ShipmentInvoice } from '../models/shipment-invoice.model';
import { ShipmentsService } from '../shipments.service';
import { DocumentTypeEnum } from '../../shared/models/enums/document-type.enum';
import { ShipmentListViews } from '../constants/shipments-list-view';
import { ShipmentsListViews } from '../models/shipments-list-views.model';
import { AuthService } from '../../shared/services/auth.service';
import {
  airFreightExpressFactor,
  airFreightFactor,
  convertCCMToCBM,
  oceanLCLBBFactor,
  truckingLTLFactor
} from '../../shared/constants/factors';
import { ShipmentServices } from '../../shared/models/shipment/shipmentServices.model';
import { Deprecated_ShipmentCustomsClearanceRate } from '../../shared/models/shipment/deprecated_shipment-customs-clearance-rate.model';
import { ShipmentCustomsClearanceGroupedRates } from '../../shared/models/shipment/shipment-customs-clearance-grouped-rates.model';
import { TransactionType } from '../../shared/models/enums/transaction-type.enum';
import { LocalStoreManager } from '../../shared/services/local-store-manager.service';
import { ContainerTypeEnum } from '../../shared/models/enums/container-type.enum';
import {
  ShippingAddress,
  TruckingAddress
} from 'src/app/shared/models/shipment/shipmentRouting.model';
import { DomesticTruckingInformation } from 'src/app/shared/models/domestic-trucking-information.model';
import { CityName } from 'src/app/shipments/enums/city-name.enum';
import { DistrictWithCityAndCountry } from 'src/app/dashboard/models/district-with-city-and-country.model';
import { FreePLUser } from 'src/app/shared/models/freepl-user.model';
import { ViewColumn } from 'src/app/shared/models/view-column.model';
import { ActivityLog } from 'src/app/shared/models/shipment/activity-log.model';
import { Progress } from 'src/app/shared/models/shipment/progress.model';
import { Form4Request } from 'src/app/shared/models/shipment/form4-request.model';
import { ProgressDate } from 'src/app/shared/models/shipment/progress-date.model';
import { ProgressPointEnum } from 'src/app/shared/models/enums/progress-point.enum';
import { ActivityNames } from '../enums/activity-names.enum';
import { IntegrationResponse } from '../models/integration-response.model';
import { ShipmentDetails } from '../models/shipment-details.model';
import { ShipmentActivityLog } from '../models/shipment-activity-log.model';
import { LocationsEntity } from 'src/app/createShipment/models/shipmentRoutingDataModel';

@Injectable({
  providedIn: 'root'
})
export class ShipmentHelpersService {
  airFreightFactor: number = airFreightFactor;
  oceanLCLBBFactor: number = oceanLCLBBFactor;
  truckingLTLFactor: number = truckingLTLFactor;
  convertCCMToCBM: number = convertCCMToCBM;
  airFreightExpressFactor: number = airFreightExpressFactor;

  totalUserDraftShipmentsNumber: number = null;

  FORM4_ELIGIBLE_MIN_LIMIT_IN_DOLLARS = 2000;

  shipmentsListViews = ShipmentListViews;

  constructor(
    private router: Router,
    private shipmentsService: ShipmentsService,
    private authService: AuthService,
    private localStorage: LocalStoreManager
  ) {}

  getVolume(
    length: number,
    width: number,
    height: number,
    quantity: number
  ): number {
    let volume = (length * width * height) / this.convertCCMToCBM;
    return volume * quantity;
  }

  getTotalVolumetricWeight(
    totalVolume: number,
    freightOrCustomsClearanceSelected: boolean,
    freightReference?: string,
    trucking?: boolean,
    isExpress?: boolean
  ): number {
    let volume = 0.0;

    volume = totalVolume * this.convertCCMToCBM;

    if (
      freightReference === ShipmentFreightType.Air &&
      freightOrCustomsClearanceSelected === true
    ) {
      let divisionFactor = isExpress
        ? this.airFreightExpressFactor
        : this.airFreightFactor;
      return volume / divisionFactor;
    }

    if (
      freightReference === ShipmentFreightType.Ocean &&
      freightOrCustomsClearanceSelected === true
    ) {
      return volume / this.oceanLCLBBFactor;
    }

    if (trucking === true) {
      return volume / this.truckingLTLFactor;
    }

    return null;
  }

  freightReference(shipment: ShipmentBase): string {
    return shipment?.routing?.freightReference;
  }

  freightSelected(shipment: ShipmentBase): boolean {
    var importExportStandaloneService = this.importExportServiceStandalone(
      shipment
    );
    return (
      shipment?.shipmentServices?.freight != null ||
      (importExportStandaloneService === true &&
        shipment?.routing?.freightReference != '')
    );
  }

  getShipmentServicesNames(shipment: ShipmentBase) {
    let servicesNames: string[] = [];
    const shipmentServices = shipment.shipmentServices;
    if (shipmentServices) {
      if (shipment.shipmentServices.importExportRate) {
        servicesNames.push(shipmentServices?.importExportRate?.type);
      }
      if (shipmentServices.freight) {
        servicesNames.push(ServiceTypes.Freight);
      }

      let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

      if (customsClearance) {
        servicesNames.push(ServiceTypes.CustomsClearance);
      }
      if (shipmentServices.trucking) {
        servicesNames.push(ServiceTypes.Trucking);
      }
    }
    return servicesNames;
  }

  importExportServiceStandalone(shipment: ShipmentBase): boolean {
    let customsClearance = this.getSelectedCustomsClearance(
      shipment?.shipmentServices
    );

    return (
      shipment?.shipmentServices?.freight === null &&
      customsClearance === null &&
      shipment?.shipmentServices?.trucking === null
    );
  }

  getImportExportServiceName(shipment: ShipmentBase): string {
    return shipment?.shipmentServices?.accountManagement?.type;
  }

  getHasLTLTrucking(shipment: ShipmentBase): boolean {
    var originTrucking = this.hasOriginTrucking(shipment);
    var destinationTrucking = this.hasDestinationTrucking(shipment);
    var showTruckingEmptySummary = this.showTruckingEmptySummary(shipment);

    return (
      (originTrucking || destinationTrucking || showTruckingEmptySummary) &&
      (shipment?.cargoDetails?.loadType === TruckingLoadType.LTL ||
        shipment?.cargoDetails?.loadType === OceanFreightOptionsEnum.LCL)
    );
  }

  showTruckingEmptySummary(shipment: ShipmentBase): boolean {
    return (
      shipment?.shipmentServices?.trucking != null ||
      shipment?.routing?.isTruckingPickup === true ||
      shipment?.routing?.isTruckingDelivery === true
    );
  }

  hasOriginTrucking(shipment: ShipmentBase): boolean {
    return (
      shipment?.cargoDetails?.originTruckingInformation != null &&
      shipment?.cargoDetails?.originTruckingInformation?.length > 0
    );
  }

  hasDestinationTrucking(shipment: ShipmentBase): boolean {
    return (
      shipment?.cargoDetails?.destinationTruckingInformation != null &&
      shipment?.cargoDetails?.destinationTruckingInformation?.length > 0
    );
  }

  calculateChargeableWeight(shipment: ShipmentBase): number {
    let totalShipmentVolume = this.calculateTotalShipmentVolume(shipment);
    let freightSelected = this.freightSelected(shipment);
    let isSelectedCustomsClearance = this.isSelectedCCService(shipment);
    let freightReference = this.freightReference(shipment);
    let hasTrucking = this.getHasLTLTrucking(shipment);
    let totalShipmentWeight = this.calculateTotalShipmentWeight(shipment);
    let isExpress = this.isExpressShipment(shipment);
    let isLCLLoadType = this.isLCLLoadType(shipment);
    let isOcean = this.isOcean(shipment);

    let totalVolumetricWeight = this.getTotalVolumetricWeight(
      totalShipmentVolume,
      freightSelected || isSelectedCustomsClearance,
      freightReference,
      hasTrucking,
      isExpress
    );

    if (isOcean && isLCLLoadType) {
      return totalVolumetricWeight;
    }

    let chargeableWeight = Math.max(totalShipmentWeight, totalVolumetricWeight);

    return chargeableWeight;
  }

  calculatePackageTotalsChargeableWeight(shipment: ShipmentBase): number {
    let totalShipmentVolume =
      shipment?.cargoDetails?.packagesTotals?.totalvolume;
    let freightSelected = this.freightSelected(shipment);
    let isSelectedCustomsClearance = this.isSelectedCCService(shipment);
    let freightReference = this.freightReference(shipment);
    let hasTrucking = this.getHasLTLTrucking(shipment);
    let totalShipmentWeight =
      shipment?.cargoDetails?.packagesTotals?.totalWeight;
    let isExpress = this.isExpressShipment(shipment);
    let isLCLLoadType = this.isLCLLoadType(shipment);
    let isOcean = this.isOcean(shipment);

    let totalVolumetricWeight = this.getTotalVolumetricWeight(
      totalShipmentVolume,
      freightSelected || isSelectedCustomsClearance,
      freightReference,
      hasTrucking,
      isExpress
    );

    if (isOcean && isLCLLoadType) {
      return totalVolumetricWeight;
    }
    let chargeableWeight = Math.max(totalShipmentWeight, totalVolumetricWeight);

    return chargeableWeight;
  }

  calculateTotalShipmentVolume(shipment: ShipmentBase): number {
    let totalVolume = 0.0;

    if (
      shipment?.cargoDetails?.hasPackage ||
      shipment?.cargoDetails?.hasBreakbulk
    ) {
      shipment?.cargoDetails?.packages?.forEach(shipmentPackage => {
        totalVolume += this.getVolume(
          shipmentPackage.length,
          shipmentPackage.width,
          shipmentPackage.height,
          shipmentPackage.quantity
        );
      });
    }

    if (shipment?.cargoDetails?.hasPallet) {
      shipment?.cargoDetails?.pallets?.forEach(shipmentPallet => {
        totalVolume += this.getVolume(
          shipmentPallet.length,
          shipmentPallet.width,
          shipmentPallet.height,
          shipmentPallet.quantity
        );
      });
    }

    return totalVolume;
  }

  calculateTotalShipmentWeight(shipment: ShipmentBase): number {
    let totalWeight = 0.0;

    if (
      shipment?.cargoDetails?.hasPackage ||
      shipment?.cargoDetails?.hasBreakbulk
    ) {
      shipment?.cargoDetails?.packages?.forEach(shipmentPackage => {
        totalWeight += shipmentPackage.quantity * shipmentPackage.weight;
      });
    }

    if (shipment?.cargoDetails?.hasPallet) {
      shipment?.cargoDetails?.pallets?.forEach(shipmentPallet => {
        totalWeight += shipmentPallet.quantity * shipmentPallet.weight;
      });
    }

    return totalWeight;
  }

  getShipmentNumber(shipment: ShipmentBase): string {
    return shipment?.freePlShipmentNumberString;
  }

  getShipmentReferenceNumber(shipment: ShipmentBase): string {
    return shipment?.routing?.customerReferenceNumber;
  }

  getShipmentCreationDate(shipment: ShipmentBase): Date {
    if (!shipment?.createdDatetime) {
      return null;
    } else {
      return shipment?.createdDatetime;
    }
  }

  getShipmentTransactionType(shipment: ShipmentBase): string {
    return shipment?.shipmentServices?.transactionType;
  }

  getShipmentProgress(shipment: ShipmentBase): string {
    return shipment?.shipmentProgress?.name;
  }

  getShipmentStatus(shipment: ShipmentBase): string {
    return shipment?.lastActivityLog?.activityName;
  }

  getShipmentStatusColor(shipment: ShipmentBase): string {
    return shipment?.shipmentStatus?.colorCode;
  }

  getShipmentOriginCityCountry(shipment: ShipmentBase): string {
    if (!shipment?.routing?.shippingAddress?.originCountry) {
      return null;
    }

    return (
      shipment?.routing?.shippingAddress?.originCity +
      ', ' +
      shipment?.routing?.shippingAddress?.originCountry
    );
  }

  getShipmentDestinationCityCountry(shipment: ShipmentBase): string {
    if (!shipment?.routing?.shippingAddress?.deliveryCountry) {
      return null;
    }

    return (
      shipment?.routing?.shippingAddress?.deliveryCity +
      ', ' +
      shipment?.routing?.shippingAddress?.deliveryCountry
    );
  }

  getShipmentCommercialInvoices(shipment: ShipmentBase): ShipmentInvoice[] {
    if (
      !shipment?.routing?.commercialInvoices ||
      shipment?.routing?.commercialInvoices.length === 0
    ) {
      return null;
    } else {
      return shipment?.routing?.commercialInvoices;
    }
  }

  getShipmentProformaInvoices(shipment: ShipmentBase): ShipmentInvoice[] {
    return shipment?.routing?.proformaInvoices;
  }

  getShipmentMasterAirWaybillOrBillOfLadingNumber(
    shipment: ShipmentBase
  ): string {
    if (this.isReferenceTypeAir(shipment)) {
      return shipment?.routing?.airWayBillNumber;
    } else {
      return shipment?.routing?.billOfladingNumber;
    }
  }

  getShipmentPackingListNumber(shipment: ShipmentBase): string {
    return shipment?.routing?.packingListNumber;
  }

  getShipmentForm4Number(shipment: ShipmentBase): string {
    return shipment?.routing?.formFourNumber;
  }

  getShipmentForm4IssuingBankNumber(shipment: ShipmentBase): string {
    return shipment?.routing?.formFourIssuingBank;
  }

  getShipmentPurchaseOrderNumber(shipment: ShipmentBase): string {
    return shipment?.routing?.purchaseOrderNumber;
  }

  getShipmentPurchaseOrderDate(shipment: ShipmentBase): Date {
    if (!shipment?.routing?.purchaseOrderDate) {
      return null;
    } else {
      return shipment?.routing?.purchaseOrderDate;
    }
  }

  getShipmentOriginPort(shipment: ShipmentBase): string {
    return shipment?.routing?.shippingAddress?.originPort;
  }

  getShipmentDestinationPort(shipment: ShipmentBase): string {
    return shipment?.routing?.shippingAddress?.deliveryPort;
  }

  getShipper(shipment: ShipmentBase): string {
    return shipment?.routing?.shipper?.name;
  }

  getConsignee(shipment: ShipmentBase): string {
    return shipment?.routing?.consignee?.name;
  }

  getSupplier(shipment: ShipmentBase): string {
    return shipment?.cargoDetails?.shipmentProduct?.supplierName;
  }

  getTotalShipmentValue(shipment: ShipmentBase): number {
    return shipment?.cargoDetails?.shipmentProduct?.totalShipmentValue;
  }

  getTotalShipmentValueCurrency(shipment: ShipmentBase): string {
    return shipment?.cargoDetails?.shipmentProduct?.currencyCode;
  }

  getLatestShipmentEvent(shipment: ShipmentBase): string {
    if (
      shipment?.lastTwoVoidActivityLogs &&
      shipment?.lastTwoVoidActivityLogs.length > 0
    ) {
      return shipment.lastTwoVoidActivityLogs[0].activityName;
    }

    return null;
  }

  getShipmentPickUpDate(shipment: ShipmentBase): Date {
    let notSurePickUpDate = shipment?.routing?.isCustomerNotSurePickupDate;
    if (notSurePickUpDate || !shipment?.routing?.pickupDate) {
      return null;
    } else {
      return new Date(shipment?.routing?.pickupDate);
    }
  }

  getShipmentDeliveryDate(shipment: ShipmentBase): Date {
    let notSureDeliveryDate = shipment?.routing?.isCustomerNotSureDeliveryDate;
    if (notSureDeliveryDate || !shipment?.routing?.deliveryDate) {
      return null;
    } else {
      return new Date(shipment?.routing?.deliveryDate);
    }
  }

  isReferenceTypeAir(shipment: ShipmentBase): boolean {
    return (
      shipment?.routing?.freightReference === ShipmentFreightType.Air ||
      shipment?.routing?.shippingAddress?.isDeliveryAirPort === true ||
      shipment?.routing?.shippingAddress?.isOriginAirPort === true
    );
  }

  getFreightReferenceFromServices(shipment: ShipmentBase) {
    var freightReference = null;
    let customsClearance = this.getSelectedCustomsClearance(
      shipment?.shipmentServices
    );

    if (shipment?.shipmentServices?.freight) {
      freightReference = shipment?.shipmentServices?.freight?.freightReference;
    } else if (customsClearance) {
      freightReference = this.getSelectedCustomsClearanceFreightReference(
        shipment?.shipmentServices
      );
    } else if (shipment?.shipmentServices?.trucking) {
      let isTruckingAir =
        this.getIsTruckingAirPort(shipment, true) ||
        this.getIsTruckingAirPort(shipment, false);

      freightReference = isTruckingAir
        ? ShipmentFreightType.Air
        : ShipmentFreightType.Ocean;
    }

    return freightReference;
  }

  isUserDraft(shipment: ShipmentBase): boolean {
    return shipment?.shipmentStatus?.name === ShipmentStatusEnum.UserDraft;
  }

  isDraft(shipment: ShipmentBase): boolean {
    return shipment?.shipmentStatus?.name === ShipmentStatusEnum.Draft;
  }

  goToDrafts(companyId: string) {
    let searchCriteria: SearchCriteria = {
      status: [ShipmentStatusEnum.UserDraft]
    };

    this.shipmentsService.onSearchCriteriaChange.emit(searchCriteria);
    this.router.navigateByUrl('company/' + companyId + '/shipments');
  }

  getShipmentDocumentsTypesNames(shipment: ShipmentBase) {
    return shipment?.documents?.map(a => a.documentTypeName);
  }

  isUseFreePLEntityAsShipper(shipment: ShipmentBase) {
    return shipment?.routing?.useFreePLEntityAsShipper;
  }

  isFTLTruckingService(shipment: ShipmentBase): boolean {
    if (isDevMode()) {
      return (
        shipment?.shipmentServices?.truckingService?.loadType?.name ===
        TruckingLoadType.FTL
      );
    }

    return (
      shipment?.shipmentServices?.trucking?.truckingType ===
      TruckingLoadType.FTL
    );
  }

  isFCLLoadType(shipment: ShipmentBase): boolean {
    return shipment?.cargoDetails?.loadType === OceanFreightOptionsEnum.FCL;
  }

  isLCLLoadType(shipment: ShipmentBase): boolean {
    return shipment?.cargoDetails?.loadType === OceanFreightOptionsEnum.LCL;
  }

  isBreakbulkTruckingService(shipment: ShipmentBase): boolean {
    if (isDevMode()) {
      return (
        shipment?.shipmentServices?.truckingService?.loadType?.name ===
        TruckingLoadType.Breakbulk
      );
    }

    let isBreakbulkTruckingService =
      shipment?.shipmentServices?.trucking?.truckingType ===
      TruckingLoadType.Breakbulk;
    return isBreakbulkTruckingService;
  }

  isLTLTruckingService(shipment: ShipmentBase): boolean {
    if (isDevMode()) {
      return (
        shipment?.shipmentServices?.truckingService?.loadType?.name ===
        TruckingLoadType.LTL
      );
    }
    return (
      shipment?.shipmentServices?.trucking?.truckingType ===
      TruckingLoadType.LTL
    );
  }

  isTruckingServiceStandalone(shipment: ShipmentBase): boolean {
    let customsClearance = this.getSelectedCustomsClearance(
      shipment?.shipmentServices
    );

    return (
      shipment?.shipmentServices?.freight === null &&
      !customsClearance &&
      (isDevMode()
        ? shipment?.shipmentServices?.truckingService != null
        : shipment?.shipmentServices?.trucking != null)
    );
  }

  isForm4Initiated(shipment: ShipmentBase): boolean {
    let isForm4Initiated = shipment?.form4Request != null;
    return isForm4Initiated;
  }

  isForm4DocumentUploaded(shipment: ShipmentBase): boolean {
    let isForm4DocumentUploaded = shipment?.documents?.some(
      d =>
        d.documentTypeName === DocumentTypeEnum.FormFour && d.isDeleted != true
    );

    return isForm4DocumentUploaded;
  }

  getShipmentCurrencyCode(shipment: ShipmentBase): string {
    let shipmentCurrencyCode =
      shipment?.cargoDetails?.shipmentProduct?.currencyCode;
    return shipmentCurrencyCode;
  }

  getShipmentTotalValue(shipment: ShipmentBase): number {
    let shipmentTotalValue =
      shipment?.cargoDetails?.shipmentProduct?.totalShipmentValue;
    return shipmentTotalValue;
  }

  isShipmentValueAboveLimit(
    shipment: ShipmentBase,
    shipmentCurrencyExchangeRate: number
  ): boolean {
    let shipmentValue =
      this.getShipmentTotalValue(shipment) * shipmentCurrencyExchangeRate;

    return shipmentValue > this.FORM4_ELIGIBLE_MIN_LIMIT_IN_DOLLARS;
  }

  hasTruckingInBundle(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.bundle?.services?.some(
      x => x.name == ServiceTypes.Trucking
    );
  }

  hasIORInBundle(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.bundle?.services?.some(
      x => x.name == ServiceTypes.IOR
    );
  }

  hasEORInBundle(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.bundle?.services?.some(
      x => x.name == ServiceTypes.EOR
    );
  }

  hasCustomsClearanceInBundle(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.bundle?.services?.some(
      x => x.name == ServiceTypes.CustomsClearance
    );
  }

  isSelectedTruckingService(shipment: ShipmentBase) {
    return isDevMode()
      ? shipment?.shipmentServices?.truckingService != null
      : shipment?.shipmentServices?.trucking != null;
  }

  isSelectedCCService(shipment: ShipmentBase) {
    let customsClearance = this.getSelectedCustomsClearance(
      shipment?.shipmentServices
    );

    return customsClearance != null;
  }

  isServiceBundleValid(shipment: ShipmentBase) {
    let bundle = shipment?.shipmentServices?.bundle;
    return bundle ? true : false;
  }

  isServiceIncludedInBundle(
    shipment: ShipmentBase,
    serviceName: string
  ): boolean {
    let isServiceBundleValid = this.isServiceBundleValid(shipment);
    if (isServiceBundleValid) {
      return shipment?.shipmentServices?.bundle?.services?.some(
        s => s.name == serviceName
      );
    }
    return false;
  }

  getCurrentShipmentsListView(currentView: string): ShipmentsListViews {
    if (!currentView) {
      let shipmentsListView = this.shipmentsListViews?.find(
        v => v.view === null
      );

      return shipmentsListView;
    }

    let shipmentsListView = this.shipmentsListViews?.find(
      v => v.view === currentView
    );

    let hasPermission = this.authService.hasPermission(
      shipmentsListView?.permission
    );

    return hasPermission || shipmentsListView?.permission == null
      ? shipmentsListView
      : null;
  }

  getProductClassification(shipment: ShipmentBase): string {
    return shipment.routing?.productClassificationName;
  }

  getShipmentHouseAirWaybillOrBillOfLadingNumber(
    shipment: ShipmentBase
  ): string {
    if (this.isReferenceTypeAir(shipment)) {
      return shipment?.routing?.houseAirWayBillNumber;
    } else {
      return shipment?.routing?.houseBillNumber;
    }
  }

  getRoadBillNumber(shipment: ShipmentBase): string {
    return shipment.routing?.roadBillNumber;
  }

  getACIDNumber(shipment: ShipmentBase): string {
    return shipment.routing?.acidNumber;
  }

  getCustomsBroker(shipment: ShipmentBase): string {
    return shipment.routing?.customsBroker;
  }

  getCustomsCertificateNumber(shipment: ShipmentBase): string {
    return shipment.routing?.customsCertificateNumber;
  }

  getEDASubmissionNumber(shipment: ShipmentBase): string {
    return shipment.routing?.edaSubmissionNumber;
  }

  getEDASubmissionDate(shipment: ShipmentBase): Date {
    return shipment.routing?.edaSubmissionDate;
  }

  getEDAImportationApprovalNumber(shipment: ShipmentBase): string {
    return shipment.routing?.edaImportationApprovalNumber;
  }

  getEDAImportationApprovalDate(shipment: ShipmentBase): Date {
    return shipment.routing?.edaImportationApprovalDate;
  }

  getRUINumber(shipment: ShipmentBase): string {
    return shipment.routing?.ruiNumber;
  }

  getRUIDate(shipment: ShipmentBase): Date {
    return shipment.routing?.ruiDate;
  }

  getBusinessUnit(shipment: ShipmentBase): string {
    return shipment.routing?.organisationalUnit;
  }

  getIncoterm(shipment: ShipmentBase): string {
    return shipment.routing?.incoterm;
  }

  getFreightForwarder(shipment: ShipmentBase): string {
    return shipment.routing?.freightForwarder;
  }

  getCarrier(shipment: ShipmentBase): string {
    return shipment.routing?.carrier;
  }

  getVessel(shipment: ShipmentBase): string {
    return shipment.routing?.vesselNumber;
  }

  getFlightNumber(shipment: ShipmentBase): string {
    return shipment.routing?.flightNumber;
  }

  getSelectedCustomsClearance(
    shipmentServices: ShipmentServices
  ):
    | ShipmentCustomsClearanceGroupedRates
    | Deprecated_ShipmentCustomsClearanceRate {
    let customsClearance =
      shipmentServices?.customsClearance?.groupedRates ||
      shipmentServices?.customsClearance?.rate;

    return customsClearance;
  }

  getSelectedCustomsClearanceFreightReference(
    shipmentServices: ShipmentServices
  ): ShipmentFreightType {
    let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

    return customsClearance?.freightReference;
  }

  getSelectedCustomsClearanceLoadType(
    shipmentServices: ShipmentServices
  ): string {
    let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

    return customsClearance?.loadTypeName;
  }

  getSelectedCustomsClearancePortId(
    shipmentServices: ShipmentServices
  ): number {
    let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

    return customsClearance?.portId;
  }

  getSelectedCustomsClearancePortName(
    shipmentServices: ShipmentServices
  ): string {
    let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

    return customsClearance?.portName;
  }

  getSelectedCustomsClearanceTransactionType(
    shipmentServices: ShipmentServices
  ): TransactionType {
    let customsClearance = this.getSelectedCustomsClearance(shipmentServices);

    return customsClearance?.transactionType;
  }

  getSelectedCustomsClearanceSingleRate(
    shipmentServices: ShipmentServices
  ): Deprecated_ShipmentCustomsClearanceRate {
    let customsClearance = shipmentServices?.customsClearance?.rate;

    return customsClearance;
  }

  getSelectedCustomsClearanceGroupedRates(
    shipmentServices: ShipmentServices
  ): ShipmentCustomsClearanceGroupedRates {
    let customsClearance = shipmentServices?.customsClearance?.groupedRates;

    return customsClearance;
  }

  getSelectedCustomsClearanceSingleRateContainerName(
    shipmentServices: ShipmentServices
  ): string {
    let containerTypeName =
      shipmentServices?.customsClearance?.rate?.containerTypeName;

    return containerTypeName;
  }

  isFreightReferenceOcean(shipmentBase: ShipmentBase): boolean {
    return this.freightReference(shipmentBase) == ShipmentFreightType.Ocean;
  }

  clearShipmentsStoredFiltersInBrowser() {
    this.localStorage.deleteData('filters');
    this.localStorage.deleteData('shipmentActorsViewFilterData');
  }

  clearEmployeeSelectedShipmentViewColumns() {
    this.localStorage.deleteData('selectedShipmentView');
  }

  clearEmployeeShipmentListView() {
    this.localStorage.deleteData('shipmentListView');
  }

  getEmployeeSelectedShipmentViewColumns(): ViewColumn[] {
    return this.localStorage?.getData('selectedShipmentView');
  }

  getShipmentPortId(shipment: ShipmentBase, isDelivery: boolean): number {
    let portId = shipment?.routing?.shippingAddress?.originPortId;

    if (isDelivery) {
      portId = shipment?.routing?.shippingAddress?.deliveryPortId;
    }
    return portId;
  }

  getShipmentSelectedContainerTypes(
    shipment: ShipmentBase
  ): ContainerTypeEnum[] {
    let containerTypes: ContainerTypeEnum[] = [];
    let hasContainers =
      shipment?.cargoDetails?.has20Container ||
      shipment?.cargoDetails?.has40Container ||
      shipment?.cargoDetails?.has40HCContainer;

    if (!hasContainers) {
      return null;
    }

    if (shipment?.cargoDetails?.has20Container) {
      containerTypes.push(ContainerTypeEnum.Container20);
    }

    if (shipment?.cargoDetails?.has40Container) {
      containerTypes.push(ContainerTypeEnum.Container40);
    }

    if (shipment?.cargoDetails?.has40HCContainer) {
      containerTypes.push(ContainerTypeEnum.Container40hc);
    }

    return containerTypes;
  }

  getShipmentSelectedLoadType(
    shipment: ShipmentBase
  ): OceanFreightOptionsEnum[] {
    let loadTypes: OceanFreightOptionsEnum[] = [];

    let loadType = shipment?.cargoDetails?.loadType;

    let hasTruckingLoadType = this.isTruckingServiceStandalone(shipment);

    if (hasTruckingLoadType) {
      loadTypes = this.getSupportedFreightLoadTypesFromTrucking(loadType);
    } else {
      loadTypes.push(OceanFreightOptionsEnum[loadType]);
    }

    return loadTypes;
  }

  getSupportedFreightLoadTypesFromTrucking(
    loadType: any
  ): OceanFreightOptionsEnum[] {
    let supportedLoadTypes: OceanFreightOptionsEnum[] = [];

    switch (loadType) {
      case TruckingLoadType.Breakbulk:
        supportedLoadTypes.push(OceanFreightOptionsEnum.Breakbulk);
        break;

      case TruckingLoadType.FTL:
        supportedLoadTypes.push(OceanFreightOptionsEnum.FCL);
        supportedLoadTypes.push(OceanFreightOptionsEnum.LCL);
        break;

      case TruckingLoadType.LTL:
        supportedLoadTypes.push(OceanFreightOptionsEnum.LCL);
        break;

      default:
        break;
    }

    return supportedLoadTypes;
  }

  hasDomesticService(shipment: ShipmentBase): boolean {
    if (isDevMode()) {
      return shipment?.shipmentServices?.domesticTruckingService ? true : false;
    }
    return shipment?.shipmentServices?.domesticTrucking ? true : false;
  }

  isDomesticTruckingCityCario(
    domesticTruckingAddress: TruckingAddress
  ): boolean {
    return domesticTruckingAddress?.cityName === CityName.Cairo;
  }

  getDomesticTruckingInformation(
    shipment: ShipmentBase
  ): DomesticTruckingInformation {
    return shipment?.cargoDetails?.domesticTruckingInformation;
  }

  getDomesticTruckingAddressPickup(shipment: ShipmentBase): TruckingAddress {
    return shipment?.routing?.domesticTruckingAddressPickup;
  }

  getDomesticTruckingAddressDelivery(shipment: ShipmentBase): TruckingAddress {
    return shipment?.routing?.domesticTruckingAddressDelivery;
  }

  getFreightTypeAir(shipment: ShipmentBase): boolean {
    return (
      shipment?.routing?.freightReference === ShipmentFreightType.Air ||
      shipment?.routing?.shippingAddress?.isDeliveryAirPort === true ||
      shipment?.routing?.shippingAddress?.isOriginAirPort === true
    );
  }

  hasQuoteId(shipment: ShipmentBase): boolean {
    return shipment?.quoteId ? true : false;
  }

  getSelectedTruckingRouteAddress(shipment: ShipmentBase): ShippingAddress {
    if (isDevMode) {
      let trucking = shipment?.shipmentServices?.truckingService;
      let address: ShippingAddress = {
        originCountry: trucking?.pickupFrom?.countryName,
        deliveryCountry: trucking?.deliverTo?.countryName,
        originCountryId: trucking?.pickupFrom?.countryId,
        deliveryCountryId: trucking?.deliverTo?.countryId,
        originCity: trucking?.pickupFrom?.cityName,
        deliveryCity: trucking?.deliverTo?.cityName,
        originCityId: trucking?.pickupFrom?.cityId,
        deliveryCityId: trucking?.deliverTo?.cityId,
        originPort: trucking?.pickupFrom?.portName,
        originPortCode: trucking?.pickupFrom?.portCode,
        deliveryPort: trucking?.deliverTo?.portName,
        deliveryPortCode: trucking?.deliverTo?.portCode,
        originPortId: trucking?.pickupFrom?.portId,
        deliveryPortId: trucking?.deliverTo?.portId,
        originText: '',
        deliveryText: '',
        originDomesticDistrict: trucking?.pickupFrom?.districtName,
        originDomesticCity: trucking?.pickupFrom?.cityName,
        deliveryDomesticDistrict: trucking?.deliverTo?.districtName,
        deliveryDomesticCity: trucking?.deliverTo?.cityName,
        isOriginAirPort:
          trucking?.pickupFrom?.freightReference === ShipmentFreightType.Air,
        isDeliveryAirPort:
          trucking?.deliverTo?.freightReference === ShipmentFreightType.Air
      };
      return address;
    }

    return shipment?.shipmentServices?.trucking?.shippingAddress;
  }

  getDistrict(
    shipment: ShipmentBase,
    isOrigin: boolean
  ): DistrictWithCityAndCountry {
    return {
      districtName: isOrigin
        ? shipment?.shipmentServices?.domesticTrucking?.originDistrict
        : shipment?.shipmentServices?.domesticTrucking?.destinationDistrict,
      cityName: isOrigin
        ? shipment?.shipmentServices?.domesticTrucking?.shippingAddress
            ?.originCity
        : shipment?.shipmentServices?.domesticTrucking?.shippingAddress
            ?.deliveryCity,
      countryName: isOrigin
        ? shipment?.shipmentServices?.domesticTrucking?.shippingAddress
            ?.originCountry
        : shipment?.shipmentServices?.domesticTrucking?.shippingAddress
            ?.deliveryCountry
    } as DistrictWithCityAndCountry;
  }

  getRegulatoryAssociate(shipment: ShipmentBase): FreePLUser {
    return shipment?.regulatoryAssociate;
  }

  getShippingAddress(shipment: ShipmentBase): ShippingAddress {
    return shipment?.routing?.shippingAddress;
  }

  getLastActivityLog(shipment: ShipmentBase): ActivityLog {
    return shipment?.lastActivityLog;
  }

  getlastTwoVoidActivityLogs(shipment: ShipmentBase): ActivityLog[] {
    return shipment?.lastTwoVoidActivityLogs;
  }

  getProgress(shipment: ShipmentBase): Progress {
    return shipment?.shipmentProgress;
  }

  getForm4Request(shipment: ShipmentBase): Form4Request {
    return shipment?.form4Request;
  }

  getMarker(shipment: ShipmentBase): string {
    return shipment?.marker;
  }

  hasPendingActions(shipment: ShipmentBase): boolean {
    return shipment?.hasPendingActions;
  }

  getUserDraftLabel(shipment: ShipmentBase): string {
    let isUserDraft = this.isUserDraft(shipment);
    if (isUserDraft) {
      return shipment?.draftLabel ?? shipment?.lastActivityLog?.reason;
    }
  }

  isAir(shipment: ShipmentBase): boolean {
    let freightReference = this.freightReference(shipment);
    return freightReference == ShipmentFreightType.Air;
  }

  isOcean(shipment: ShipmentBase): boolean {
    let freightReference = this.freightReference(shipment);
    return freightReference == ShipmentFreightType.Ocean;
  }

  getProgressDate(
    progressDates: ProgressDate[],
    progressPoint: ProgressPointEnum
  ): Date {
    var progressDate = progressDates?.find(
      d => d?.shipmentProgressName === progressPoint
    );
    if (progressDate != undefined) {
      return progressDate.actual ? progressDate.actual : progressDate.expected;
    }
    return null;
  }

  hasAirWayBillNumber(shipment: ShipmentBase): boolean {
    return !!shipment?.routing?.airWayBillNumber;
  }

  hasBillOfLading(shipment: ShipmentBase): boolean {
    return !!shipment?.routing?.billOfladingNumber;
  }

  setLastSelectedShipmentsList(view: string) {
    this.localStorage.saveSyncedSessionData(view, 'lastSelectedShipmentsList');
  }

  getLastSelectedShipmentsList(): string {
    return this.localStorage.getData('lastSelectedShipmentsList');
  }

  clearLastSelectedShipmentsList() {
    return this.localStorage.deleteData('lastSelectedShipmentsList');
  }

  isExpressShipment(shipment: ShipmentBase): boolean {
    return shipment?.routing?.isExpress;
  }

  hasSeaRatesTracking(shipment: ShipmentBase): boolean {
    return shipment?.hasSeaRatesTracking;
  }

  getSeaRatesIntegrationResponse(shipment: ShipmentBase): IntegrationResponse {
    return shipment?.seaRatesResponse;
  }

  isShipmentInitiated(shipmentActivityLogs: ShipmentActivityLog[]): boolean {
    const activityLogs = this.getShipmentActivityLogsCombined(
      shipmentActivityLogs
    );
    return activityLogs?.some(
      e => e.activityName === ActivityNames.ShipmentInitiated
    );
  }

  getCustomerName(shipment: ShipmentBase): string {
    return shipment?.companyName;
  }

  getShipmentActivityLogs(
    shipmentDetails: ShipmentDetails
  ): ShipmentActivityLog[] {
    return shipmentDetails?.shipmentActivityLogs;
  }

  getShipmentActivityLogsCombined(
    shipmentActivityLogs: ShipmentActivityLog[]
  ): ActivityLog[] {
    const activityLogs: ActivityLog[] = [];
    shipmentActivityLogs.forEach(a => {
      activityLogs.push(...a.activityLogs);
    });
    return activityLogs;
  }

  getOceanContainersNumbers(shipment: ShipmentBase): string[] {
    return shipment?.oceanContainersNumbers;
  }

  getExpressShipment(shipment: ShipmentBase): string {
    const isExpress = this.isExpressShipment(shipment);
    return isExpress ? 'Yes' : 'No';
  }

  getCompanyHasIorService(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.hasCompanyIorService;
  }

  getCompanyHasEorService(shipment: ShipmentBase): boolean {
    return shipment?.shipmentServices?.hasCompanyEorService;
  }

  getShipmentLastActivityLog(
    shipmentActivityLogs: ShipmentActivityLog[]
  ): ActivityLog {
    let activityLogs = this.getShipmentActivityLogsCombined(
      shipmentActivityLogs
    );
    activityLogs = activityLogs.filter(c => c.actualDate != null);
    activityLogs = activityLogs.sort((a, b) => a.id - b.id);
    return activityLogs?.[activityLogs.length - 1];
  }

  hasTruckingService(shipment: ShipmentBase): boolean {
    return isDevMode()
      ? shipment?.shipmentServices?.truckingService != null
      : shipment?.shipmentServices?.trucking != null;
  }

  getNewTruckingFreightReference(shipment: ShipmentBase): string {
    let freightReference =
      shipment?.shipmentServices?.truckingService?.routeDirection ===
      ServiceTypes.PortToDoor
        ? shipment?.shipmentServices?.truckingService?.pickupFrom
            ?.freightReference
        : shipment?.shipmentServices?.truckingService?.deliverTo
            ?.freightReference;

    return freightReference;
  }

  getTruckingPortId(shipment: ShipmentBase, isOrigin: boolean): number {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom?.portId
        : shipment?.shipmentServices.trucking.shippingAddress.originPortId;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo?.portId
      : shipment?.shipmentServices.trucking.shippingAddress.deliveryPortId;
  }

  getTruckingPortName(shipment: ShipmentBase, isOrigin: boolean): string {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom?.portName
        : shipment?.shipmentServices.trucking.shippingAddress.originPort;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo?.portName
      : shipment?.shipmentServices.trucking.shippingAddress.deliveryPort;
  }

  getTruckingPortCode(shipment: ShipmentBase, isOrigin: boolean): string {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom?.portCode
        : shipment?.shipmentServices.trucking.shippingAddress.originPortCode;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo?.portCode
      : shipment?.shipmentServices.trucking.shippingAddress.deliveryPortCode;
  }

  getTruckingCountryId(shipment: ShipmentBase, isOrigin: boolean): number {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom?.countryId
        : shipment?.shipmentServices.trucking.shippingAddress.originCountryId;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo?.countryId
      : shipment?.shipmentServices.trucking.shippingAddress.deliveryCountryId;
  }

  getTruckingDistrictName(shipment: ShipmentBase, isOrigin: boolean): string {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom?.districtName
        : shipment?.shipmentServices.trucking.originDistrict;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo?.districtName
      : shipment?.shipmentServices.trucking.destinationDistrict;
  }

  getNewTruckingDistricts(shipment: ShipmentBase): LocationsEntity[] {
    let domesticTrucking = shipment?.shipmentServices?.domesticTruckingService;
    let trucking = shipment?.shipmentServices?.truckingService;

    if (!domesticTrucking && !trucking) {
      return;
    }

    let originDistrict: LocationsEntity = {
      id:
        domesticTrucking?.pickupFrom?.districtId ||
        trucking?.pickupFrom?.districtId,
      district:
        domesticTrucking?.pickupFrom?.districtName ||
        trucking?.pickupFrom?.districtName,
      countryId:
        domesticTrucking?.pickupFrom?.countryId ||
        trucking?.pickupFrom?.countryId,
      countryName:
        domesticTrucking?.pickupFrom?.countryName ||
        trucking?.pickupFrom?.countryName,
      cityId:
        domesticTrucking?.pickupFrom?.cityId || trucking?.pickupFrom?.cityId,
      cityName:
        domesticTrucking?.pickupFrom?.cityName ||
        trucking?.pickupFrom?.cityName,
      portId:
        domesticTrucking?.pickupFrom?.portId || trucking?.pickupFrom?.portId,
      portNamme:
        domesticTrucking?.pickupFrom?.portName ||
        trucking?.pickupFrom?.portName,
      isForTrucking: true,
      isForFreight: false
    };

    let deliveryDistrict: LocationsEntity = {
      id:
        domesticTrucking?.deliverTo?.districtId ||
        trucking?.deliverTo?.districtId,
      district:
        domesticTrucking?.deliverTo?.districtName ||
        trucking?.deliverTo?.districtName,
      countryId:
        domesticTrucking?.deliverTo?.countryId ||
        trucking?.deliverTo?.countryId,
      countryName:
        domesticTrucking?.deliverTo?.countryName ||
        trucking?.deliverTo?.countryName,
      cityId:
        domesticTrucking?.deliverTo?.cityId || trucking?.deliverTo?.cityId,
      cityName:
        domesticTrucking?.deliverTo?.cityName || trucking?.deliverTo?.cityName,
      portId:
        domesticTrucking?.deliverTo?.portId || trucking?.deliverTo?.portId,
      portNamme:
        domesticTrucking?.deliverTo?.portName || trucking?.deliverTo?.portName,
      isForTrucking: true,
      isForFreight: false
    };

    let result: LocationsEntity[] = [];
    if (originDistrict?.district) {
      result.push(originDistrict);
    }

    if (deliveryDistrict?.district) {
      result.push(deliveryDistrict);
    }

    return result;
  }

  getIsTruckingAirPort(shipment: ShipmentBase, isOrigin: boolean): boolean {
    if (isOrigin) {
      return isDevMode()
        ? shipment?.shipmentServices?.truckingService?.pickupFrom
            ?.freightReference === ShipmentFreightType.Air
        : shipment?.shipmentServices.trucking.shippingAddress.isOriginAirPort;
    }

    return isDevMode()
      ? shipment?.shipmentServices?.truckingService?.deliverTo
          ?.freightReference === ShipmentFreightType.Air
      : shipment?.shipmentServices.trucking.shippingAddress.isDeliveryAirPort;
  }
}
