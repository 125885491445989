import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(): Observable<{ primaryColor: string; secondaryColor: string }> {
    const domain = '' + window.location.hostname; // Get the domain

    const apiUrl = `${environment.apiUrl}PublicTenant/Domain/${domain}`; // Adjust the URL for your backend

    // Fetch theme data for the domain

    return this.http
      .get<{ primaryColor: string; secondaryColor: string }>(apiUrl, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
