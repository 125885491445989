<div class="summary-container w-100">
  <div class="approval-information-container">
    <div class="row m-0">
      <div class="m-0 section-header">
        Approval Information
      </div>
      <div class="col-10 p-0 m-0">
        <div class="row mx-0 d-flex">
          <div class="field-section">
            <div class="label">Approval Type</div>
            <div class="value">
              {{ approvalDetails?.approvalType?.name || "-" }}
            </div>
          </div>

          <div class="field-section category-classification">
            <div class="label">Category Classification</div>
            <div class="value">
              {{ approvalDetails?.categoryClassification || "-" }}
            </div>
          </div>
        </div>

        <div class="field-section" *ngIf="isShowRequiredApprovalForField">
          <div class="label">Approval Reason</div>
          <div class="value">
            {{ approvalDetails?.approvalTypeReason?.name || "-" }}
          </div>
        </div>

        <div *ngIf="!isDetailsPostCreation">
          <div
            class="field-section pb-0"
            *ngIf="
              !isApprovalTypeMatch(approvalTypesEnum.AnnualImportationPlan)
            "
          >
            <div class="label selected-products-label">Selected Products</div>

            <div class="row px-0 mx-0">
              <div class="col-3 label product-col px-0 mx-0">Product</div>
              <div class="col-3 label product-col px-0 mx-0">Part Number</div>
            </div>
            <div
              class="row px-0 mx-0"
              *ngFor="let product of approvalDetails?.products; let i = index"
            >
              <div
                class="col-3 value product-value product-col selected-products-label px-0 mx-0"
              >
                {{ i + 1 }}
              </div>
              <div class="col-3 value product-value product-col px-0 mx-0">
                {{ product?.articleNumber }}
              </div>
            </div>
          </div>

          <div
            class="field-section"
            *ngIf="isApprovalTypeMatch(approvalTypesEnum.AnnualImportationPlan)"
          >
            <div class="label">Registration License Number</div>
            <div class="value">
              {{
                approvalDetails?.registrationLicenseNumber
                  ?.registrationLicenseNumber || "-"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divider-row" *ngIf="isDisplayDocumentSection"></div>

    <!-- Documents Section -->
    <div class="row m-0" *ngIf="isDisplayDocumentSection">
      <div class="m-0 section-header">
        Uploaded Documents
      </div>
      <div class="col-3 p-0 m-0 " *ngIf="approvalDetails?.invoiceDocument">
        <div class="field-section pb-0">
          <div class="label">Invoice</div>
          <div
            class="value"
            [ngClass]="{
              'view-details cursor-pointer': approvalDetails?.invoiceDocument
            }"
            (click)="viewFile(approvalDetails?.invoiceDocument?.path)"
          >
            {{ approvalDetails?.invoiceDocument?.fileName || "-" }}
          </div>
        </div>
      </div>

      <div class="col-3 p-0 m-0 " *ngIf="!isCreationProcess">
        <div class="field-section pb-0">
          <div class="label">{{ statusDocumentFieldName }}</div>
          <div
            class="value"
            [ngClass]="{
              'view-details cursor-pointer': approvalFile
            }"
            (click)="viewFile(approvalFile?.path)"
          >
            {{ approvalFile?.fileName || "No document uploaded yet." }}
          </div>
        </div>
      </div>

      <div class="col-3 p-0 m-0 " *ngIf="waiverRequestFile">
        <div class="field-section pb-0">
          <div class="label">Product Waiver Request</div>
          <div
            class="value"
            [ngClass]="{
              'view-details cursor-pointer': waiverRequestFile
            }"
            (click)="viewFile(waiverRequestFile?.path)"
          >
            {{ waiverRequestFile?.fileName || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
