export enum DocumentParentType {
  CompanyProfile = 'CompanyProfile',
  Shipment = 'Shipment',
  Product = 'Product',
  ProductRegistration = 'ProductRegistration',
  Quote = 'Quote',
  Approval = 'Approval',
  Supplier = 'Supplier'

}
