import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from '../services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedCompanyAccessGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const companyId = route.params.companyId;
    if (companyId && !this.canAccessCompany(companyId)) {
      this.navgiateToDashboard();
      return false;
    }

    if (companyId && companyId != this.defaultCompanyId) {
      this.authService.updateDefaultCompanyDataForCurrentUser(
        companyId,
        this.authService.currentIndustry,
        false
      );
    }

    return true;
  }

  navgiateToDashboard(): void {
    this.alertService.error('Unauthorized access');
    this.router.navigate([`/company/${this.defaultCompanyId}/dashboard`]);
  }

  canAccessCompany(companyId: string): boolean {
    return this.authService.canAccessCompany(companyId);
  }

  get defaultCompanyId(): string {
    return this.authService?.currentUser?.defaultCustomerCompany;
  }
}
