<div>
  <div
    *ngIf="isUserLoggedIn; else notLoggedIn"
    class="app-component app-container app-styling"
    [class]="translationService.getCurrentLanguage() + 'Class'"
  >
    <app-white-nav-bar></app-white-nav-bar>

    <app-non-admin-sidebar *ngIf="!canSeeAdminSide"></app-non-admin-sidebar>

    <app-admin-sidebar *ngIf="canSeeAdminSide"></app-admin-sidebar>

    <main
      class="app-component container main-component overflow-hidden"
      [ngClass]="{
        'padding-after-login': !canSeeAdminSide,
        'padding-after-login-is-admin': canSeeAdminSide,
        'custom-page': !excludedPages,
        'pr-0': excludedPages
      }"
    >
      <br *ngIf="!excludedPages" />

      <router-outlet *ngIf="!isLoading"></router-outlet>
      <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </main>
  </div>

  <ng-template #notLoggedIn>
    <router-outlet *ngIf="!isLoading"></router-outlet>
  </ng-template>
</div>
<div
  id="pre-bootstrap"
  *ngIf="!removePrebootScreen"
  [class.prebootShow.app-component]="!isAppLoaded"
  class="app-component prebootStep"
>
  <div class="messaging">
    <h1>Loaded!</h1>
  </div>
</div>
