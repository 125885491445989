import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApprovalBase } from 'src/app/approvals/models/approval-base.model';
import { ApprovalTypesEnum } from 'src/app/create-approval/enums/approval-types.enum';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { Document } from '../../models/document.model';
import { DocumentTypeEnum } from '../../models/enums/document-type.enum';

@Component({
  selector: 'app-approval-summary',
  templateUrl: './approval-summary.component.html',
  styleUrls: ['./approval-summary.component.scss']
})
export class ApprovalSummaryComponent implements OnInit {
  @Input() approvalDetails: ApprovalBase;
  @Input() isDisplayDocumentSection: boolean;
  @Input() isDetailsPostCreation: boolean = false;
  @Input() redirectionRoute: string = null;
  approvalTypesEnum = ApprovalTypesEnum;

  constructor(
    private fileViewerService: FileViewerService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  viewFile(path): void {
    if (path) {
      this.fileViewerService.view(path);
    }
  }

  isApprovalTypeMatch(approvalType: string): boolean {
    return this.approvalDetails?.approvalType?.name == approvalType;
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  get isCreationProcess(): boolean {
    return this.router.url.includes('summary');
  }


  get isShowRequiredApprovalForField(): boolean {
    return (
      this.isApprovalTypeMatch(ApprovalTypesEnum.ScientificCommitteeApproval) ||
      this.isApprovalTypeMatch(
        ApprovalTypesEnum.ImportationCommitteeApproval
      ) ||
      this.isApprovalTypeMatch(ApprovalTypesEnum.SpecialCasesApproval)
    );
  }

  get approvalFile(): Document | undefined {
    return this.approvalDetails?.documents?.find(
      (doc) => doc.documentTypeName == DocumentTypeEnum.ApprovalDocument
    );
  }

  get waiverRequestFile(): Document | undefined {
    return this.approvalDetails?.documents?.find(
      (doc) => doc.documentTypeName == DocumentTypeEnum.WaiverRequest
    );
  }

  get statusDocumentFieldName(): string {
    const approvalType = this.approvalDetails?.approvalType?.name;
    const defaultName = DocumentTypeEnum.ApprovalFile

    if (!approvalType) {
      return defaultName;
    }
    if (
      approvalType.includes(this.approvalTypesEnum.AnnualImportationPlan)
    ) {
      return 'EDA Annual Importation Plan File';
    }
    return `${approvalType} File`;
  }

}
