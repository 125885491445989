<div class="bg-black px-5 py-4">
  <img [src]="logo" alt="freePl" class="logo" />
</div>
<div class="login-container ">
  <div class="row mx-0 h-100">
    <div class="col-md-6 p-0 d-flex justify-content-center align-items-center ">
      <router-outlet></router-outlet>
    </div>

    <div class="col-md-6 p-0 ">
      <div class="left-side-content">
        <h3 class="promotional-header">
          The Smartest Way to Manage Your Cargo
        </h3>
        <img
          src="../../../../assets/images/home/background.webp"
          class="freight-img"
          alt="Freight Options"
        />
      </div>
    </div>
  </div>
</div>
