<div class="date-header">
  <button mat-icon-button (click)="previousClicked()" style=" color: '#2198dd'">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <div>
    <button mat-button [matMenuTriggerFor]="monthMenu" class="label-button">
      {{ monthLabel }}
    </button>
    <mat-menu #monthMenu="matMenu" class="months-menu">
      <button
        mat-menu-item
        *ngFor="let month of months"
        (click)="setMonth(month)"
      >
        {{ month }}
      </button>
    </mat-menu>

    <button mat-button [matMenuTriggerFor]="yearMenu" class="label-button">
      {{ yearLabel }}
    </button>
    <mat-menu #yearMenu="matMenu" class="years-menu">
      <button mat-menu-item *ngFor="let year of years" (click)="setYear(year)">
        {{ year }}
      </button>
    </mat-menu>
  </div>

  <button mat-icon-button (click)="nextClicked()" style=" color: '#2198dd'">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
