import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IndustryService } from '../../admin-portal-configs/services/industry.service';
import { IndustryDto } from '../models/industry-dto';

@Injectable({
  providedIn: 'root'
})
export class IndustryParentsResolver implements Resolve<IndustryDto[]> {
  constructor(
    private industryService: IndustryService
  ) { }

  resolve(): Observable<IndustryDto[]> {
    return this.industryService.getIndustriesParents().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
