import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/company/models/company.model';
import { CompanyTabs } from 'src/app/company2/enum/company-tabs.enum';
import { Company2Service } from 'src/app/company2/services/company2.service';
import { CustomerServicesHelperService } from 'src/app/customer/services/customer-services-helper.service';
import { CustomerServicesService } from 'src/app/customer/services/customer-services.service';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanySideNavBarSubTabs } from '../../models/company-side-nav-bar-sub-tabs.model';
import { OperationalKPIsEnum } from '../../models/enums/operational-kpis.enum';
import { SubTabTypeEnum } from '../../models/enums/sub-tab-type.enum';
import { Tab } from '../../models/tab.model';
import { CompanyFreePlService } from 'src/app/company/models/company-free-pl-service.model';
import { CompanySelectedService } from 'src/app/customer/models/company-selected-service.model';

@Component({
  selector: 'app-company-side-nav-bar',
  templateUrl: './company-side-nav-bar.component.html',
  styleUrls: ['./company-side-nav-bar.component.scss']
})
export class CompanySideNavBarComponent implements OnInit {
  //TODO when implementing the related page, ensure to replace 'canView' value with the correct value
  companyTabsWithIcons: Tab[];
  company: Company;
  @Input() companyServices: CompanyFreePlService[] | CompanySelectedService[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private company2Service: Company2Service,
    private authService: AuthService,
    private customerServicesService: CustomerServicesService,
    private customerServicesHelperService: CustomerServicesHelperService
  ) {}

  ngAfterContentChecked() {
    if (!this.company) {
      this.company = this.company2Service.company;
    }
  }

  ngOnInit(): void {
    this.companyTabsWithIcons = this.setCompanyTabsWithIcons();
    this.setServicesSubTabsValue();

    if (this.isCustomerModule) {
      this.customerServicesService.selectedServiceChanged.subscribe(() => {
        this.getSelectedServicesForCustomerWithServiceProvider();
      });
    }
  }

  getSelectedServicesForCustomerWithServiceProvider(): void {
    this.customerServicesService
      .getSelectedServicesForCustomerWithServiceProvider(this.currentCompanyId)
      .subscribe(res => {
        this.companyServices = res;
        this.company2Service.companyServices = res;
        this.getCustomerServicesSubTabs();
      });
  }

  isSelectedTab(key: string): boolean {
    if (key == CompanyTabs.Details && this.router.url.includes('edit')) {
      key = 'edit';
    }
    return this.router.url.includes('/' + key.replace(' ', '-').toLowerCase());
  }

  isSelectedSubTab(subPageName: string): boolean {
    var subPageNameWithLowerCase = subPageName.toLowerCase();

    if (subPageNameWithLowerCase.includes(' ')) {
      return this.router.url.includes(
        subPageNameWithLowerCase.replace(' ', '-')
      );
    } else if (subPageNameWithLowerCase.includes('trucking')) {
      if (subPageNameWithLowerCase.includes('port')) {
        return (
          this.router.url.includes('port') &&
          this.router.url.includes('trucking')
        );
      } else {
        return (
          this.router.url.includes('domestic') &&
          this.router.url.includes('trucking')
        );
      }
    } else {
      return this.router.url.includes(subPageNameWithLowerCase);
    }
  }

  navigateToPage(pageName: string) {
    var companyTab = this.companyTabsWithIcons.find(x => x.name == pageName);

    if (this.isCustomerModule) {
      pageName = pageName?.toLowerCase().replace(/\s+/g, '-');
    }

    if (
      (this.isCustomerModule || companyTab.subTabs == null) &&
      companyTab.clickable != false
    ) {
      this.router.navigate([`${pageName.toLocaleLowerCase()}`], {
        relativeTo: this.route
      });
    }
  }

  expandSubmenu(tab: any): void {
    tab.isExpanded = !tab.isExpanded;
  }

  setServicesSubTabsValue(): void {
    if (this.isCustomerModule) {
      this.getCustomerServicesSubTabs();
    } else {
      let freePlServices = this.companyServices.map(data => data.freePlService);
      let subTabs = this.orderServices(freePlServices);
      this.processServicesSubTabs(subTabs);
    }
  }

  getCustomerServicesSubTabs(): void {
    let companyServices = this.companyServices as CompanySelectedService[];
    companyServices.forEach(x => {
      if (x.isIndividualService && x.isBundleService) {
        x.isBundleService = null;
      }
    });

    let subTabs = this.customerServicesHelperService.processCustomerSelectedServicesOnSideNavBar(
      companyServices
    );

    this.processServicesSubTabs(subTabs);
    this.changeTruckingToPortTrucking(subTabs);

    let bundleSubTab = subTabs?.find(x => x?.name == 'Bundle');
    if (bundleSubTab != undefined && bundleSubTab != null) {
      this.changeTruckingToPortTrucking(bundleSubTab.bundleServices);
    }
  }

  processServicesSubTabs(subTabsServices: CompanySideNavBarSubTabs[]): void {
    var servicesTab = this.companyTabsWithIcons.find(
      x => x.name == CompanyTabs.Services
    );
    servicesTab.subTabs = subTabsServices;
    servicesTab.isExpanded = false;
    servicesTab.subTabs.forEach(subTab => {
      subTab.type = SubTabTypeEnum.Service;
    });
  }

  changeTruckingToPortTrucking(
    subTabsServices: CompanySideNavBarSubTabs[]
  ): void {
    var service = subTabsServices.find(x => x.name == ServiceTypes.Trucking);
    if (service != undefined && service != null) {
      service.name = ServiceTypes.PortTrucking;
    }
  }

  orderServices(
    res: Array<CompanySideNavBarSubTabs>
  ): Array<CompanySideNavBarSubTabs> {
    var service = res.find(x => x?.name == ServiceTypes.Trucking);
    if (service != null && service != undefined) {
      service.name = ServiceTypes.PortTrucking;
    }

    res.sort((a, b) => a.serviceId - b.serviceId);

    return res;
  }

  getSubTabUrl(
    serviceId: number,
    subTabName: string,
    subTabType: string
  ): string {
    let serviceUrl = `service/${serviceId}/`;

    subTabName = subTabName?.toLowerCase().replace(/\s+/g, '-');

    if (subTabType == SubTabTypeEnum.KPI) {
      return subTabName + '-details';
    } else if (subTabName == SubTabTypeEnum.Bundle.toLowerCase()) {
      return 'service/bundle-details';
    }

    return serviceUrl + subTabName + '-details';
  }

  setCompanyTabsWithIcons(): Tab[] {
    if (this.isCustomerModule) {
      var tabs = [
        {
          name: CompanyTabs.Details,
          icon: 'details',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Contacts,
          icon: 'company-contacts',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Documents,
          icon: 'company-documents-sidebar',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Services,
          icon: 'company-details-services',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Employees,
          icon: 'company-details-employees',
          canView: this.canViewEmployees,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.AssignedBPS,
          icon: 'company-assigned-bps',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Network,
          icon: 'company-network',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.OperationalKpis,
          icon: 'company-details-operational-kpis',
          canView: true,
          subTabs: this.operationalKpisSubTabs,
          isExpanded: false,
          clickable: false
        },
        {
          name: CompanyTabs.ContractualTerms,
          icon: 'company-contractual-terms',
          canView: true,
          subTabs: null,
          isExpanded: null
        }
      ] as Tab[];

      return tabs;
    }
    var tabs = [
      {
        name: CompanyTabs.Details,
        icon: 'details',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Documents,
        icon: 'company-documents-sidebar',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Services,
        icon: 'company-details-services',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Employees,
        icon: 'company-details-employees',
        canView: this.canViewEmployees,
        subTabs: null,
        isExpanded: null
      }
    ] as Tab[];

    return tabs;
  }

  get operationalKpisSubTabs(): CompanySideNavBarSubTabs[] {
    let shipmentKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.ShipmentKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let registrationKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.RegistrationKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let approvalsKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.ApprovalsKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let subTabs = [shipmentKpiTab, registrationKpiTab, approvalsKpiTab];
    return subTabs;
  }

  get currentCompanyId(): number {
    let splittedUrls = this.router.url.split('/');
    let companyId = splittedUrls[2];
    return +companyId;
  }

  get isCustomerModule(): boolean {
    return this.router.url.includes('customer');
  }

  get canViewEmployees(): boolean {
    return this.authService.canViewEmployees;
  }
}
