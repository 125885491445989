import { Injectable } from '@angular/core';
import { AccountEndpoint } from 'src/app/shared/services/account-endpoint.service';

import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CategoryAndPermissions } from '../models/category-and-permissions';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common'; 

@Injectable({
  providedIn: 'root'
})
export class CategoryPermissionsResolver implements Resolve<CategoryAndPermissions[]> {
  constructor(private AccountEndpoint: AccountEndpoint, private location: Location) {}

  resolve(): Observable<CategoryAndPermissions[]> {
    let isPlanPermissionPage = false;
    const fullUrl = window.location.origin + this.location.path();

    if (fullUrl.includes("plans")) {
      isPlanPermissionPage = true;
    }
    return this.AccountEndpoint.getCategoriesAndPermissions(isPlanPermissionPage).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
