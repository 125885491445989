<div
  class="admin-sidebar-container text-center h-100 d-flex align-items-center"
>
  <div class="menu-icon">
    <svg-icon name="menu-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'Workflows'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      'selected selected-2':
        isCurrentPage(adminSidebarRoutesEnum.ShipmentWorkflow) ||
        isCurrentPage(adminSidebarRoutesEnum.RegistrationsWorkflow) ||
        isCurrentPage(adminSidebarRoutesEnum.ApprovalsWorkflow)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="workflow-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ShipmentWorkflow]"
        *ngIf="canViewShipmentWorkflow"
      >
        Shipment Workflow
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.RegistrationsWorkflow]"
        *ngIf="canViewRegistrationWorkflows"
      >
        Registrations Workflow
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ApprovalsWorkflow]"
      >
        Approvals Workflow
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'Activities'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      selected:
        isCurrentPage(adminSidebarRoutesEnum.ShipmentActivities) ||
        isCurrentPage(adminSidebarRoutesEnum.RegistrationActivities) ||
        isCurrentPage(adminSidebarRoutesEnum.ApprovalsActivities)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="shipment-activities-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ShipmentActivities]"
        *ngIf="canViewShipmentActivities"
      >
        Shipment Activities
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.RegistrationActivities]"
        *ngIf="canViewRegistrationActivities"
      >
        Registration Activities
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ApprovalsActivities]"
      >
        Approvals Activities
      </div>
    </div>
  </div>

  <div
    class="nav-item cursor-pointer"
    [ngbPopover]="'Shipment Statuses'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [routerLink]="[adminSidebarRoutesEnum.ShipmentStatuses]"
    [ngClass]="{
      selected: isCurrentPage(adminSidebarRoutesEnum.ShipmentStatuses)
    }"
    *ngIf="canViewShipmentActivities"
  >
    <svg-icon name="shipment-statuses-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'KPIs'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      'selected selected-2':
        isCurrentPage(adminSidebarRoutesEnum.ShipmentKpis) ||
        isCurrentPage(adminSidebarRoutesEnum.RegistrationKpis) ||
        isCurrentPage(adminSidebarRoutesEnum.ApprovalsKpis)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="kpis-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ShipmentKpis]"
        *ngIf="canViewShipmentKPIs"
      >
        Shipment KPIs
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.RegistrationKpis]"
        *ngIf="canViewProductRegistrationKPI"
      >
        Registration KPIs
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ApprovalsKpis]"
      >
        Approvals KPIs
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'System Fields'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      'selected selected-2':
        isCurrentPage(adminSidebarRoutesEnum.ChargeTypes) ||
        isCurrentPage(adminSidebarRoutesEnum.Currencies) ||
        isCurrentPage(adminSidebarRoutesEnum.DocumentTypes) ||
        isCurrentPage(adminSidebarRoutesEnum.HSCodes) ||
        isCurrentPage(adminSidebarRoutesEnum.Industries) ||
        isCurrentPage(adminSidebarRoutesEnum.ProductCategories)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="system-fields-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup system-fields-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ChargeTypes]"
        *ngIf="canViewChargeType"
      >
        Charge Types
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Currencies]"
        *ngIf="canViewCurrency"
      >
        Currencies
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.DocumentTypes]"
        *ngIf="canViewDocumentType"
      >
        Document Types
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.HSCodes]"
      >
        HS Codes
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Industries]"
      >
        Industries
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.ProductCategories]"
        *ngIf="canViewProductCategories"
      >
        Product Categories
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'Geo Settings'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      'selected selected-2':
        isCurrentPage(adminSidebarRoutesEnum.Districts) ||
        isCurrentPage(adminSidebarRoutesEnum.Cities) ||
        isCurrentPage(adminSidebarRoutesEnum.Countries) ||
        isCurrentPage(adminSidebarRoutesEnum.Ports) ||
        isCurrentPage(adminSidebarRoutesEnum.LandRoutes)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="geolocations-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup geosettings-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Districts]"
        *ngIf="canViewDistricts"
      >
        Districts
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Cities]"
        *ngIf="canViewCities"
      >
        Cities
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Countries]"
      >
        Countries
      </div>
      <div class="dropdown-item" [routerLink]="[adminSidebarRoutesEnum.Ports]">
        Ports
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.LandRoutes]"
      >
        Land Routes
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'Security'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      'selected selected-2':
        isCurrentPage(adminSidebarRoutesEnum.Settings) &&
        (isCurrentFragment('users') || isCurrentFragment('roles'))
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="security-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup security-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Settings]"
        fragment="users"
        *ngIf="canViewUsers"
      >
        Users
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Settings]"
        fragment="roles"
      >
        Roles
      </div>
    </div>
  </div>

  <div
    class="nav-item"
    ngbDropdown
    [ngbPopover]="'Network'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [ngClass]="{
      selected:
        isCurrentPage(adminSidebarRoutesEnum.Carriers) ||
        isCurrentPage(adminSidebarRoutesEnum.Suppliers)
    }"
  >
    <button class="btn btn-link dropdown-button" ngbDropdownToggle>
      <svg-icon name="network-sidebar"></svg-icon>
    </button>
    <div ngbDropdownMenu class="nav-popup network-popup">
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Carriers]"
        *ngIf="canViewCarriers"
      >
        Carriers
      </div>
      <div
        class="dropdown-item"
        [routerLink]="[adminSidebarRoutesEnum.Suppliers]"
      >
        Suppliers
      </div>
    </div>
  </div>

  <div
    class="nav-item cursor-pointer"
    [ngbPopover]="'Old Customers Profile'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [routerLink]="[adminSidebarRoutesEnum.OldCustomersProfile]"
    [ngClass]="{
      selected:
        isCurrentPage(adminSidebarRoutesEnum.OldCustomersProfile) &&
        !isCurrentPage(adminSidebarRoutesEnum.CustomersProfile)
    }"
    *ngIf="canViewFreePLCustomers"
  >
    <svg-icon name="old-customer-profile-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    [ngbPopover]="'Customers Profile'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [routerLink]="[adminSidebarRoutesEnum.CustomersProfile]"
    [ngClass]="{
      selected: isCurrentPage(adminSidebarRoutesEnum.CustomersProfile)
    }"
    *ngIf="canViewFreePLCustomers"
  >
    <svg-icon name="customer-profile-sidebar"></svg-icon>
  </div>

  <div
    class="nav-item cursor-pointer"
    [ngbPopover]="'Company Profile'"
    triggers="mouseenter:mouseleave"
    placement="right"
    [routerLink]="[adminSidebarRoutesEnum.CompanyProfile]"
    [ngClass]="{
      selected: isCurrentPage(adminSidebarRoutesEnum.CompanyProfile)
    }"
  >
    <svg-icon name="company-profile-sidebar"></svg-icon>
  </div>

  <div class="tenant-module">
    <div
      class="sub-item cursor-pointer"
      [routerLink]="[adminSidebarRoutesEnum.Plans]"
      [ngClass]="{
        selected: isCurrentPage(adminSidebarRoutesEnum.Plans)
      }"
      [ngbPopover]="'Plans'"
      triggers="mouseenter:mouseleave"
      placement="right"
      *ngIf="canViewPlan"
    >
      <svg-icon name="new-plans-sidebar"></svg-icon>
    </div>

    <div
      class="sub-item cursor-pointer"
      [ngbPopover]="'Subscriptions'"
      triggers="mouseenter:mouseleave"
      placement="right"
    >
      <svg-icon name="subscriptions-sidebar"></svg-icon>
    </div>

    <div
      class="sub-item cursor-pointer"
      [routerLink]="[adminSidebarRoutesEnum.Tenants]"
      [ngClass]="{
        selected: isCurrentPage(adminSidebarRoutesEnum.Tenants)
      }"
      [ngbPopover]="'Tenants'"
      triggers="mouseenter:mouseleave"
      placement="right"
      *ngIf="canViewTenant"
    >
      <svg-icon name="tenants-sidebar"></svg-icon>
    </div>
  </div>
</div>
