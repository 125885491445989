import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry } from 'src/app/shared/models/industry.model';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { IndustryService } from '../../../services/industry.service';
import { IndustryDto } from 'src/app/admin-portal-configs/models/industry-dto';

@Component({
  selector: 'app-add-or-edit-industry',
  templateUrl: './add-or-edit-industry.component.html',
  styleUrls: ['./add-or-edit-industry.component.scss']
})
export class AddOrEditIndustryComponent implements OnInit {
  industryForm: UntypedFormGroup;
  industry: IndustryDto;
  industryParents: IndustryDto[];
  isSaving: boolean = false;
  duplicateIndustry: boolean = false;
  isNewIndustry: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private industryService: IndustryService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.industry = data['industry'];
      this.industryParents = data['industryParents'];
      this.industryParents = this.industryParents.filter(x => x.name !== this.industry?.name);
    });

    this.initForm();
  }

  initForm(): void {
    this.isNewIndustry = this.industry == null;
    this.industry = this.industry || ({} as IndustryDto);

    this.industryForm = this.fb.group({
      name: [this.industry.name, [Validators.required]],
      description: [this.industry?.description],
      parentIndustry: [this.industry?.parentIndustryName]
    });
  }

  save(): void {
    const formVal = this.industryForm.value as Industry;

    if (!this.industryForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    formVal.parentIndustryId = formVal?.parentIndustry?.id;
    formVal.parentIndustry = null;

    if (this.isNewIndustry) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(formVal: Industry): void {
    this.industryService.updateIndustry(this.industry.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: Industry): void {
    this.industryService.addNewIndustry(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  cancel(): void {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/industries']);
  }

  resetForm(): void {
    this.industryForm.reset();
  }

  get isFormValid(): boolean {
    return this.industryForm.valid;
  }

  private saveSuccessHelper(industry?: Industry): void {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewIndustry) {
      this.alertService.showMessage(
        'Success',
        `Industry \"${industry.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to industry \"${industry.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/industries']);
  }

  private saveFailedHelper(error: any): void {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == "409") {
      this.duplicateIndustry = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
