import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { ActivityService } from '../../../services/activity.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit {
  columns: any[] = [];
  rows: Activity[] = [];
  rowsCache: Activity[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('parentCategoryTemplate', { static: true })
  parentCategoryTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var activities = data['activities'];

      activities = this.getIndecies(activities);

      this.rowsCache = [...activities];
      this.rows = activities;
    });

    this.initTable();
  }

  initTable(): void {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 400 }
    ];

    if (this.isVoidActivities) {
      this.columns.push({
        name: gT('config.management.Status.ParentCategory'),
        width: 250,
        cellTemplate: this.parentCategoryTemplate,
        resizeable: true
      });
    }

    if (this.canUpdateShipmentActivities || this.canDeleteShipmentActivities) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.Activity.Action'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onSearchChanged(value: string): void {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteActivity(row: Activity): void {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" activity ?',
      DialogType.confirm,
      () => this.deleteActivityHelper(row)
    );
  }

  deleteActivityHelper(row: Activity): void {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.activityService.deleteActivity(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rows.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);

        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the activity.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any): Activity[] {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canUpdateShipmentActivities(): boolean {
    return this.authService.canUpdateShipmentActivities;
  }

  get canDeleteShipmentActivities(): boolean {
    return this.authService.canDeleteShipmentActivities;
  }

  get canCreateShipmentActivities(): boolean {
    return this.authService.canCreateShipmentActivities;
  }

  get isVoidActivities(): boolean {
    return this.router.url.includes('activities');
  }

  get pageTitle(): string {
    return this.isVoidActivities
      ? 'Shipment Activities Management'
      : 'Shipment Statuses Management';
  }

  get searchPlaceholder(): string {
    return this.isVoidActivities
      ? 'config.management.Activity.Search'
      : 'config.management.Status.Search';
  }

  get addNewButton(): string {
    return this.isVoidActivities
      ? 'config.management.Activity.Add'
      : 'config.management.Status.Add';
  }
}
